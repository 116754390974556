import { FormEvent, RefObject } from 'react';
import InputMask, { ReactInputMask } from 'react-input-mask';
import { Notification } from '@components';

interface InputProps {
  id?: string;
  innerRef?: RefObject<ReactInputMask>;
  disabledAutoComplete?: string;
  error?: any; // Arthur
  mask: string;
  name: string;
  onKeyUp?: (_e: FormEvent<HTMLInputElement>) => void;
  onChange: (_e: FormEvent<HTMLInputElement>) => void;
  onBlur?: (_e: FormEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type: 'text' | 'email' | 'password';
  value?: string;
}

export function MaskedInput({
  id,
  innerRef,
  disabledAutoComplete,
  error,
  mask,
  name,
  onChange,
  onKeyUp,
  onBlur,
  placeholder,
  type,
  value = '',
}: InputProps) {
  const err = error?.find((e: any) => e.key === name);

  return (
    <>
      <InputMask
        id={id}
        ref={innerRef}
        mask={mask}
        name={name}
        className={`form__field ${
          err && 'form__field--error'
        }`}
        onChange={onChange}
        onBlur={onBlur}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        type={type}
        value={value}
        autoComplete={disabledAutoComplete}
      />
      {err && err.message && <Notification.ErrorMessage message={err.message} />}
    </>
  );
}
