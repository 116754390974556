/* eslint-disable react/no-array-index-key */
import {
  useEffect,
  useState,
  Fragment,
  useContext,
} from 'react';
import { Template } from '@components';
import { SelectionProgress } from '@utils';
import { routes } from '@routes';
import { AuthContext, GalleryContext, RouterContext } from '@contexts';
import { useGalleriesAlbums } from '@api';
import { albumSummaryPlaceholder, selectionProgressCardSummaryPlaceholder } from './placeholder';

export const Albums = () => {
  const { navigate } = useContext(RouterContext);
  const { auth } = useContext(AuthContext);
  const context = useContext(GalleryContext);
  const { getAlbumsList } = useGalleriesAlbums();
  const [albums, setAlbums] = useState<Array<Template.IAlbumSummary>>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [cards, setCards] = useState<Array<Template.ISelectionProgressCardSummary>>();

  const getAlbums = async () => {
    setIsLoading(true);
    setAlbums(albumSummaryPlaceholder);
    setCards(selectionProgressCardSummaryPlaceholder);

    const { data } = await getAlbumsList({
      galleryId: context?.galleryId || '',
      partnerId: context?.partnerId || '',
      userId: auth.userId || '',
    });
    const summary = data.reverse().map((album) => ({
      ...album,
      handleButtonClick: () => navigate(routes.albumApproval(album.id)),
    }));
    setAlbums(summary);
    setCards(undefined);
    setIsLoading(false);
  };

  useEffect(() => {
    getAlbums();
  }, []);

  useEffect(() => {
    if (albums && !isLoading) {
      setCards(SelectionProgress.handleSelectionStatusData(albums, 'Visualizar'));
    }
  }, [albums]);

  return (
    <>
      <Template.Header active="Albums" />
      <main className="page__main albums">
        <section className="page__section">
          <div className="page__container">
            <div className="page__wrapper">
              <Template.Heading titleId="Albums" onReturnClick={() => navigate(routes.home())} />
            </div>
          </div>
        </section>
        <section className="page__section">
          <div className="page__container">
            <div className="page__wrapper">
              <div className="albums__grid">
                {albums
                  && cards
                  && albums.map((album, index) => (
                    <Fragment key={index}>
                      <Template.CardAlbum
                        isLoading={isLoading}
                        albumSummary={album}
                        selectionProgressCardSummary={cards[index]}
                      />
                    </Fragment>
                  ))}
              </div>
            </div>
          </div>
        </section>
      </main>
      <Template.Footer />
    </>
  );
};
