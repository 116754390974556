import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

function useFrameAlbumViewer(iframeEl: React.RefObject<HTMLIFrameElement>) {
  let parentRefId = '';

  const [firstPage, setFirstPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);

  function postMessage(viewerCommand: string, content?: any): void {
    const msg = {
      command: viewerCommand,
      content,
      _callbackId: `${viewerCommand}__${parentRefId}`,
    };

    iframeEl.current?.contentWindow?.postMessage(msg, '*');
  }

  function registerParentRef(): void {
    parentRefId = uuidv4();
    postMessage('registerParentRef', parentRefId);
  }

  function nextAlbumPage() {
    postMessage('album.nextPage');
  }

  function backAlbumPage() {
    postMessage('album.previousPage');
  }

  function initAlbumViewer() {
    return new Promise<string>((resolve) => {
      const messageCallback = ({
        data: { _eventName: eventName, parameter },
      }: any) => {
        switch (eventName) {
          case 'onLoad':
            registerParentRef();
            postMessage('subscribeEvent', {
              eventName: 'album.currentPageChanged',
            });
            resolve(parentRefId);
            break;
          case 'album.currentPageChanged':
            setFirstPage(parameter.first);
            setLastPage(parameter.last);
            break;
          default:
            break;
        }
      };
      window.removeEventListener('message', messageCallback);
      window.addEventListener('message', messageCallback);
    });
  }

  useEffect(() => {
    initAlbumViewer();
  }, []);

  return {
    nextAlbumPage,
    backAlbumPage,
    firstPage,
    lastPage,
  };
}

export { useFrameAlbumViewer };
