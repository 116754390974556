import { ChangeEvent, FocusEvent, RefObject } from 'react';
import { Notification } from '@components';

interface InputProps {
  id?: string;
  innerRef?: RefObject<HTMLInputElement>;
  classes?: string;
  disabledAutoComplete?: string;
  disabled?: boolean;
  error?: any;
  name: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type: 'text' | 'email' | 'password';
  value?: string;
}

export function Input({
  id,
  innerRef,
  classes,
  disabledAutoComplete,
  disabled = false,
  error,
  name,
  onChange,
  onBlur,
  placeholder,
  type,
  value,
}: InputProps) {
  const err = error?.find((e: any) => e.key === name);

  return (
    <>
      <input
        id={id}
        ref={innerRef}
        className={`form__field ${classes} ${err && 'form__field--error'}`}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        type={type}
        value={value}
        onBlur={onBlur}
        autoComplete={disabledAutoComplete}
        disabled={disabled}
      />
      {err && <Notification.ErrorMessage message={err} />}
    </>
  );
}
