/* eslint-disable arrow-body-style */
import { useCallback } from 'react';
import { Shipping } from '@types';
import { useApi } from './api';
import { GetAddressByZipCodeRequest, GetShippingMethodsRequest, GetShippingMethodsResponse } from './types/shipping';

export const useShipping = () => {
  const api = useApi();

  const getAddressByZipCode = useCallback((params: GetAddressByZipCodeRequest) => {
    return api.get<Shipping.Address>(`shipping/address/zipCode/${params.zipCode}`, { params });
  }, [api]);

  const getShippingMethods = useCallback((params: GetShippingMethodsRequest) => {
    return api.get<GetShippingMethodsResponse>('shipping/shippingMethods', { params });
  }, [api]);

  return {
    getAddressByZipCode,
    getShippingMethods,
  };
};
