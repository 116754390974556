import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
} from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { getPartnerSlug, getProjectSlug } from '@routes/utils';

interface RouterContextProps {
  slugs: {
    project: string
    partner: string
  }
  navigate: NavigateFunction
}

export const RouterContext = createContext<RouterContextProps>({
  slugs: { partner: '', project: '' },
  navigate: () => { },
});

const slugs = { project: getProjectSlug(), partner: getPartnerSlug() };

export const RouterContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const getContextValue = useCallback(() => ({ slugs, navigate }), []);

  return (
    <RouterContext.Provider value={getContextValue()}>
      {children}
    </RouterContext.Provider>
  );
};
