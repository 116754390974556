/* eslint-disable no-unused-vars */
enum ThumbSizes {
  small = '256',
  big = '1920',
}

function useReplaceThumbUrls() {
  const replaceThumbUrls = (url: string, size: ThumbSizes = ThumbSizes.small): string => url.replace('[SIZE]', size).replace('[FORMAT]', 'jpg');

  return { replaceThumbUrls };
}

export { useReplaceThumbUrls, ThumbSizes };
