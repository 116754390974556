import { MercadoPago } from '@types';

export class MercadoPagoService {
  public instance: MercadoPago | undefined;

  private static script: Promise<void>;

  public async init() {
    await MercadoPagoService.initScript();

    const token = process.env.REACT_APP_MP_PUBLIC_KEY || 'some-token';

    this.instance = new window.MercadoPago(token, {
      locale: 'pt-BR',
      advancedFraudPrevention: true,
    });
  }

  private static initScript(): Promise<void> {
    if (!this.script) {
      this.script = this.loadSDK();
    }

    return this.script;
  }

  private static async loadSDK(): Promise<void> {
    const p1 = new Promise<void>((resolve) => {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://sdk.mercadopago.com/js/v2';
      script.onload = () => {
        resolve();
      };

      document.head.appendChild(script);
    });

    const p2 = new Promise<void>((resolve) => {
      const script = document.createElement('script');
      script.async = true;
      script.src = 'https://www.mercadopago.com/v2/security.js';
      (script as any).view = 'checkout';
      script.onload = () => resolve();

      document.head.appendChild(script);
    });

    await Promise.all([p1, p2]);
  }
}
