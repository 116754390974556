/* eslint-disable no-restricted-syntax */
import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { MdOutlinePhotoLibrary } from 'react-icons/md';
import JSZip from 'jszip';
import moment, { Moment } from 'moment';
import fileDownload from 'js-file-download';
import { UI, Template, Notification } from '@components';
import { lang } from '@i18n';
import { useReplaceThumbUrls, ThumbSizes } from '@hooks';
import { routes } from '@routes';
import { persistentStorage } from '@utils';
import { GalleryContext, RouterContext } from '@contexts';
import { useScene } from '@api';
import { Scene } from '@types';

export interface linkDownloadRequestById {
  id: string | number;
  projectSlug: string;
  partnerSlug: string;
  date: Moment;
}

export function DownloadRequest() {
  const params = useParams();
  const { navigate, slugs } = useContext(RouterContext);
  const context = useContext(GalleryContext);

  const linkDownloadRequestKey: string = 'LastLinkDownloadRequestDate';

  const [zipLoading, setZipLoading] = useState(false);
  const [linkLoading, setLinkLoading] = useState(false);
  const [filesReadyCounter, setFilesReadyCounter] = useState<number>(0);
  const [scene, setScene] = useState<Scene.WithImages>();
  const [availableResulutions, setAvailableResulutions] = useState<string[]>();
  const [availableResulutionsIsLoading, setAvailableResulutionsIsLoading] = useState<boolean>(true);
  const [hasMessage, setHasMessage] = useState<{
    type: 'error' | 'success' | 'warning';
    field: 'zip' | 'link';
    message: string;
  } | null>(null);
  const { downloadFilesBySceneId, sceneWithImagesBySceneId, getDownloadableScenes } = useScene();
  const { replaceThumbUrls } = useReplaceThumbUrls();
  const [storedLinkDownloadRequest, setStoredLinkDownloadRequest] = useState<linkDownloadRequestById | undefined>(undefined);
  const [currentLinkDownloadRequest, setCurrentLinkDownloadRequest] = useState<linkDownloadRequestById | undefined>(undefined);
  const [disableLinkRequest, setDisableLinkRequest] = useState(false);

  async function handleDownloadableScenes(): Promise<void> {
    setAvailableResulutionsIsLoading(true);

    const { data } = await getDownloadableScenes();
    setAvailableResulutions(data.availableResolutions);

    setAvailableResulutionsIsLoading(false);
  }

  async function getDownloadableFiles(sceneId: string) {
    const { data: scene } = await sceneWithImagesBySceneId({
      sceneId: sceneId === '0' ? 'suggested' : (sceneId as unknown as number),
      sceneQuery: {
        sceneFilter: sceneId === '0' ? 'suggested' : '',
        pagination: {
          skip: 0,
          take: 0,
        },
      },
    });

    setScene(scene);
  }

  useEffect(() => {
    if (!scene) {
      getDownloadableFiles(params.sceneId as string);
    }
    handleDownloadableScenes();
  }, []);

  async function downloadFilesLow() {
    const jsZip = JSZip();

    try {
      setZipLoading(true);

      const { data } = await downloadFilesBySceneId({
        sceneId: scene?.id as number,
        resolution: 'SocialMedia',
      });

      for (const file of data.files) {
        const fileUrl = replaceThumbUrls(file.url, ThumbSizes.big);
        // eslint-disable-next-line no-await-in-loop
        const imageBlob = await (await fetch(fileUrl)).blob();

        jsZip.file(file.name, imageBlob);
        setFilesReadyCounter((curState) => {
          const newState = curState + 1;
          return newState;
        });
      }

      setFilesReadyCounter(0);

      const zip = await jsZip.generateAsync({ type: 'blob' });

      fileDownload(
        zip,
        `Fotos ${context?.galleryName} - ${scene ? scene.name : ''}.zip`,
      );

      setHasMessage({
        type: 'success',
        field: 'zip',
        message: `Download realizado com sucesso. Consulte o arquivo "Fotos ${context?.galleryName} - ${scene ? scene.name : ''}.zip" em seu computador`,
      });
    } catch (error: any) {
      setHasMessage({
        type: 'warning',
        field: 'zip',
        message: lang.translate(lang.keys.errors.api.generic),
      });
    } finally {
      setZipLoading(false);
    }
  }

  function setOriginalFilesSucessMessage(): void {
    setHasMessage({
      type: 'success',
      field: 'link',
      message:
        'Pedido sendo processado. Você receberá um email com o link em até 24h. Lembre-se de conferir o lixo eletrônico.',
    });
  }

  async function downloadOriginalFiles() {
    try {
      setLinkLoading(true);

      await downloadFilesBySceneId({
        sceneId: scene?.id as number,
        resolution: 'Original',
      });

      setOriginalFilesSucessMessage();

      persistentStorage.setItem(linkDownloadRequestKey, {
        id: scene ? scene.id : '',
        partnerSlug: slugs.partner,
        projectSlug: slugs.project,
        date: moment(new Date()),
      });

      setStoredLinkDownloadRequest(persistentStorage.getItem(linkDownloadRequestKey));
    } catch (error: any) {
      setHasMessage({
        type: 'warning',
        field: 'link',
        message: lang.translate(lang.keys.errors.api.generic),
      });
    } finally {
      setLinkLoading(false);
    }
  }

  function returnToDownloadPage() {
    navigate(routes.downloads());
  }

  const onClickModal = () => {
    setHasMessage(null);
  };

  useEffect(() => {
    if (scene) {
      setStoredLinkDownloadRequest(persistentStorage.getItem(linkDownloadRequestKey));
      setCurrentLinkDownloadRequest({
        id: scene ? scene.id : '',
        partnerSlug: slugs.partner,
        projectSlug: slugs.project,
        date: moment(new Date()),
      });
    }
  }, [scene]);

  useEffect(() => {
    if (storedLinkDownloadRequest && currentLinkDownloadRequest
        && currentLinkDownloadRequest.id === storedLinkDownloadRequest.id
        && currentLinkDownloadRequest.partnerSlug === storedLinkDownloadRequest.partnerSlug
        && currentLinkDownloadRequest.projectSlug === storedLinkDownloadRequest.projectSlug
        && currentLinkDownloadRequest.date.diff(storedLinkDownloadRequest.date, 'minutes') < (24 * 60)) {
      setDisableLinkRequest(true);
      setOriginalFilesSucessMessage();
    } else {
      setDisableLinkRequest(false);
    }
  }, [storedLinkDownloadRequest, currentLinkDownloadRequest]);

  return (
    <>
      <Template.Header active="Downloads" />
      <main className="page__main download-request">
        <section className="page__section ">
          <div className="page__container">
            <div className="download-request__heading-wrapper">
              <Template.Heading
                titleId="Downloads"
                onReturnClick={returnToDownloadPage}
              />
            </div>
          </div>
        </section>
        <section className="page__section">
          <div className={`page__container ${availableResulutions && availableResulutions.length === 2 ? 'page__container--w-small' : 'page__container--w-xsmall'}`}>
            <div className="page__bg-area page__bg-area--padding-large page__bg-area--no-bs  download-request__top-wrapper">
              <p className="card-download__text card-download__text--emphasys">
                Suas fotos estão prontas para download!
              </p>
              <p className="card-download__text">
                Selecione abaixo como prefere baixar seus arquivos.
              </p>
            </div>
            <div className="download-request__row">
              {availableResulutions?.includes('SocialMedia') && (
              <div className="download-request__card-wrapper">
                <div className="card-download">
                  <div className="page__bg-area page__bg-area--padding-large page__bg-area--no-bs card-download__top">
                    <div className="card-download__text-icon">
                      <p className="card-download__text">Menor Tamanho</p>
                      <MdOutlinePhotoLibrary className="card-download__icon" />
                      <p className="card-download__text card-download__text--emphasys">
                        {scene?.filesCount}
                        {' '}
                        Fotos
                      </p>
                    </div>
                    <small className="card-download__small">
                      Um arquivo compactado com as suas fotos será baixado
                      diretamente para seu computador ou celular.
                    </small>
                  </div>
                  <div className="card-download__bottom">
                    <div className="form__btn-message">
                      <UI.Button
                        type="button"
                        variant={UI.ButtonEnum.primaryOutline}
                        fullwidth="w-full"
                        loading={zipLoading || availableResulutionsIsLoading}
                        classes="card-download__btn"
                        onClick={downloadFilesLow}
                        text=".ZIP"
                      />
                      {hasMessage && hasMessage.field === 'zip' && (
                      <Notification.Message
                        type={hasMessage.type}
                        onClick={onClickModal}
                        message={hasMessage.message}
                      />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              )}
              {availableResulutions?.includes('Original') && (
              <div className="download-request__card-wrapper">
                <div className="card-download">
                  <div className="page__bg-area page__bg-area--padding-large page__bg-area--no-bs card-download__top">
                    <div className="card-download__text-icon">
                      <p className="card-download__text">Tamanho original</p>
                      <MdOutlinePhotoLibrary className="card-download__icon" />
                      <p className="card-download__text card-download__text--emphasys">
                        {scene?.filesCount}
                        {' '}
                        Fotos
                      </p>
                    </div>
                    <small className="card-download__small">
                      Um link para download das fotos será enviado para o seu
                      email. Em função de processamento, isso pode levar até
                      12h.
                    </small>
                  </div>
                  <div className="card-download__bottom">
                    <div className="form__btn-message">
                      <UI.Button
                        type="button"
                        variant={UI.ButtonEnum.primaryOutline}
                        fullwidth="w-full"
                        loading={linkLoading || availableResulutionsIsLoading}
                        classes="card-download__btn"
                        onClick={downloadOriginalFiles}
                        text={disableLinkRequest ? 'Link já solicitado' : 'Enviar link'}
                        disabled={disableLinkRequest}
                      />
                      {hasMessage && hasMessage.field === 'link' && (
                      <Notification.Message
                        type={hasMessage.type}
                        onClick={onClickModal}
                        message={hasMessage.message}
                      />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              )}
            </div>
          </div>
        </section>
      </main>
      <Template.Footer />

      <UI.FullscreenLoader
        textFristLine={zipLoading
          ? `Processando: ${filesReadyCounter}/${scene?.filesCount} fotos `
          : 'Aguarde, estamos processando a solicitação, esse processo pode demorar até 1 minuto.'}
        textSecondLine={zipLoading
          ? 'Após finalizado o processamento, o download do arquivo será feito automaticamente.'
          : undefined}
        active={zipLoading || linkLoading}
      />
    </>
  );
}
