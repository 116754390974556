/* revisar componente na implementação das pages */

import React from 'react';
import { Dialog } from '@headlessui/react';
import { MdOutlineClear } from 'react-icons/md';
import { IconType } from 'react-icons';
import { UI } from '@components';

interface ModalProps {
  isOpen: boolean,
  maxWidth?: number,
  header: {
    title: string,
    Icon: IconType,
  };
  contentEl: React.ReactNode;
  buttonGroupDirection?: 'row' | 'column';
  closeable?: boolean;
  onClose: () => void;
  negativeButton?: {
    label: React.ReactNode,
    onClick: () => void;
    variant?: UI.ButtonEnum;
  }
  positiveButton?: {
    label: React.ReactNode,
    onClick: () => void;
    variant?: UI.ButtonEnum;
  }
}

export function Modal({
  isOpen,
  maxWidth = 1280,
  header,
  contentEl,
  buttonGroupDirection = 'row',
  closeable = true,
  onClose,
  negativeButton,
  positiveButton,
} : ModalProps) {
  return (
    <Dialog
      as="div"
      open={isOpen}
      className="modal modal--sm"
      onClose={onClose}
    >
      <div className="modal__backdrop" />
      <Dialog.Panel
        className="modal__wrapper"
        style={{ maxWidth: `${maxWidth}px` }}
      >
        <div className="modal-header">
          <div className="modal-header__container modal-header__container--left">
            <div className="modal-header__icon-wrapper">
              <header.Icon className="modal-header__icon" />
            </div>
            <Dialog.Title
              as="h3"
              className="modal-header__title"
            >
              {header.title}
            </Dialog.Title>
          </div>
          <div className="modal-header__container modal-header__container--right">
            {closeable && (
              <button
                className="modal-header__btn-close btn btn--outline"
                onClick={onClose}
                tabIndex={-1}
                type="button"
              >
                <MdOutlineClear className="modal__icon" />
              </button>
            )}
          </div>
        </div>

        <div className="modal__body">
          {contentEl}
        </div>

        <div className="modal__footer">
          <div className={`modal__btn-group 
                ${buttonGroupDirection === 'column' ? 'modal__btn-group--column' : ''}`}
          >
            {negativeButton && (
            <button
              className={`modal__btn btn ${negativeButton.variant}`}
              type="button"
              onClick={negativeButton.onClick}
            >
              {negativeButton.label}
            </button>
            )}

            {positiveButton && (
            <button
              className={`modal__btn btn ${positiveButton.variant}`}
              type="button"
              onClick={positiveButton.onClick}
            >
              {positiveButton.label}
            </button>
            )}
          </div>
        </div>
      </Dialog.Panel>
    </Dialog>
  );
}
