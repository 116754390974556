/* eslint-disable arrow-body-style */
import { useCallback } from 'react';
import { Scene } from '@types';
import { useApi } from './api';
import {
  DownloadFilesBySceneIdRequest,
  DownloadFilesBySceneIdResponse,
  GetDownloadableScenesResponse,
  SceneWithImagesBySceneIdRequest,
  SceneWithImagesBySelectionIdRequest,
} from './types/scene';

export const useScene = () => {
  const api = useApi();

  const getDownloadableScenes = useCallback(() => api.get<GetDownloadableScenesResponse>('/scene/downloadable/summary'), [api]);

  const downloadFilesBySceneId = useCallback(({ sceneId, resolution }: DownloadFilesBySceneIdRequest) => {
    return api.post<DownloadFilesBySceneIdResponse>(`scene/sceneId/${sceneId}/downloadFiles`, { resolution });
  }, [api]);

  const getScenesList = useCallback(() => api.get<Scene.Item[]>('/scene/summary'), [api]);

  const sceneWithImagesBySceneId = useCallback(({ sceneId, sceneQuery }: SceneWithImagesBySceneIdRequest) => {
    return api.post<Scene.WithImages>(`/scene/sceneId/${sceneId}`, sceneQuery);
  }, [api]);

  const sceneWithImagesBySelectionId = useCallback(({ sceneId, selectionId, sceneQuery }: SceneWithImagesBySelectionIdRequest) => {
    return api.post<Scene.WithImages>(`/scene/sceneId/${sceneId}/selectionId/${selectionId}`, sceneQuery);
  }, [api]);

  return {
    getDownloadableScenes,
    downloadFilesBySceneId,
    getScenesList,
    sceneWithImagesBySceneId,
    sceneWithImagesBySelectionId,
  };
};
