/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useState, Fragment, useContext } from 'react';
import { UI } from '@components';
import { routes } from '@routes';
import { RouterContext } from '@contexts';
import { MdOutlineDone } from 'react-icons/md';
import { ISelectionResume } from './SelectionResume';

export interface IReviewSelectionButton {
  label: string,
  style: UI.ButtonEnum,
  onClick: () => void,
}

interface SelectionHandleButtonProps {
  currentId: string;
  selectionResumeData: ISelectionResume;
  isLoading?: boolean;
  reviewSelectionButtons?: IReviewSelectionButton[]
}

export function SelectionHandleButton({
  currentId,
  selectionResumeData,
  isLoading,
  reviewSelectionButtons,
}: SelectionHandleButtonProps) {
  const { navigate } = useContext(RouterContext);
  const [showReviewSelectionModal, setShowReviewSelectionModal] = useState<boolean>(false);

  function navegateSelectionReview() {
    if (currentId) {
      navigate(routes.selectionReview(currentId));
    }
  }

  const reviewSelectionModalEl: React.ReactNode = (
    <>
      <p>
        <b>Parabéns! </b>
        Você está mais próximo de finalizar sua seleção de fotos.
      </p>
      <p>
        Agora você será
        <b> redirecionado para a revisão </b>
        da mesma,
        <b> podendo voltar para a seleção de fotos </b>
        caso queira fazer alguma alteração.
      </p>
    </>
  );

  return (
    <>
      { !reviewSelectionButtons && selectionResumeData.selectionStatus === 'Finished' && (
        <UI.Button
          type="button"
          disabled
          loading={isLoading || false}
          variant={UI.ButtonEnum.primary}
          text="Seleção Finalizada"
          onClick={() => false}
        />
      )}
      {!reviewSelectionButtons && selectionResumeData.selectionStatus === 'Expired' && (
        <UI.Button
          type="button"
          disabled
          loading={isLoading || false}
          variant={UI.ButtonEnum.error}
          classes="selection-resume__btn--error"
          text="Seleção Expirada"
          onClick={() => false}
        />
      )}
      {(!reviewSelectionButtons && (selectionResumeData.selectionStatus === 'Pending' || selectionResumeData.selectionStatus === 'InProgress')) && (
        <UI.Button
          type="button"
          disabled={
                selectionResumeData.selectedFilesCount === 0
                || (selectionResumeData
                  && selectionResumeData
                  && typeof selectionResumeData.minFiles === 'number'
                  && typeof selectionResumeData.selectedFilesCount === 'number'
                  && selectionResumeData.minFiles > selectionResumeData.selectedFilesCount)
              }
          loading={isLoading || false}
          variant={UI.ButtonEnum.primary}
          text="Finalizar seleção"
          onClick={() => setShowReviewSelectionModal(true)}
        />
      )}
      {!reviewSelectionButtons && (
        <UI.Modal
          isOpen={showReviewSelectionModal}
          maxWidth={560}
          header={{
            title: 'Revisar Seleção',
            Icon: MdOutlineDone,
          }}
          contentEl={reviewSelectionModalEl}
          buttonGroupDirection="column"
          closeable={false}
          onClose={() => false}
          positiveButton={{
            label: 'Continuar para revisão',
            onClick() {
              setShowReviewSelectionModal(false);
              navegateSelectionReview();
            },
            variant: UI.ButtonEnum.primary,
          }}
          negativeButton={{
            label: 'Cancelar',
            onClick: () => {
              setShowReviewSelectionModal(false);
            },
            variant: UI.ButtonEnum.primaryOutline,
          }}
        />
      )}

      {reviewSelectionButtons
        && reviewSelectionButtons.map(({ label, style, onClick }) => (
          <Fragment key={label}>
            <UI.Button
              type="button"
              disabled={false}
              loading={isLoading || false}
              variant={style}
              text={label}
              onClick={onClick}
            />
          </Fragment>
        ))}
    </>
  );
}
