import { useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, Template, UI } from '@components';
import { lang } from '@i18n';
import { RouterContext } from '@contexts';
import { routes } from '@routes';
import { useAuth } from '@api';

type ResetFormValueType = { new_password: string, new_password_confirm: string };
const resetFormInitialValue: ResetFormValueType = { new_password: '', new_password_confirm: '' };

const schema = Yup.object({
  new_password: Yup.string()
    .required(lang.translate(lang.keys.errors.generic.required_field, {
      path: lang.translate(lang.keys.forms.users.password),
    }))
    .min(6, lang.translate(lang.keys.errors.generic.min_lenth, {
      path: lang.translate(lang.keys.forms.users.password),
      length: 6,
    })),
  new_password_confirm: Yup.string()
    .required(lang.translate(lang.keys.errors.generic.required_field, {
      path: lang.translate(lang.keys.forms.users.password),
    }))
    .oneOf([Yup.ref('new_password'), null], lang.translate(lang.keys.errors.reset.password_confirm)),
});

export function UserSettingsReset() {
  const { navigate } = useContext(RouterContext);
  const { resetPassword } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isPasswordConfirmationVisible, setIsPasswordConfirmationVisible] = useState(false);

  const [searchParams] = useSearchParams();
  const token = searchParams.get('tk');
  const email = searchParams.get('email');

  async function handleSubmit(values: ResetFormValueType) {
    setLoading(true);

    await resetPassword({
      email: email as string,
      password: values.new_password,
      token: token as string,
    });

    setLoading(false);
  }

  const onClickPasswordIcon = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const onClickPasswordConfirmationIcon = () => {
    setIsPasswordConfirmationVisible(!isPasswordConfirmationVisible);
  };

  return (
    <>
      <Template.Header />
      <main className="page__main reset">
        <section className="page__section">
          <div className="page__container">
            <div className="user-settings__heading-wrapper">
              <Template.Heading titleId="Change_password" onReturnClick={() => navigate(routes.userSettings())} />
            </div>
            <Formik initialValues={resetFormInitialValue} validationSchema={schema} onSubmit={handleSubmit}>
              {({
                errors, touched, handleChange, handleBlur,
              }) => (
                <div className="user-settings__forms-area">
                  <div className="page__container page__container--center">
                    <div className="page__bg-area reset__area">
                      <div className="page__heading">
                        <h5 className="page__title">{lang.translate(lang.keys.forms.users.changePassword)}</h5>
                        <p className="page__desc">Preencha com sua nova senha</p>
                      </div>
                      <div className="form">
                        <div className="form__grid">
                          <div className="form__group">
                            <Form.Label name="new_password" text={lang.translate(lang.keys.forms.users.new_password)} />
                            <Form.Input
                              id="new_password"
                              name="new_password"
                              type={`${isPasswordVisible ? 'text' : 'password'}`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.new_password && errors.new_password ? errors.new_password : ''}
                              placeholder={lang.translate(lang.keys.forms.users.new_password_placeholder)}
                            />
                            <Form.ShowContent show={isPasswordVisible ? 'show' : undefined} onClick={onClickPasswordIcon} />
                          </div>
                          <div className="form__group">
                            <Form.Label name="new_password_confirm" text={lang.translate(lang.keys.forms.users.new_password_confirm)} />
                            <Form.Input
                              id="new_password_confirm"
                              name="new_password_confirm"
                              type={`${isPasswordConfirmationVisible ? 'text' : 'password'}`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={touched.new_password_confirm && errors.new_password_confirm ? errors.new_password_confirm : ''}
                              placeholder={lang.translate(lang.keys.forms.users.new_password_confirm_placeholder)}
                            />
                            <Form.ShowContent
                              show={isPasswordConfirmationVisible ? 'show' : undefined}
                              onClick={onClickPasswordConfirmationIcon}
                            />
                          </div>
                          <div className="form__group">
                            <div className="reset__btn-group">
                              <UI.Button
                                type="submit"
                                loading={loading}
                                variant={UI.ButtonEnum.primary}
                                fullwidth="w-full"
                                text="Salvar"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </section>
      </main>
      <Template.Footer />
    </>
  );
}
