/* eslint-disable no-unused-vars */
export interface SpinnerProps {
  size?: number
  strokeWidth?: number;
  color?: 'neutral' | 'primary';
  colorScale?: '10' | '20' | '30' | '40' | '50' | '60' | '70' | '80' | '90' | '95' | '100';
}

export function Spinner({
  size = 50,
  strokeWidth = 5,
  color = 'neutral',
  colorScale = '100',
}: SpinnerProps) {
  return (
    <span className="spinner">
      <svg
        className="spinner__svg"
        viewBox={`0 0 ${size} ${size}`}
        style={{ width: `${size}px`, height: `${size}px` }}
      >
        <circle
          className="spinner__svg-path"
          cx={`${size / 2}`}
          cy={`${size / 2}`}
          r={`${(size / 2) - strokeWidth}`}
          fill="none"
          strokeWidth={`${strokeWidth}`}
          style={{ stroke: `var(--color-${color}-${colorScale})` }}
        />
      </svg>
    </span>
  );
}
