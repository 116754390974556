/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { useFormatLocal } from '@hooks';
import { Template } from '@components';
import { Selection } from '@types';

export enum SelectionResumeEnum {
  selection = '',
  gallery = 'project-banner--home',
}

export interface ISelectionResume {
  title: string;
  subtitle?: string;
  minFiles?: number;
  selectedFilesCount: number;
  expirationDate?: Date;
  price?: number;
  totalValue?: number;
  selectionStatus: Selection.Status;
}

interface SelectionResumeProps {
  currentId: string;
  selectionResumeData: ISelectionResume;
  reviewSelectionButtons?: Template.IReviewSelectionButton[];
  isLoading?: boolean;
}

export function SelectionResume({
  currentId,
  selectionResumeData,
  reviewSelectionButtons = [],
  isLoading,
}: SelectionResumeProps) {
  const { formatCurrencyPtBR, formatLocalDate } = useFormatLocal();

  return (
    <div className="selection-resume">
      <div className="selection-resume__inner">
        <div className="selection-resume__top-content">
          <div className="selection-resume__heading">
            <h5
              className={`selection-resume__title ${
                isLoading ? 'skeleton' : ''
              }`}
            >
              {selectionResumeData.title}
            </h5>
            {selectionResumeData.subtitle && (
              <p
                className={`selection-text__title ${
                  isLoading ? 'skeleton' : ''
                }`}
              >
                {selectionResumeData.subtitle}
              </p>
            )}
          </div>
          <div className="selection-resume__infos-group">
            {selectionResumeData.minFiles && !selectionResumeData?.price ? (
              <p
                className={`selection-resume__text selection-resume__text--emphasys ${
                  isLoading ? 'skeleton' : ''
                }`}
              >
                Total de fotos do pacote:&nbsp;
                <b>{selectionResumeData.minFiles}</b>
              </p>
            ) : null}
            {selectionResumeData.minFiles && selectionResumeData.price ? (
              <p
                className={`selection-resume__text selection-resume__text--emphasys ${
                  isLoading ? 'skeleton' : ''
                }`}
              >
                Seleção mínima:&nbsp;
                <b>{selectionResumeData.minFiles}</b>
              </p>
            ) : null}
            <p
              className={`selection-resume__text selection-resume__text--emphasys ${
                isLoading ? 'skeleton' : ''
              }`}
            >
              Fotos selecionadas:&nbsp;
              <b>{selectionResumeData.selectedFilesCount}</b>
            </p>
            {selectionResumeData.price ? (
              <p
                className={`selection-resume__text selection-resume__text--emphasys ${
                  isLoading ? 'skeleton' : ''
                }`}
              >
                Valor por foto extra:&nbsp;
                <b>{formatCurrencyPtBR(selectionResumeData.price)}</b>
              </p>
            ) : null}
            {selectionResumeData.price ? (
              <p
                className={`selection-resume__text selection-resume__text--emphasys ${
                  isLoading ? 'skeleton' : ''
                }`}
              >
                Valor a pagar:&nbsp;
                <b>
                  {formatCurrencyPtBR(
                    selectionResumeData?.totalValue as number,
                  )}
                </b>
              </p>
            ) : null}
            {selectionResumeData.expirationDate ? (
              <p
                className={`selection-resume__text selection-resume__text--emphasys ${isLoading ? 'skeleton' : ''}`}
              >
                Data Limite:&nbsp;
                <b>{formatLocalDate(selectionResumeData.expirationDate)}</b>
              </p>
            ) : null}
          </div>
        </div>
        <div
          className={`selection-resume__btn-group ${isLoading ? 'skeleton' : ''}`}
        >
          <Template.SelectionHandleButton
            currentId={currentId}
            selectionResumeData={selectionResumeData}
            isLoading={isLoading}
            reviewSelectionButtons={reviewSelectionButtons.length > 0 ? reviewSelectionButtons : undefined}
          />
        </div>
      </div>
    </div>
  );
}
