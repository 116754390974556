/* eslint-disable no-plusplus */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
import React, {
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import moment from 'moment';
import { MdOutlineCreditCard, MdPersonOutline } from 'react-icons/md';
import { AiOutlineBarcode } from 'react-icons/ai';
import { Formik, FormikProps } from 'formik';
import { UI, Template, Form } from '@components';
import { useFormatLocal } from '@hooks';
import { lang } from '@i18n';
import { routes } from '@routes';
import { AuthContext, RouterContext } from '@contexts';
import {
  useOrders,
  usePaymentMethods,
  useShipping,
  useShoppingCart,
  useUsers,
} from '@api';
import { GetShippingMethodsResponse, GetUserInfosResponse } from '@api/types';
import { Order, MercadoPago, Product } from '@types';
import { MercadoPagoService } from '@services/mercado_pago';
import { creditCardSchema } from './schema';

export interface IPaymentMethodInstallments {
  installment: number;
  installmentValue: number;
  totalValue: number;
}

export interface IInstallmentsOptions {
  installmentsOptions: Array<IPaymentMethodInstallments>;
}

type CreditCardFormValueType = {
  cardholderCPF: string;
  cardholderName: string;
  creditCardCVV: string;
  creditCardNumber: string;
  creditCardValidity: string;
};

const initialCreditCardFormValue: CreditCardFormValueType = {
  cardholderCPF: '',
  cardholderName: '',
  creditCardCVV: '',
  creditCardNumber: '',
  creditCardValidity: '',
};

export function Checkout() {
  const checkoutDetailsPlaceholder: Product.Summary[] = [
    {
      productName: 'placehoder',
      quantity: 2,
      productImageUrl: '',
      unitValue: 199,
    },
  ];
  const paymentMethodsTabsPlaceholder: UI.ITab[] = [
    {
      id: 'PIX',
      name: 'Pix',
      iconPngUrl:
        'https://foto-go.s3.sa-east-1.amazonaws.com/assets/images/pix.png',
      handleClick: () => false,
      disabled: true,
    },
    {
      id: 'credit_card',
      name: 'Cartão de crédito',
      Icon: MdOutlineCreditCard,
      handleClick: () => false,
      disabled: true,
    },
    {
      id: 'billet',
      name: 'Boleto',
      Icon: AiOutlineBarcode,
      handleClick: () => false,
      disabled: true,
    },
  ];

  const formRef = useRef<FormikProps<any>>(null);
  const { slugs, navigate } = useContext(RouterContext);
  const { auth } = useContext(AuthContext);
  const { formatCurrencyPtBR } = useFormatLocal();
  const { getShoppingCartItems } = useShoppingCart();
  const { getInstallments } = usePaymentMethods();
  const { getUserInfos } = useUsers();
  const { processOrder } = useOrders();
  const { getShippingMethods } = useShipping();
  const [checkoutDetails, setCheckoutDetails] = useState<Product.Summary[]>(checkoutDetailsPlaceholder);
  const [isCheckoutDetailsLoading, setIsCheckoutDetailsLoading] = useState<boolean>(true);
  const [paymentMethod, setPaymentMethod] = useState<string>('PIX');
  const [MPcreditCardPaymentTokenHasFailed, setMPcreditCardPaymentTokenHasFailed] = useState<boolean>(false);
  const [installmentSelectedIndex, setInstallmentSelectedIndex] = useState(0);
  const [mercadoPagoService, setMercadoPagoService] = useState<any>();
  const [showRedirectToUserSettingsModal, setShowRedirectToUserSettingsModal] = useState(false);
  const [paymentMethodsTabs, setPaymentMethodsTabs] = useState<UI.ITab[]>(paymentMethodsTabsPlaceholder);
  const [paymentMethodsTabsIsLoading, setPaymentMethodsTabsIsLoading] = useState<boolean>(true);
  const [installmentsOptions, setInstallmentsOptions] = useState<Form.ISelectOption[]>([]);
  const [userInfos, setUserInfos] = useState<GetUserInfosResponse>();
  const [shippingMethods, setShippingMethods] = useState<GetShippingMethodsResponse>();
  const [deviceId, setDeviceId] = useState<any>(undefined);
  const [deviceTimer, setDeviceTimer] = useState<any>(null);
  const [isProcessingOrder, setIsProcessingOrder] = useState<boolean>(false);

  const redirectToUserSettingsModalEl: React.ReactNode = (
    <>
      <p>
        Parece que você nunca realizou pagamentos conosco.
      </p>
      <p>
        Vamos preencher o formulário de dados pessoais? É rapidinho!
      </p>
    </>
  );

  useEffect(() => {
    if (!deviceTimer) {
      setDeviceTimer(setInterval(() => {
        if (window.MP_DEVICE_SESSION_ID) {
          setDeviceId(window.MP_DEVICE_SESSION_ID);
        }
      }, 300));
    }
  }, []);

  useEffect(() => {
    if (deviceId) {
      clearInterval(deviceTimer);
    }
  }, [deviceId]);

  function onSelectTab(id: string) {
    setPaymentMethod(id);
  }

  async function initMercadoPagoSDK(): Promise<void> {
    const mercadoPagoService: MercadoPagoService = new MercadoPagoService();
    await mercadoPagoService.init();
    setMercadoPagoService(mercadoPagoService);
  }

  async function getDetails() {
    setIsCheckoutDetailsLoading(true);

    try {
      const { data: item } = await getShoppingCartItems({ partnerSlug: slugs.partner });

      if (item.length === 0) {
        navigate(routes.home());
        return;
      }

      setCheckoutDetails([
        {
          productName: item[0].productName,
          quantity: item[0].quantity,
          productImageUrl: item[0].productImageUrl,
          unitValue: item[0].unitPrice,
        },
      ]);

      const { data: userInfos } = await getUserInfos({
        userId: auth.userId,
      });

      if (!userInfos?.address?.zipCode || !userInfos?.user?.cpf) {
        setShowRedirectToUserSettingsModal(true);
        return;
      }

      setUserInfos(userInfos);

      const { data: shippingMethods } = await getShippingMethods({
        zipCode: userInfos.address.zipCode as string,
        products: [{
          id: item[0].productId,
          optionId: item[0].optionId,
          quantity: item[0].quantity,
        }],
      });

      setShippingMethods(shippingMethods);

      await initMercadoPagoSDK();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }

    setIsCheckoutDetailsLoading(false);
  }

  async function populateInstallments() {
    const totalValue = checkoutDetails.reduce((total, value) => total + (value.unitValue || 0) * value.quantity, 0);
    const { data } = await getInstallments({
      partnerSlug: slugs.partner,
      value: totalValue,
    });

    setInstallmentsOptions(data.map((payment, index: number) => ({
      value: index,
      text: `${payment.installment}x de R$ ${formatCurrencyPtBR(payment.installmentValue)}`,
      comment: `Total: R$ ${formatCurrencyPtBR(payment.totalValue)}`,
    })) || []);

    setPaymentMethodsTabs([
      {
        id: 'PIX',
        name: 'Pix',
        iconPngUrl:
          'https://foto-go.s3.sa-east-1.amazonaws.com/assets/images/pix.png',
        handleClick: () => onSelectTab('PIX'),
        disabled: false,
      },
      {
        id: 'credit_card',
        name: 'Cartão de crédito',
        Icon: MdOutlineCreditCard,
        handleClick: () => onSelectTab('credit_card'),
        disabled: false,
      },
      {
        id: 'billet',
        name: 'Boleto',
        Icon: AiOutlineBarcode,
        handleClick: () => onSelectTab('billet'),
        disabled: totalValue < 5,
        toolTip: 'xaxa',
      },
    ]);

    setPaymentMethodsTabsIsLoading(false);
  }

  async function closeOrder() {
    setIsProcessingOrder(true);

    try {
      const MP_PAYMENT_TYPE_ID = 7;

      const paymentData: Order.PaymentRequest = {
        paymentMethodId: MP_PAYMENT_TYPE_ID,
        mercadoPago: {
          methodCode: paymentMethod === 'billet'
            ? MercadoPago.PaymentType.PaymentSlip
            : paymentMethod === 'PIX'
              ? MercadoPago.PaymentType.Pix
              : MercadoPago.PaymentType.CreditCard,
          deviceId: window.MP_DEVICE_SESSION_ID,
          creditCard: undefined as unknown as MercadoPago.CreditCard,
        } as unknown as MercadoPago.Data,
      };

      const { data } = await processOrder({
        partnerSlug: slugs.partner,
        gallerySlug: slugs.project,
        shippingAddress: userInfos ? userInfos.address : {},
        shippingMethodId: shippingMethods?.shippingMethods[0].id as number,
        registerOrderPaymentRequest: paymentData,
      });
      setIsCheckoutDetailsLoading(false);
      navigate(routes.checkoutFinished(data.orderId));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error({ err });
    }

    setIsProcessingOrder(false);
  }

  const creditCardSubmit = async (values: CreditCardFormValueType) => {
    setMPcreditCardPaymentTokenHasFailed(false);

    let paymentId: string | undefined;
    let paymentToken: string | undefined;

    try {
      try {
        const response = await mercadoPagoService?.instance?.getPaymentMethods({ bin: values.creditCardNumber.substring(0, 6) });
        if (response && response.results && response.results.length > 0) {
          paymentId = response.results[0].id;
        }
      } catch (error) {
        setIsProcessingOrder(false);
        throw error;
      }

      try {
        const response = (await mercadoPagoService?.instance?.createCardToken({
          cardNumber: values.creditCardNumber,
          cardholderName: values.cardholderName,
          securityCode: values.creditCardCVV,
          identificationType: 'CPF',
          identificationNumber: values.cardholderCPF,
          cardExpirationMonth: values.creditCardValidity.split('/')[0].padStart(2, '0'),
          cardExpirationYear: moment(values.creditCardValidity, 'MM/YY').year().toString(),
        }));
        if (response?.id) {
          paymentToken = response.id;
        }
      } catch (error) {
        setMPcreditCardPaymentTokenHasFailed(true);
        setIsProcessingOrder(false);
        throw error;
      }

      setIsProcessingOrder(true);

      try {
        const MP_PAYMENT_TYPE_ID = 7;
        const paymentData: Order.PaymentRequest = {
          paymentMethodId: MP_PAYMENT_TYPE_ID,
          mercadoPago: {
            methodCode: MercadoPago.PaymentType.CreditCard,
            deviceId: window.MP_DEVICE_SESSION_ID,
            creditCard: {
              holderName: values.cardholderName,
              holderDocument: values.cardholderCPF,
              installmentsQuantity: 1,
              token: paymentToken,
              methodId: paymentId,
            },
          } as unknown as MercadoPago.Data,
        };

        const { data } = await processOrder({
          partnerSlug: slugs.partner,
          gallerySlug: slugs.project,
          shippingAddress: userInfos ? userInfos.address : {},
          shippingMethodId: shippingMethods?.shippingMethods[0].id as number,
          registerOrderPaymentRequest: paymentData,
        });
        setIsCheckoutDetailsLoading(false);
        navigate(routes.checkoutFinished(data.orderId));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        throw error;
      }

      setIsProcessingOrder(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  async function prepareOrder() {
    setIsProcessingOrder(true);

    if (!window.MP_DEVICE_SESSION_ID) {
      // eslint-disable-next-line no-alert
      alert('not ready yet');
      return;
    }

    if (paymentMethod === 'credit_card') {
      formRef.current?.submitForm();
    } else {
      closeOrder();
    }
  }

  function onChangeState(_value: number | string) {
    setInstallmentSelectedIndex(_value as number);
  }

  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    populateInstallments();
  }, [checkoutDetails]);

  function navigateToSelections() {
    navigate(routes.selection());
  }

  return (
    <>
      <Template.Header />
      {checkoutDetails && (
        <main className="page__main">
          <section className="page__section checkout">
            <div className="page__container">
              <div className="checkout__flex-container">
                <div className="checkout__heading-wrapper">
                  <Template.Heading
                    titleId="Checkout"
                    onReturnClick={navigateToSelections}
                  />
                </div>
                <div className="checkout__content">
                  <div className="checkout__resume-wrapper">
                    <Template.OrderResume
                      orderResumeSummary={checkoutDetails}
                      isLoading={isCheckoutDetailsLoading}
                      orderTotalValue={checkoutDetails.reduce(
                        (total, value) => total + (value.unitValue || 0) * value.quantity,
                        0,
                      )}
                    />
                  </div>
                  <div className="checkout__payment-methods-wrapper">
                    <div className="payment-methods">
                      <div className="payment-methods__inner">
                        <div className="payment-methods__flex-group payment-methods__flex-group--top">
                          <div className="payment-methods__heading">
                            <p className="payment-methods__title">
                              Formas de pagamentos
                            </p>
                          </div>
                          <div className="payment-methods__tabs-observation-wrapper">
                            <div className="payment-methods__tabs-wrapper">
                              <UI.Tabs
                                variant={UI.TabsEnum.paymentMethods}
                                currentId={paymentMethod}
                                tabs={paymentMethodsTabs}
                                isLoading={paymentMethodsTabsIsLoading}
                              />
                            </div>
                            {paymentMethodsTabs[paymentMethodsTabs.findIndex((p) => p.id === 'billet')].disabled && (
                              <small className="payment-methods__observation">
                                O valor mínimo para emissão de boletos é de R$ 5,00
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="payment-methods__flex-group payment-methods__flex-group--middle">
                          {paymentMethod === 'PIX' && (
                            <div className="payment-methods__tab-content payment-methods__tab-content--pix">
                              <p className="payment-methods__text payment-methods__text--center">
                                O pedido será confirmado somente após aprovação
                                do pagamento.
                              </p>
                            </div>
                          )}
                          {paymentMethod === 'credit_card' && (
                            <div className="payment-methods__tab-content payment-methods__tab-content--credit-card">
                              <div className="payment-methods__form-wrapper">
                                <Formik
                                  innerRef={formRef}
                                  initialValues={initialCreditCardFormValue}
                                  validationSchema={creditCardSchema}
                                  onSubmit={creditCardSubmit}
                                >
                                  {({
                                    errors, touched, handleChange, handleBlur, setFieldValue,
                                  }) => (
                                    <div className="payment-methods__form">
                                      {MPcreditCardPaymentTokenHasFailed && (
                                        <p className="payment-methods__error-message form__error-message form__error-message--large">
                                          Revise os dados do seu cartão de crédito e tente novamente.
                                          <br />
                                          Caso o erro persista, entre em contato com seu fotógrafo.
                                        </p>
                                      )}
                                      <div className="payment-methods__form-container">
                                        <div className="form__group">
                                          <Form.Label name="creditCardNumber" text={lang.translate(lang.keys.forms.checkout.credit_card_number)} />
                                          <Form.MaskedInput
                                            id="creditCardNumber"
                                            name="creditCardNumber"
                                            mask="9999 9999 9999 9999"
                                            onChange={(e) => setFieldValue('creditCardNumber', e.currentTarget.value.replace(/[^\d]/g, ''))}
                                            onBlur={(e) => setFieldValue('creditCardNumber', e.currentTarget.value.replace(/[^\d]/g, ''))}
                                            type="text"
                                            error={touched.creditCardNumber && errors.creditCardNumber ? errors.creditCardNumber : ''}
                                            disabledAutoComplete="nope"
                                            placeholder={lang.translate(lang.keys.forms.checkout.credit_card_number_placeholder)}
                                          />
                                        </div>
                                        <div className="form__group">
                                          <Form.Label name="creditCardValidity" text={lang.translate(lang.keys.forms.checkout.credit_card_validity)} />
                                          <Form.MaskedInput
                                            id="creditCardValidity"
                                            name="creditCardValidity"
                                            mask="99/99"
                                            onChange={(e) => setFieldValue('creditCardValidity', e.currentTarget.value.replace('_', ''))}
                                            onBlur={(e) => setFieldValue('creditCardValidity', e.currentTarget.value.replace('_', ''))}
                                            type="text"
                                            disabledAutoComplete="nope"
                                            placeholder={lang.translate(lang.keys.forms.checkout.credit_card_validity_placeholder)}
                                          />
                                        </div>
                                        <div className="form__group">
                                          <Form.Label name="creditCardCVV" text={lang.translate(lang.keys.forms.checkout.credit_card_cvv)} />
                                          <Form.MaskedInput
                                            id="creditCardCVV"
                                            name="creditCardCVV"
                                            mask="999"
                                            onChange={(e) => setFieldValue('creditCardCVV', e.currentTarget.value.replace(/[^\d]/g, ''))}
                                            onBlur={(e) => setFieldValue('creditCardCVV', e.currentTarget.value.replace(/[^\d]/g, ''))}
                                            type="text"
                                            error={touched.creditCardCVV && errors.creditCardCVV ? errors.creditCardCVV : ''}
                                            disabledAutoComplete="nope"
                                            placeholder={lang.translate(lang.keys.forms.checkout.credit_card_cvv_placeholder)}
                                          />
                                        </div>
                                        <div className="form__group">
                                          <Form.Label name="cardholderName" text={lang.translate(lang.keys.forms.checkout.cardholder_name)} />
                                          <Form.Input
                                            id="cardholderName"
                                            name="cardholderName"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            type="text"
                                            error={touched.cardholderName && errors.cardholderName ? errors.cardholderName : ''}
                                            disabledAutoComplete="nope"
                                            placeholder={lang.translate(lang.keys.forms.checkout.cardholder_name_placeholder)}
                                          />
                                        </div>
                                        <div className="form__group">
                                          <Form.Label name="cardholderCPF" text={lang.translate(lang.keys.forms.checkout.cardholder_cpf)} />
                                          <Form.MaskedInput
                                            id="cardholderCPF"
                                            name="cardholderCPF"
                                            mask="999.999.999-99"
                                            onChange={(e) => setFieldValue('cardholderCPF', e.currentTarget.value.replace(/[^\d]/g, ''))}
                                            onBlur={(e) => setFieldValue('cardholderCPF', e.currentTarget.value.replace(/[^\d]/g, ''))}
                                            type="text"
                                            error={touched.cardholderCPF && errors.cardholderCPF ? errors.cardholderCPF : ''}
                                            disabledAutoComplete="nope"
                                            placeholder={lang.translate(lang.keys.forms.checkout.cardholder_cpf_placeholder)}
                                          />
                                        </div>
                                        <div className="form__group">
                                          <Form.Label name="installments" text="Número de parcelas" />
                                          <Form.Select
                                            name="installments"
                                            selectOptions={installmentsOptions}
                                            selectedValue={installmentSelectedIndex}
                                            onChange={onChangeState}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Formik>
                              </div>
                            </div>
                          )}
                          {paymentMethod === 'billet' && (
                            <div className="payment-methods__tab-content payment-methods__tab-content--billet">
                              <p className="payment-methods__text payment-methods__text--center">
                                O pedido será confirmado somente após aprovação
                                do pagamento.
                                <br />
                                O prazo final tal é de 2 dias úteis, após
                                efetuado.
                              </p>
                            </div>
                          )}

                          <div className="payment-methods__total-value-wrapper">
                            <p className="payment-methods__total-value">
                              Valor Final:&nbsp;&nbsp;
                              <span className={`payment-methods__total-value payment-methods__total-value--primary ${isCheckoutDetailsLoading ? 'skeleton' : ''}`}>
                                {formatCurrencyPtBR(
                                  checkoutDetails.reduce(
                                    (total, value) => total
                                      + (value.unitValue || 0) * value.quantity,
                                    0,
                                  ),
                                )}
                                {paymentMethod === 'credit_card' && (
                                  <span className="payment-methods__total-value payment-methods__total-value--primary payment-methods__total-value payment-methods__total-value--500">
                                    &nbsp;em
                                    {' '}
                                    {installmentsOptions.map(
                                      ({ value, text }) => {
                                        if (
                                          value === installmentSelectedIndex
                                        ) {
                                          return text;
                                        }
                                      },
                                    )}
                                  </span>
                                )}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="payment-methods__flex-group payment-methods__flex-group--bottom">
                          <UI.Button
                            type="button"
                            loading={!deviceId || isProcessingOrder}
                            onClick={prepareOrder}
                            variant={UI.ButtonEnum.primary}
                            text="Finalizar compra"
                            disabled={!deviceId || isProcessingOrder}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      )}
      <Template.Footer />

      <UI.FullscreenLoader
        textFristLine="Aguarde, estamos processando seu pagamento. Este pocesso pode demorar até 1 minuto."
        active={isProcessingOrder}
      />

      <UI.Modal
        isOpen={showRedirectToUserSettingsModal}
        maxWidth={560}
        header={{
          title: 'Dados pessoais',
          Icon: MdPersonOutline,
        }}
        contentEl={redirectToUserSettingsModalEl}
        buttonGroupDirection="column"
        closeable={false}
        onClose={async () => { }}
        positiveButton={{
          label: 'Ir para "Meu Cadastro"',
          onClick() {
            navigate(routes.checkoutUserSettings());
            setShowRedirectToUserSettingsModal(false);
          },
          variant: UI.ButtonEnum.primary,
        }}
      />
    </>
  );
}
