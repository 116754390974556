import moment from 'moment/moment';
import 'moment/locale/pt-br';

function useFormatLocal() {
  moment.locale('pt-br');

  const formatCurrencyPtBR = (currencyValue: number) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(
    currencyValue,
  );

  const formatLocalDate = (date: Date) => moment(date).local().format('DD/MM/YYYY');

  const humanizeLocalDate = (date: Date) => moment(moment(date).local(), 'DD-MM-YYYY').fromNow();

  return { formatCurrencyPtBR, formatLocalDate, humanizeLocalDate };
}

export { useFormatLocal };
