import {
  AlbumApproval,
  Albums,
  Checkout,
  CheckoutFinished,
  Download,
  DownloadRequest,
  Gallery,
  Home,
  Login,
  NotFound,
  OrderDetail,
  Orders,
  Recovery,
  Selection,
  SelectionReview,
  UserSettings,
  UserSettingsRecovery,
  UserSettingsReset,
} from '@pages';
import { Register } from '@pages/register';
import { CheckoutUserSettings } from '@pages/checkout-user-settings';
import { getProjectSlug as gps } from './utils';
import { FotoGoRoute } from './types';
import { AlreadyAuthenticatedGuard, RequireAuthGuard } from './guards';

const buildPath = (path: string, projectSlug?: string) => `${gps(projectSlug)}/${path}`;

const getBasicRoutePath = (path: string) => ((projectSlug?: string) => buildPath(path, projectSlug));

export const paths = {
  notFound: () => '404',
  login: getBasicRoutePath('login'),
  home: getBasicRoutePath('home'),
  recovery: getBasicRoutePath('recovery'),
  albums: getBasicRoutePath('albums'),
  albumApproval: (albumId: string, projectSlug?: string) => buildPath(`album-approval/${albumId}`, projectSlug),
  checkout: getBasicRoutePath('checkout'),
  checkoutUserSettings: getBasicRoutePath('checkout-user-settings'),
  checkoutFinished: getBasicRoutePath('checkout-finished'),
  downloads: getBasicRoutePath('downloads'),
  downloadRequest: (sceneId: string, projectSlug?: string) => buildPath(`download-request/${sceneId}`, projectSlug),
  gallery: getBasicRoutePath('gallery'),
  selection: getBasicRoutePath('selection'),
  selectionReview: (selectionId: string, projectSlug?: string) => buildPath(`selection-review/${selectionId}`, projectSlug),
  orders: getBasicRoutePath('orders'),
  orderDetail: (orderId: string, projectSlug?: string) => buildPath(`order-detail/${orderId}`, projectSlug),
  register: getBasicRoutePath('register'),
  userSettings: getBasicRoutePath('user-settings'),
  userSettingsRecovery: getBasicRoutePath('user-settings-recovery'),
  userSettingsReset: getBasicRoutePath('user-settings-reset'),
};

export type MappedRoutes = keyof (typeof paths)

const slug = '/:projectSlug';
export const routesMap: Record<MappedRoutes, FotoGoRoute> = {
  notFound: {
    path: paths.notFound(),
    guards: [],
    element: <NotFound />,
  },
  login: {
    path: paths.login(slug),
    guards: [AlreadyAuthenticatedGuard],
    element: <Login />,
  },
  home: {
    path: paths.home(slug),
    guards: [RequireAuthGuard],
    element: <Home />,
  },
  recovery: {
    path: paths.recovery(slug),
    guards: [RequireAuthGuard],
    element: <Recovery />,
  },
  albums: {
    path: paths.albums(slug),
    guards: [RequireAuthGuard],
    element: <Albums />,
  },
  albumApproval: {
    path: paths.albumApproval('/:albumId', slug),
    guards: [RequireAuthGuard],
    element: <AlbumApproval />,
  },
  checkout: {
    path: paths.checkout(slug),
    guards: [RequireAuthGuard],
    element: <Checkout />,
  },
  checkoutUserSettings: {
    path: paths.checkoutUserSettings(slug),
    guards: [RequireAuthGuard],
    element: <CheckoutUserSettings />,
  },
  checkoutFinished: {
    path: paths.checkoutFinished(slug),
    guards: [RequireAuthGuard],
    element: <CheckoutFinished />,
  },
  downloads: {
    path: paths.downloads(slug),
    guards: [RequireAuthGuard],
    element: <Download />,
  },
  downloadRequest: {
    path: paths.downloadRequest('/:sceneId', slug),
    guards: [RequireAuthGuard],
    element: <DownloadRequest />,
  },
  gallery: {
    path: paths.gallery(slug),
    guards: [RequireAuthGuard],
    element: <Gallery />,
  },
  selection: {
    path: paths.selection(slug),
    guards: [RequireAuthGuard],
    element: <Selection />,
  },
  selectionReview: {
    path: paths.selectionReview('/:selectionId', slug),
    guards: [RequireAuthGuard],
    element: <SelectionReview />,
  },
  orders: {
    path: paths.orders(slug),
    guards: [RequireAuthGuard],
    element: <Orders />,
  },
  orderDetail: {
    path: paths.orderDetail('/:orderId', slug),
    guards: [RequireAuthGuard],
    element: <OrderDetail />,
  },
  register: {
    path: paths.register(slug),
    guards: [],
    element: <Register />,
  },
  userSettings: {
    path: paths.userSettings(slug),
    guards: [RequireAuthGuard],
    element: <UserSettings />,
  },
  userSettingsRecovery: {
    path: paths.userSettingsRecovery(slug),
    guards: [RequireAuthGuard],
    element: <UserSettingsRecovery />,
  },
  userSettingsReset: {
    path: paths.userSettings(slug),
    guards: [RequireAuthGuard],
    element: <UserSettingsReset />,
  },
};
