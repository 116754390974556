import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Template } from '@components';

interface FixedSelectionSummaryProps {
  currentId: string;
  selectionResumeData: Template.ISelectionResume;
  reveal: boolean;
  currentValue: number;
  maxValue: number;
  isLoading: boolean;
}

export function FixedSelectionSummary({
  currentId,
  selectionResumeData,
  reveal,
  currentValue,
  maxValue,
  isLoading,
}: FixedSelectionSummaryProps) {
  return (
    <div
      className={`fixed-selec-summ ${(!reveal || isLoading) && 'fixed-selec-summ--hidden'}`}
    >
      <div className="fixed-selec-summ__inner">
        <div className="fixed-selec-summ__gorup">
          <p className="fixed-selec-summ__text">Seleção</p>
        </div>
        <div className="fixed-selec-summ__gorup fixed-selec-summ__gorup--progress">
          <CircularProgressbar
            value={currentValue}
            maxValue={maxValue || currentValue}
            text={maxValue === undefined ? `${currentValue}` : `${currentValue}/${maxValue}`}
            strokeWidth={10}
          />
        </div>
        <div className="fixed-selec-summ__gorup fixed-selec-summ__gorup--linear-progress">
          <div className="fixed-selec-summ__counter">
            <span className="fixed-selec-summ__number">
              {currentValue}
            </span>
            {maxValue && (
              <span className="fixed-selec-summ__number">
                &nbsp;/&nbsp;
                {maxValue}
              </span>
            )}
          </div>
          <div className="fixed-selec-summ__bar">
            <span
              className="fixed-selec-summ__bar fixed-selec-summ__bar--progress"
              style={{ width: maxValue ? `${(currentValue * 100) / maxValue}%` : '100%' }}
            />
          </div>
        </div>
        <div className="fixed-selec-summ__gorup">
          <Template.SelectionHandleButton
            currentId={currentId}
            selectionResumeData={selectionResumeData}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}
