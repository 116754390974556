/* revisar componente na implementação das pages */

import { useEffect, useRef, useState } from 'react';
import {
  MdOutlineComment,
  MdFavoriteBorder,
  MdOutlineClose,
  MdOutlineChevronLeft,
  MdChevronRight,
  MdOutlineFavorite,
} from 'react-icons/md';
import { useKeenSlider } from 'keen-slider/react';
import 'keen-slider/keen-slider.min.css';
import { useReplaceThumbUrls, ThumbSizes } from '@hooks';
import { UI } from '@components';
import { Gallery } from '@types';

interface ImageViewerProps {
  currentIndex: number;
  initialSlide: number;
  disableButtonHeart?: boolean;
  hiddenComment?: boolean;
  hiddenHeart?: boolean;
  isSelected?: boolean;
  sliderImages: Gallery.ImageThumb[];
  onCloseClick: () => void;
  onSelectClick?: () => void;
  onCommentClick?: () => void;
  onPrevSlide?: () => void;
  onNextSlide?: () => void;
}

interface ArrowProps {
  disabled: boolean;
  left?: boolean;
  onClick: (_e: any) => void;
}

function Arrow({
  disabled, left, onClick,
}: ArrowProps) {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`image-viewer__slider-arrow ${
        left
          ? 'image-viewer__slider-arrow--left'
          : 'image-viewer__slider-arrow--right'
      } ${disabled ? 'image-viewer__slider-arrow--disabled' : ''}`}
    >
      {left ? (
        <MdOutlineChevronLeft className="image-viewer__slider-arrow-icon" />
      ) : (
        <MdChevronRight className="image-viewer__slider-arrow-icon" />
      )}
    </button>
  );
}

export function ImageViewer({
  currentIndex,
  initialSlide,
  disableButtonHeart,
  hiddenComment,
  hiddenHeart,
  isSelected,
  sliderImages,
  onCloseClick,
  onSelectClick,
  onCommentClick,
  onPrevSlide = undefined,
  onNextSlide = undefined,
}: ImageViewerProps) {
  const { replaceThumbUrls } = useReplaceThumbUrls();
  const [currentSlide, setCurrentSlide] = useState<number>(initialSlide);
  const [preventChangeSlide, setPreventChangeSlide] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  // const [windowListenerController] = useState<AbortController>(new AbortController());
  const imageSliderRef = useRef<HTMLDivElement>(null);

  function onLeftClick(event: any, sliderInstance: any): void {
    event.stopPropagation();
    if (preventChangeSlide) return;
    if (sliderInstance && sliderInstance.current && (currentSlide === 0)) return;
    sliderInstance.current?.prev();
    if (typeof onPrevSlide === 'function') {
      onPrevSlide();
    }
  }

  function onRightClick(event: any, sliderInstance: any): void {
    event.stopPropagation();
    if (preventChangeSlide) return;
    if (sliderInstance && sliderInstance.current && (currentSlide === sliderInstance.current.track.details.slides.length - 1)) return;
    sliderInstance.current?.next();
    if (typeof onNextSlide === 'function') {
      onNextSlide();
    }
  }

  // function sliderEventsListener(event: any, instanceRef: any): void {
  //   const availableKeyCodes = ['ArrowRight', 'ArrowLeft', 'Escape'];
  //   if (availableKeyCodes.includes(event.code)) {
  //     event.stopPropagation();
  //     console.log('preventChangeSlide', preventChangeSlide);
  //     if (event.code === 'ArrowRight') {
  //       onRightClick(event, instanceRef);
  //     } else if (event.code === 'ArrowLeft') {
  //       onLeftClick(event, instanceRef);
  //     } else if (event.code === 'Escape') {
  //       onCloseClick();
  //     }
  //   }
  // }

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: initialSlide,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
    created() {
      setLoaded(true);
      // window.addEventListener(
      //   'keyup',
      //   (event) => sliderEventsListener(event, instanceRef),
      //   { signal: windowListenerController.signal },
      // );
    },
  });

  useEffect(() => {
    if (preventChangeSlide === false) {
      setPreventChangeSlide(true);
      setTimeout(() => {
        setPreventChangeSlide(false);
      }, 200);
    }
  }, [currentSlide]);

  return (
    <div
      ref={imageSliderRef}
      className={`image-viewer ${!loaded ? 'image-viewer--is-loading' : ''}`}
    >
      <div className="image-viewer__backdrop" />
      <div className="image-viewer__container">
        <div className="image-viewer__actions">
          <div className="image-viewer__action-group" />
          <div className="image-viewer__action-group">
            {!hiddenComment && isSelected && (
              <button type="button" className="image-viewer__btn" onClick={onCommentClick}>
                <MdOutlineComment className="image-viewer__btn-icon" />
              </button>
            )}
            {!hiddenHeart && (
              <button
                disabled={disableButtonHeart}
                className="image-viewer__btn"
                onClick={onSelectClick}
                type="button"
              >
                {isSelected ? (
                  <MdOutlineFavorite className="image-viewer__btn-icon" />
                ) : (
                  <MdFavoriteBorder className="image-viewer__btn-icon" />
                )}
              </button>
            )}
            <button
              className="image-viewer__btn"
              onClick={() => {
                // windowListenerController.abort();
                onCloseClick();
              }}
              type="button"
            >
              <MdOutlineClose className="image-viewer__btn-icon" />
            </button>
          </div>
        </div>
        <div className="image-viewer__slider-wrapper">
          <div className="image-viewer__skeleton" />
          <div className="image-viewer__slider">
            <div
              ref={sliderRef}
              className="image-viewer__slider-container keen-slider"
            >
              {sliderImages.map(({ id, url }) => (
                <div
                  key={id}
                  className="image-viewer__slider-item keen-slider__slide"
                >
                  <UI.ProgressiveImg
                    classes="image-viewer__img"
                    width="1920"
                    height="1080"
                    src={replaceThumbUrls(url, ThumbSizes.big)}
                    placeholderSrc={replaceThumbUrls(url, ThumbSizes.small)}
                    alt=""
                  />
                  {/* <img
                    className="image-viewer__img"
                    width="1920"
                    height="1080"
                    src={replaceThumbUrls(url, ThumbSizes.big)}
                    alt=""
                  /> */}
                </div>
              ))}
            </div>
            {loaded && instanceRef.current && (
              <>
                <Arrow
                  left
                  onClick={(event) => {
                    onLeftClick(event, instanceRef);
                  }}
                  disabled={
                    instanceRef
                    && instanceRef.current
                    && (currentSlide === 0)
                  }
                />

                <Arrow
                  onClick={(event) => {
                    onRightClick(event, instanceRef);
                  }}
                  disabled={
                    currentSlide === instanceRef.current.track.details.slides.length - 1
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
