import {
  FormEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Formik } from 'formik';
import {
  Template, Form, UI, Notification,
} from '@components';
import { lang } from '@i18n';
import { useFormatLocal } from '@hooks';
import { routes } from '@routes';
import { AuthContext, RouterContext } from '@contexts';
import { useShipping, useStates, useUsers } from '@api';
import { checkoutUserSettingsSchema } from './schema';

type CheckoutUserSettingsFormValueType = {
  name: string;
  email: string;
  cpf: string;
  gender: string;
  birthday: string;
  phone: string;
  zipCode: string;
  city: string;
  cityId: number;
  state: string;
  stateId: number;
  address: string;
  addressId: number;
  number: string;
  address2: string;
  district: string;
  districtId: number;
}

const initialCheckoutUserSettingsFormValue: CheckoutUserSettingsFormValueType = {
  name: '',
  email: '',
  cpf: '',
  gender: '0',
  birthday: '',
  phone: '',
  zipCode: '',
  city: '',
  cityId: 0,
  state: '',
  stateId: 0,
  address: '',
  addressId: 0,
  number: '',
  address2: '',
  district: '',
  districtId: 0,
};

export function CheckoutUserSettings() {
  const { navigate } = useContext(RouterContext);
  const { auth } = useContext(AuthContext);
  const { formatLocalDate } = useFormatLocal();
  const { getUserInfos, updateUserInfos } = useUsers();
  const { getStates } = useStates();
  const { getAddressByZipCode } = useShipping();
  const [initialState, setInitialState] = useState<CheckoutUserSettingsFormValueType>(initialCheckoutUserSettingsFormValue);
  const [loading, setLoading] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);

  const [stateOptions, setStateOptions] = useState<Array<Form.ISelectOption>>([
    { value: 0, text: 'Selecione o estado' },
  ]);

  const genderOptions: Array<Form.ISelectOption> = ([
    { value: '0', text: 'Selecione o sexo' },
    { value: 'M', text: 'Masculino' },
    { value: 'F', text: 'Feminino' },
  ]);

  async function getUserDetails() {
    const { data: userDetails } = await getUserInfos({ userId: auth.userId || '' });
    setInitialState({
      name: userDetails.user.name || '',
      email: userDetails.user.email || '',
      cpf: userDetails.user.cpf || '',
      birthday: userDetails.user.birthday
        ? formatLocalDate(userDetails.user.birthday)
        : '',
      gender: userDetails.user.gender || '0',
      phone: userDetails.user.phone || '',
      zipCode: userDetails.address.zipCode || '',
      city: userDetails.address?.city?.name || '',
      cityId: userDetails.address?.city?.id || 0,
      state: userDetails.address?.state?.name || '',
      stateId: userDetails.address?.state?.id || 0,
      address: userDetails.address?.address1 || '',
      addressId: userDetails.address?.addressId || 0,
      number: userDetails.address?.number || '',
      address2: userDetails.address?.address2 || '',
      district: userDetails.address?.district?.name || '',
      districtId: userDetails.address?.district?.id || 0,
    });
  }

  async function getStatesOptions() {
    if (stateOptions.length > 1) return;

    const { data: stateOptionsList } = await getStates();
    setStateOptions(stateOptionsList.map((so) => ({ value: so.id || '', text: so.name })));
  }

  useEffect(() => {
    getUserDetails();
    getStatesOptions();
  }, []);

  function onClickModal() {
    setHasSuccess(false);
  }

  const handleSubmit = async (values: CheckoutUserSettingsFormValueType) => {
    try {
      setLoading(true);

      await updateUserInfos({
        user: {
          name: values.name,
          email: values.email,
          cpf: values.cpf,
          gender: values.gender,
          phone: values.phone,
          birthday: new Date(values.birthday.split('/').reverse().join('/').toString()),
        },
        address: {
          zipCode: values.zipCode,
          city: {
            id: values.cityId,
            name: values.city,
          },
          state: {
            id: values.stateId,
            name: values.state,
          },
          district: {
            id: values.districtId,
            name: values.district,
          },
          addressId: values.addressId,
          address1: values.address,
          address2: values.address2,
          number: values.number,
        },
      });
    } finally {
      setLoading(false);
      setHasSuccess(true);
    }
  };

  const onClickHome = () => {
    navigate(routes.home());
  };

  return (
    <>
      <Template.Header />
      <main className="page__main user-settings">
        <section className="page__section">
          <div className="page__container">
            <div className="user-settings__heading-wrapper">
              <Template.Heading
                titleId="CheckoutUserSettings"
                onReturnClick={onClickHome}
              />
            </div>
            <Formik initialValues={initialState} validationSchema={checkoutUserSettingsSchema} onSubmit={handleSubmit}>
              {({
                errors, touched, handleChange, handleBlur, values, setFieldValue,
              }) => (
                <div className="user-settings__forms-area">
                  <div className="user-settings__flex-container">
                    <div className="user-settings__left">
                      <div className="page__bg-area">
                        <div className="page__heading">
                          <h5 className="page__title">
                            Dados pessoais
                          </h5>
                        </div>
                        <div className="user-settings__user-profile-form">
                          <div className="form__grid user-settings__user-profile-grid">
                            <div className="form__group">
                              <Form.Label name="name" text={lang.translate(lang.keys.forms.users.name)} />
                              <Form.Input
                                id="name"
                                name="name"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                error={touched.name && errors.name ? errors.name : ''}
                                disabledAutoComplete="nope"
                                placeholder={lang.translate(lang.keys.forms.users.name_placeholder)}
                              />
                            </div>
                            <div className="form__group">
                              <Form.Label name="cpf" text={lang.translate(lang.keys.forms.users.cpf)} />
                              <Form.MaskedInput
                                id="cpf"
                                name="cpf"
                                mask="999.999.999-99"
                                onChange={(e) => setFieldValue('cpf', e.currentTarget.value.replace(/[^\d]/g, ''))}
                                onBlur={(e) => setFieldValue('cpf', e.currentTarget.value.replace(/[^\d]/g, ''))}
                                type="text"
                                error={touched.cpf && errors.cpf ? errors.cpf : ''}
                                disabledAutoComplete="nope"
                                placeholder={lang.translate(lang.keys.forms.users.cpf_placeholder)}
                              />
                            </div>
                            <div className="form__group">
                              <Form.Label name="gender" text={lang.translate(lang.keys.forms.users.gender)} />
                              <Form.Select
                                name="gender"
                                selectOptions={genderOptions}
                                onChange={(_value: number | string) => {
                                  setFieldValue('gender', _value);
                                }}
                                selectedValue={values.gender}
                                error={touched.gender && errors.gender ? errors.gender : ''}
                              />
                            </div>
                            <div className="form__group">
                              <Form.Label name="birthday" text={lang.translate(lang.keys.forms.users.birthday)} />
                              <Form.MaskedInput
                                id="birthday"
                                name="birthday"
                                mask="99/99/9999"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                error={touched.birthday && errors.birthday ? errors.birthday : ''}
                                disabledAutoComplete="nope"
                                placeholder={lang.translate(lang.keys.forms.users.birthday_placeholder)}
                              />
                            </div>
                            <div className="form__group">
                              <Form.Label name="phone" text={lang.translate(lang.keys.forms.users.phone)} />
                              <Form.MaskedInput
                                id="phone"
                                name="phone"
                                mask="(99) 99999-9999"
                                onChange={(e) => setFieldValue('phone', e.currentTarget.value.replace(/[^\d]/g, ''))}
                                onBlur={(e) => setFieldValue('phone', e.currentTarget.value.replace(/[^\d]/g, ''))}
                                type="text"
                                error={touched.phone && errors.phone ? errors.phone : ''}
                                disabledAutoComplete="nope"
                                placeholder={lang.translate(lang.keys.forms.users.phone_placeholder)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="user-settings__right">
                      <div className="page__bg-area">
                        <div className="page__heading">
                          <h5 className="page__title">
                            Endereço
                          </h5>
                        </div>
                        <div className="user-settings__user-adress-form">
                          <div className="form__grid user-settings__user-adress-grid">
                            <div className="form__group">
                              <Form.Label name="zipCode" text={lang.translate(lang.keys.forms.users.zipCode)} />
                              <Form.MaskedInput
                                id="zipCode"
                                name="zipCode"
                                mask="99999-999"
                                onChange={(e) => setFieldValue('zipCode', e.currentTarget.value.replace(/[^\d]/g, ''))}
                                onBlur={async (e) => {
                                  if (!e.currentTarget.value) return;
                                  const zipCode = e.currentTarget.value;
                                  const { data: zipCodeDetails } = await getAddressByZipCode({
                                    userId: auth.userId || '',
                                    zipCode: zipCode.replace(/[^\d]/g, ''),
                                  });
                                  setFieldValue('address', zipCodeDetails.address1);
                                  setFieldValue('addressId', zipCodeDetails.addressId || 0);
                                  setFieldValue('city', zipCodeDetails.city.name);
                                  setFieldValue('cityId', zipCodeDetails.city.id || 0);
                                  setFieldValue('district', zipCodeDetails.district.name);
                                  setFieldValue('districtId', zipCodeDetails.district.id || 0);
                                  setFieldValue('state', zipCodeDetails.state.name);
                                  setFieldValue('stateId', zipCodeDetails.state.id);
                                }}
                                type="text"
                                error={touched.zipCode && errors.zipCode ? errors.zipCode : ''}
                                disabledAutoComplete="nope"
                                placeholder={lang.translate(lang.keys.forms.users.zipCode_placeholder)}
                              />
                            </div>
                            <div className="form__group">
                              <Form.Label name="city" text={lang.translate(lang.keys.forms.users.city)} />
                              <Form.Input
                                id="city"
                                name="city"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                error={touched.city && errors.city ? errors.city : ''}
                                disabledAutoComplete="nope"
                                placeholder={lang.translate(lang.keys.forms.users.city_placeholder)}
                              />
                            </div>
                            <div className="form__group">
                              <Form.Label name="state" text={lang.translate(lang.keys.forms.users.state)} />
                              <Form.Select
                                name="state"
                                selectOptions={stateOptions}
                                onChange={(_value: number | string) => {
                                  const state = stateOptions.filter((state) => state.value === _value);
                                  setFieldValue('stateId', state[0].value as number);
                                  setFieldValue('state', state[0].text);
                                }}
                                selectedValue={values.stateId}
                                error={touched.state && errors.state ? errors.state : ''}
                              />
                            </div>
                            <div className="form__group">
                              <Form.Label name="address1" text={lang.translate(lang.keys.forms.users.address1)} />
                              <Form.Input
                                id="address"
                                name="address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                error={touched.address && errors.address ? errors.address : ''}
                                disabledAutoComplete="nope"
                                placeholder={lang.translate(lang.keys.forms.users.address1_placeholder)}
                              />
                            </div>
                            <div className="form__group">
                              <Form.Label name="number" text={lang.translate(lang.keys.forms.users.number)} />
                              <Form.Input
                                id="number"
                                name="number"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                error={touched.number && errors.number ? errors.number : ''}
                                disabledAutoComplete="nope"
                                placeholder={lang.translate(lang.keys.forms.users.number_placeholder)}
                              />
                            </div>
                            <div className="form__group">
                              <Form.Label name="address2" text={lang.translate(lang.keys.forms.users.address2)} />
                              <Form.Input
                                id="address2"
                                name="address2"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                error={touched.address2 && errors.address2 ? errors.address2 : ''}
                                disabledAutoComplete="nope"
                                placeholder={lang.translate(lang.keys.forms.users.address2_placeholder)}
                              />
                            </div>
                            <div className="form__group">
                              <Form.Label name="district" text={lang.translate(lang.keys.forms.users.district)} />
                              <Form.Input
                                id="district"
                                name="district"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type="text"
                                error={touched.district && errors.district ? errors.district : ''}
                                disabledAutoComplete="nope"
                                placeholder={lang.translate(lang.keys.forms.users.district_placeholder)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="user-settings__btn-group">
                        <div className="form__btn-message">
                          <UI.Button
                            type="submit"
                            loading={loading}
                            variant={UI.ButtonEnum.primary}
                            fullwidth="w-full"
                            text="Salvar e voltar para o Checkout"
                          />
                          {hasSuccess && (
                            <Notification.Message
                              type="success"
                              onClick={onClickModal}
                              message="Dados atualizados com sucesso."
                            />
                          )}
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </section>
      </main>
      <Template.Footer />
    </>
  );
}
