import { UI } from '@components';

interface LogoProps {
  title: string;
  description?: string;
  buttonLabel?: string;
  onButtonClick?: () => void;
}

export function NoContent({
  title,
  description,
  buttonLabel,
  onButtonClick,
}: LogoProps) {
  return (
    <div className="no-content">
      <div className="no-content__heading">
        {title && (
          <h5 className="no-content__title">
            {title}
          </h5>
        )}
        {description && (
          <p className="no-content__desc">
            {description}
          </p>
        )}
      </div>
      {buttonLabel && (
        <UI.Button
          variant={UI.ButtonEnum.primaryOutline}
          onClick={onButtonClick}
          type="button"
          text={buttonLabel}
          loading={false}
        />
      )}
    </div>
  );
}
