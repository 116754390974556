import { useRef, useState } from 'react';
import { MdOutlineImageNotSupported } from 'react-icons/md';
import { Template } from '@components';
import { Album } from '@types';

export interface IAlbumSummary extends Album.Item {
  handleButtonClick: () => void;
}

interface CardAlbumProps {
  albumSummary: IAlbumSummary;
  selectionProgressCardSummary: Template.ISelectionProgressCardSummary;
  isLoading: boolean;
}

export function CardAlbum({
  albumSummary,
  selectionProgressCardSummary,
  isLoading,
}: CardAlbumProps) {
  const imagePreviewEl = useRef<HTMLImageElement>(null);
  const imageWrapperEl = useRef<HTMLDivElement>(null);
  const imagePreviewBaseUrl = 'https://api.3d-viewer.goimage.com.br/projects/id/previewImage';
  const viewerId = albumSummary.viewerUrl.split('=')[1];
  const imagePreviewUrl = imagePreviewBaseUrl.replace('id', viewerId);

  const [hideImage, setHideImage] = useState(false);

  function handleImagePreviewLoadError() {
    setHideImage(true);
  }

  const removeSkeletonClasses = () => {
    imageWrapperEl.current?.classList.remove(...['skeleton', 'skeleton--neutral-90']);
  };
  return (
    <div className="card-album">
      <div
        className="card-album__inner"
        onClick={albumSummary.handleButtonClick}
      >
        <div className={`card-album__top ${isLoading ? 'skeleton skeleton--neutral-90' : ''}`}>
          <div
            className="card-album__img-wrapper skeleton skeleton--neutral-90"
            ref={imageWrapperEl}
          >
            <div className="card-album__overlay" />
            <img
              className="card-album__img"
              ref={imagePreviewEl}
              alt={`Capa do album ${albumSummary.name}`}
              src={imagePreviewUrl}
              onLoad={() => {
                removeSkeletonClasses();
              }}
              onError={() => {
                handleImagePreviewLoadError();
                removeSkeletonClasses();
              }}
              style={{ display: hideImage ? 'none' : 'block' }}
            />
            <MdOutlineImageNotSupported className="card-album__img-error" style={{ display: !hideImage ? '' : 'block' }} />
          </div>
        </div>
        <div className="card-album__content">
          <div className="card-album__selection-progress-wrapper">
            <Template.SelectionProgressCard
              isLoading={isLoading}
              selectionProgressCardSummary={selectionProgressCardSummary}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
