/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-useless-fragment */
import {
  MdOutlineCheck,
  MdOutlineMonetizationOn,
  MdOutlineMoving,
  MdOutlineBlock,
} from 'react-icons/md';
import { Payment } from '@types';

interface PaymentStatusProps {
  status: Payment.Status;
  isLoading: boolean;
}

const cardOrderIconsLabel = [
  {
    name: 'Pending',
    label: 'Aguardando',
    Icon: MdOutlineMonetizationOn,
  },
  {
    name: 'Processing',
    label: 'Em andamento',
    Icon: MdOutlineMoving,
  },
  {
    name: 'Redeem',
    label: 'Concluído',
    Icon: MdOutlineCheck,
  },
  {
    name: 'Denied',
    label: 'Cancelado',
    Icon: MdOutlineBlock,
  },
];

export function PaymentStatus({ status, isLoading }: PaymentStatusProps) {
  return (
    <>
      {status
        && cardOrderIconsLabel.map(({ name, label, Icon }, index) => {
          if (status === name) {
            return (
              <div
                key={index}
                className={`payment-status payment-status--${name.toLocaleLowerCase()} ${isLoading ? 'skeleton' : ''}`}
              >
                <Icon className="payment-status__icon" />
                <span className="payment-status__text">{label}</span>
              </div>
            );
          }
        })}
    </>
  );
}
