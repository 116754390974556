import { useEffect, useState, useContext } from 'react';
import { Template, UI } from '@components';
import { useWindowSize } from '@hooks';
import { BannerContext } from '@contexts';

export function Home() {
  const windowSize = useWindowSize();
  const isDesktopScreen: boolean = windowSize.width > 1024;
  const { bannerTemplate } = useContext(BannerContext);

  const [uiRenderOptions, setUiRenderOptions] = useState({
    logo: UI.LogoEnum.logoHome,
    projectTitle: Template.ProjectTitleEnum.home,
    navbar: UI.NavbarEnum.Home1,
    socialbar: Template.SocialbarEnum.row,
  });

  function setBodyElModifierClass(): void {
    document.body.classList.add('page__body--h100');
  }

  useEffect(() => {
    setBodyElModifierClass();
  }, []);

  useEffect(() => {
    switch (bannerTemplate) {
      case 'banner1':
        setUiRenderOptions({
          logo: UI.LogoEnum.logoHome,
          projectTitle: Template.ProjectTitleEnum.home,
          navbar: UI.NavbarEnum.Home1,
          socialbar: Template.SocialbarEnum.row,
        });
        break;
      case 'banner2':
        setUiRenderOptions({
          logo: UI.LogoEnum.logoHeader,
          projectTitle: Template.ProjectTitleEnum.header,
          navbar: UI.NavbarEnum.row,
          socialbar: Template.SocialbarEnum.row,
        });
        break;
      case 'banner3':
        setUiRenderOptions({
          logo: UI.LogoEnum.logoHeader,
          projectTitle: Template.ProjectTitleEnum.home,
          navbar: UI.NavbarEnum.Home1,
          socialbar: Template.SocialbarEnum.row,
        });
        break;

      default:
        setUiRenderOptions({
          logo: UI.LogoEnum.logoHeader,
          projectTitle: Template.ProjectTitleEnum.home,
          navbar: UI.NavbarEnum.Home1,
          socialbar: Template.SocialbarEnum.row,
        });
        break;
    }
  }, [bannerTemplate]);
  return (
    <>
      {bannerTemplate === 'banner1' && isDesktopScreen && (
        <main className="page__main home1">
          <section className="page__section page__section--no-vertical-pd page__section--grow home1__container">
            <div className="page__container home1__container">
              <div className="home1__bg">
                <Template.ProjectCover />
              </div>
              <div className="home1__content">
                <div className="home1__logo-wrapper">
                  <UI.Logo variant={uiRenderOptions.logo} />
                </div>
                <div className="home1__project-title-wrapper">
                  <Template.ProjectTitle variant={uiRenderOptions.projectTitle} />
                </div>
                <div className="home1__navbar-wrapper">
                  <UI.Navbar variant={uiRenderOptions.navbar} />
                </div>
                <div className="home1__socialbar-wrapper">
                  <Template.Socialbar variant={uiRenderOptions.socialbar} />
                </div>
              </div>
            </div>
          </section>
        </main>
      )}

      {bannerTemplate === 'banner2' && isDesktopScreen && (
        <main className="page__main home2">
          <section className="page__section page__section--no-vertical-pd page__section--grow home__container">
            <div className="page__container home2__container">
              <div className="home2__bg">
                <Template.ProjectCover />
              </div>
              <div className="page__container home2__content-wrapper">
                <div className="home2__content">
                  <div className="home2__left-wrapper">
                    <UI.Logo variant={uiRenderOptions.logo} />
                    <Template.ProjectTitle variant={uiRenderOptions.projectTitle} />
                  </div>
                  <div className="home2__navbar-wrapper">
                    <UI.Navbar variant={uiRenderOptions.navbar} />
                  </div>
                  <div className="home3__socialbar-wrapper">
                    <Template.Socialbar variant={uiRenderOptions.socialbar} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      )}

      {bannerTemplate === 'banner3' && isDesktopScreen && (
        <main className="page__main home3">
          <section className="page__section page__section--no-vertical-pd page__section--grow home__container">
            <div className="page__container home3__container">
              <div className="home3__bg">
                <Template.ProjectCover />
              </div>
              <div className="home3__content">
                <div className="home3__left-middle-wrapper">
                  <Template.ProjectTitle variant={uiRenderOptions.projectTitle} />
                  <UI.Navbar variant={uiRenderOptions.navbar} />
                </div>
                <div className="home3__top-right-wrapper">
                  <UI.Logo variant={uiRenderOptions.logo} />
                  <Template.Socialbar variant={uiRenderOptions.socialbar} />
                </div>
              </div>
            </div>
          </section>
        </main>
      )}

      {!isDesktopScreen && (
        <main className="page__main home1">
          <section className="page__section page__section--no-vertical-pd page__section--grow home1__container">
            <div className="page__container home1__container">
              <div className="home1__bg">
                <Template.ProjectCover />
              </div>
              <div className="home1__content">
                <UI.Logo variant={UI.LogoEnum.logoHeader} />
                <Template.ProjectTitle variant={Template.ProjectTitleEnum.header} />
                <UI.HamburgerMenu />
              </div>
            </div>
          </section>
        </main>
      )}

      <Template.OffcanvasMenu />
      <Template.Footer hiddeLogo />
    </>
  );
}
