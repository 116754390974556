import { useRef, useState } from 'react';
import {
  MdOutlineOpenInFull,
  MdFavoriteBorder,
  MdOutlineFavorite,
  MdOutlineComment,
} from 'react-icons/md';

interface ImageThumbProps {
  url: string;
  hiddenHeart?: boolean;
  disableButtonHeart?: boolean;
  hiddenExpand?: boolean;
  hiddenComment?: boolean;
  isSelected?: boolean;
  commentsCount?: number;
  onExpandClick?: () => void;
  onSelectClick?: () => void;
  onCommentClick?: () => void;
  onThumbClick?: () => void;
}

export function ImageThumb({
  url,
  hiddenHeart,
  disableButtonHeart,
  hiddenExpand,
  hiddenComment,
  isSelected,
  commentsCount = 0,
  onExpandClick,
  onSelectClick,
  onCommentClick,
  onThumbClick,
}: ImageThumbProps) {
  const [skeletonActive, setSkeletonActive] = useState<boolean>(true);

  const imageThumbEl = useRef<HTMLDivElement>(null);

  return (
    <div className="images-grid__img-aspect-ratio">
      <div
        ref={imageThumbEl}
        onClick={onThumbClick}
        className={`
          image-thumb 
          ${isSelected ? 'image-thumb--is-select' : ''} 
          ${commentsCount !== 0 && isSelected ? 'image-thumb--has-comments' : ''} 
          ${onThumbClick ? 'image-thumb--cursor-pointer' : ''}
          ${disableButtonHeart ? 'image-thumb--select-hidden' : ''}
          ${skeletonActive ? 'skeleton' : ''}
        `}
      >
        <div className="image-thumb__inner">
          <div className="image-thumb__overlay">
            <div className="image-thumb__btn-group">
              {!hiddenHeart && (
                <button
                  type="button"
                  disabled={disableButtonHeart}
                  className="image-thumb__btn image-thumb__btn--select"
                  onClick={onSelectClick}
                >
                  {isSelected ? (
                    <MdOutlineFavorite className="image-thumb__icon" />
                  ) : (
                    !disableButtonHeart && (
                      <MdFavoriteBorder className="image-thumb__icon" />
                    )
                  )}
                </button>
              )}
              {!hiddenExpand && (
                <button
                  type="button"
                  className="image-thumb__btn"
                  onClick={onExpandClick}
                >
                  <MdOutlineOpenInFull className="image-thumb__icon" />
                </button>
              )}
              {!hiddenComment && isSelected && (
                <button
                  type="button"
                  className="image-thumb__btn image-thumb__btn--comments"
                  onClick={onCommentClick}
                >
                  {commentsCount !== 0 && (
                  <span className="image-thumb__comments-counter">
                    {commentsCount}
                  </span>
                  )}

                  <MdOutlineComment className="image-thumb__icon" />
                </button>
              )}
            </div>
          </div>
          <div className="image-thumb__image">
            <img
              className="image-thumb__img"
              src={url}
              alt=""
              onLoad={() => { setSkeletonActive(false); }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
