import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useGallery } from '@api';
import { FocusPoint } from '@types';
import { calculateZoomOrigin } from '@utils';
import { GalleryContext } from './gallery.context';

interface BannerContextProps {
  focusPoint?: FocusPoint
  bannerTemplate?: string
}

const defaultValue = { focusPoint: undefined, bannerTemplate: '' };

export const BannerContext = createContext<BannerContextProps>(defaultValue);

export const BannerContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const context = useContext(GalleryContext);
  const { getProjectBanner } = useGallery();
  const [state, setState] = useState<BannerContextProps>(defaultValue);

  const loadContext = async () => {
    try {
      const { data: { focusPoint, bannerTemplate } } = await getProjectBanner();
      const calc = focusPoint ? calculateZoomOrigin(focusPoint.x, focusPoint.y) : { focusPoint: { x: 0, y: 0 } };
      setState({ focusPoint: calc.focusPoint, bannerTemplate });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error({ err });
    }
  };

  useEffect(() => {
    if (context?.partnerId) {
      loadContext();
    }
  }, [context?.partnerId]);

  const getContextValue = useCallback(() => state, [state]);

  return (
    <BannerContext.Provider value={getContextValue()}>
      {children}
    </BannerContext.Provider>
  );
};
