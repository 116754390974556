/* eslint-disable no-plusplus */
/* eslint-disable radix */
import * as Yup from 'yup';
import { lang } from '@i18n';

export const checkoutUserSettingsSchema = Yup.object({
  name: Yup.string()
    .required(lang.translate(lang.keys.errors.generic.required_field, {
      path: lang.translate(lang.keys.forms.users.name),
    })),
  email: Yup.string().email().required(),
  cpf: Yup.string()
    .min(11, lang.translate(lang.keys.errors.generic.min_lenth, {
      path: lang.translate(lang.keys.forms.users.cpf),
      length: 11,
    }))
    .max(11, lang.translate(lang.keys.errors.generic.max_lenth, {
      path: lang.translate(lang.keys.forms.users.cpf),
      length: 11,
    }))
    .required(lang.translate(lang.keys.errors.generic.required_field, {
      path: lang.translate(lang.keys.forms.users.cpf),
    }))
    .test('invalidDigits', lang.translate(lang.keys.errors.user_settings.cpf), (value) => {
      const cpf = (value as string).replace(/\D+/g, '');
      let sum = 0;
      for (let i = 0; i < 9; i++) {
        sum += parseInt(cpf.charAt(i)) * (10 - i);
      }
      let digit1 = 11 - (sum % 11);
      if (digit1 === 10 || digit1 === 11) digit1 = 0;
      if (parseInt(cpf.charAt(9)) !== digit1) return false;
      sum = 0;
      for (let i = 0; i < 10; i++) {
        sum += parseInt(cpf.charAt(i)) * (11 - i);
      }
      let digit2 = 11 - (sum % 11);
      if (digit2 === 10 || digit2 === 11) digit2 = 0;
      if (parseInt(cpf.charAt(10)) !== digit2) return false;
      return true;
    }),
  birthday: Yup.string()
    .required(lang.translate(lang.keys.errors.generic.required_field, {
      path: lang.translate(lang.keys.forms.users.birthday),
    }))
    .test('invalidValue', lang.translate(lang.keys.errors.user_settings.birthday), (value) => {
      const date = new Date((value as string).split('/').reverse().join('/').toString());
      if (date.toString() === 'Invalid Date') return false;
      if (date >= new Date()) return false;
      return true;
    }),
  gender: Yup.string()
    .test('invalidValue', lang.translate(lang.keys.errors.user_settings.gender), (value) => value !== '0'),
  phone: Yup.string()
    .min(11, lang.translate(lang.keys.errors.generic.min_lenth, {
      path: lang.translate(lang.keys.forms.users.phone),
      length: 11,
    }))
    .max(11, lang.translate(lang.keys.errors.generic.max_lenth, {
      path: lang.translate(lang.keys.forms.users.phone),
      length: 11,
    }))
    .required(lang.translate(lang.keys.errors.generic.required_field, {
      path: lang.translate(lang.keys.forms.users.phone),
    })),
  zipCode: Yup.string()
    .min(8, lang.translate(lang.keys.errors.generic.min_lenth, {
      path: lang.translate(lang.keys.forms.users.zipCode),
      length: 8,
    }))
    .max(8, lang.translate(lang.keys.errors.generic.max_lenth, {
      path: lang.translate(lang.keys.forms.users.zipCode),
      length: 8,
    }))
    .required(lang.translate(lang.keys.errors.generic.required_field, {
      path: lang.translate(lang.keys.forms.users.zipCode),
    })),
  city: Yup.string()
    .required(lang.translate(lang.keys.errors.generic.required_field, {
      path: lang.translate(lang.keys.forms.users.city),
    })),
  cityId: Yup.number().optional(),
  state: Yup.string()
    .required(lang.translate(lang.keys.errors.generic.required_field, {
      path: lang.translate(lang.keys.forms.users.state),
    }))
    .test('invalidValue', lang.translate(lang.keys.errors.generic.required_field, {
      path: lang.translate(lang.keys.forms.users.state),
    }), (value) => (value !== 'Selecione o estado')),
  stateId: Yup.number().optional(),
  address: Yup.string()
    .required(lang.translate(lang.keys.errors.generic.required_field, {
      path: lang.translate(lang.keys.forms.users.address1),
    })),
  addressId: Yup.number().optional(),
  number: Yup.string()
    .min(1, lang.translate(lang.keys.errors.generic.min_lenth, {
      path: lang.translate(lang.keys.forms.users.number),
      length: 1,
    }))
    .required(lang.translate(lang.keys.errors.generic.required_field, {
      path: lang.translate(lang.keys.forms.users.number),
    })),
  address2: Yup.string().optional().min(0),
  district: Yup.string()
    .required(lang.translate(lang.keys.errors.generic.required_field, {
      path: lang.translate(lang.keys.forms.users.district),
    })),
  districtId: Yup.number().optional(),
});
