import { Template, UI } from '@components';

export function OffcanvasMenu() {
  return (
    <div className="offcanvas">
      <div className="offcanvas__mobile-trigger">
        <UI.HamburgerMenu />
      </div>
      <div className="offcanvas__bg" />
      <div className="offcanvas__container">
        <UI.Navbar variant={UI.NavbarEnum.column} />
        <Template.Socialbar variant={Template.SocialbarEnum.offcanvas} />
      </div>
    </div>
  );
}
