import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
} from 'react';
import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { Store } from 'react-notifications-component';
import { routes } from '@routes';
import { RouterContext } from './router.context';
import { AuthContext } from './auth.context';
import { GalleryContext } from './gallery.context';

interface ApiContextProps {
  api: AxiosInstance
}

export const ApiContext = createContext<ApiContextProps>({
  api: axios.create({ baseURL: process.env.REACT_APP_PUBLIC_API_URL }),
});

export const ApiContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { navigate } = useContext(RouterContext);
  const { auth } = useContext(AuthContext);
  const context = useContext(GalleryContext);

  const getContextValue = useCallback(() => {
    const api = axios.create({
      baseURL: process.env.REACT_APP_PUBLIC_API_URL,
      headers: {
        Authorization: auth.accessToken ? `${auth.tokenType} ${auth.accessToken}` : undefined,
        PartnerId: context?.partnerId,
        GalleryId: context?.galleryId,
        IsGuest: false,
      },
      validateStatus: () => true,
    });

    api.interceptors.response.use((res: AxiosResponse<any, any>) => {
      if (res.status >= 500) {
        navigate(routes.home());

        Store.addNotification({
          message: 'Algo de errado aconteceu, nossa equipe está analisando. Tente novamente em breve.',
          type: 'danger',
          insert: 'top',
          container: 'top-right',
          animationIn: ['animate__animated', 'animate__fadeIn'],
          animationOut: ['animate__animated', 'animate__fadeOut'],
          dismiss: {
            duration: 5000,
            onScreen: true,
          },
        });
      }
      return res;
    });

    return { api };
  }, [context?.partnerId, context?.galleryId, auth.accessToken]);

  return (
    <ApiContext.Provider value={getContextValue()}>
      {children}
    </ApiContext.Provider>
  );
};
