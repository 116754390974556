/* eslint-disable no-nested-ternary */
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Template } from '@components';
import { useFormatLocal } from '@hooks';
import { RouterContext } from '@contexts';
import { routes } from '@routes';
import { Order, Product } from '@types';
import { useOrders } from '@api';

export function OrderDetail() {
  const params = useParams();
  const { getOrderDetails } = useOrders();
  const { navigate, slugs } = useContext(RouterContext);
  const { formatCurrencyPtBR } = useFormatLocal();
  const [orderItems, setOrderItems] = useState<Product.Summary[]>();
  const [orderDetail, setOrderDetail] = useState<Order.Details>();
  const [orderDetailIsLoading, setOrderDetailISLoading] = useState<boolean>(true);

  // const {
  //   context: { partnerSlug },
  // } = useSelector((store: IStore) => store);

  const orderItemsPlaceholder: Product.Summary[] = [
    {
      productName: 'placeholder',
      quantity: 99,
      productImageUrl: 'placeholder',
      unitValue: 99,
    },
    {
      productName: 'placeholder',
      quantity: 99,
      productImageUrl: 'placeholder',
      unitValue: 99,
    },
  ];

  const orderDetailPlaceholder: Order.Details = {
    id: '3',
    goImageId: 3,
    createdAt: new Date(),
    paymentConfirmationDate: new Date(),
    productsSummary: orderItemsPlaceholder,
    paymentStatus: 'Pending',
    totalValue: 99,
    code: '59611898790',
    url: 'https://api.goimage.com.br/api/mercadopago/pix/59611898790/brcodecontent',
  };

  async function getOrder() {
    setOrderDetailISLoading(true);
    setOrderDetail(orderDetailPlaceholder);
    setOrderItems(orderItemsPlaceholder);

    const { data } = await getOrderDetails({
      partnerSlug: slugs.partner as string,
      orderId: params.orderId || '',
    });

    setOrderDetail(data);

    setOrderItems(
      data.productsSummary.map((product) => ({
        ...product,
      })),
    );

    setOrderDetailISLoading(false);
  }

  useEffect(() => {
    getOrder();
  }, [params.orderId]);

  function returnToOrdersPage() {
    navigate(routes.orders());
  }

  return (
    <>
      <Template.Header active="Orders" />
      <main className="page__main">
        <section className="page__section order-details">
          <div className="page__container">
            <div className="order-details__heading-wrapper">
              <Template.Heading
                titleId="Orders"
                onReturnClick={returnToOrdersPage}
              />
            </div>
            {orderDetail && (
            <div className="order-details__content">
              <div className="order-details__order-resume-wrapper">
                {orderItems && (
                <Template.OrderResume
                  orderResumeSummary={orderItems}
                  orderTotalValue={orderDetail.totalValue}
                  isLoading={orderDetailIsLoading}
                />
                )}
              </div>
              <div className="order-details__details-wrapper">
                <div className="page__bg-area order-details__colum-group">
                  <div className="page__heading">
                    <h5 className={`page__title page__title--color-neutral page__title--bold page__title--small
                    ${orderDetailIsLoading ? 'skeleton' : ''}`}
                    >
                      Pagamento
                    </h5>
                  </div>
                  <div className="order-details__infos-grid">
                    <div className="order-details__info-name">
                      <p className={`order-details__text ${orderDetailIsLoading ? 'skeleton' : ''}`}>Valor do pedido</p>
                    </div>
                    <div className="order-details__info-value">
                      <p className={`order-details__text order-details__text--emphasys ${orderDetailIsLoading ? 'skeleton' : ''}`}>
                        {formatCurrencyPtBR(orderDetail.totalValue)}
                      </p>
                    </div>
                    <div className="order-details__info-name">
                      <p className={`order-details__text ${orderDetailIsLoading ? 'skeleton' : ''}`}>
                        Forma de pagamento
                      </p>
                    </div>
                    <div className="order-details__info-value">
                      <p className={`order-details__text order-details__text--emphasys ${orderDetailIsLoading ? 'skeleton' : ''}`}>
                        {orderDetail.paymentType === 'pix'
                          ? 'Pix'
                          : orderDetail.paymentType === 'CreditCard'
                            ? 'Cartão de Crédito'
                            : 'Boleto'}
                      </p>
                      {orderDetail.paymentType === 'CreditCard'
                          && orderDetail.installmentsQuantity ? (
                            <>
                              <p className={`order-details__text ${orderDetailIsLoading ? 'skeleton' : ''}`}>
                                {orderDetail.installmentsQuantity}
                                {'x de '}
                                {formatCurrencyPtBR(
                                  orderDetail.totalValue
                                    / orderDetail.installmentsQuantity,
                                )}
                              </p>
                              <p className={`order-details__text order-details__text--small ${orderDetailIsLoading ? 'skeleton' : ''}`}>
                                (Sem juros)
                              </p>
                            </>
                        ) : null}
                    </div>
                    <div className="order-details__info-name">
                      <p className={`order-details__text ${orderDetailIsLoading ? 'skeleton' : ''}`}>
                        Valor Total Pago
                      </p>
                    </div>
                    <div className="order-details__info-value">
                      <p className={`order-details__text order-details__text--emphasys ${orderDetailIsLoading ? 'skeleton' : ''}`}>
                        {formatCurrencyPtBR(orderDetail.totalValue)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="page__bg-area order-details__colum-group">
                  <div className="order-details__status-container">
                    <h5 className={`page__title page__title--small page__title--bold page__title--color-neutral 
                      ${orderDetailIsLoading ? 'skeleton' : ''}`}
                    >
                      Status de Pagamento
                    </h5>
                    <div className="order-details__status">
                      <Template.PaymentStatus
                        isLoading={orderDetailIsLoading}
                        status={orderDetail.paymentStatus}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            )}
          </div>
        </section>
      </main>
      <Template.Footer />
    </>
  );
}
