import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import axios from 'axios';
import { ColorScheme } from '@utils';
import { routes } from '@routes';
import { Gallery } from '@types';
import { AuthContext, AuthContextData } from './auth.context';
import { RouterContext } from './router.context';

type SlugsType = { project: string; partner: string; }

const getContext = (slugs: SlugsType, auth: AuthContextData) => axios.get<Gallery.Context>('/galleries/context', {
  params: {
    partnerSlug: slugs.partner,
    gallerySlug: slugs.project,
    userId: auth.authenticated ? auth.userId : undefined,
  },
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
  headers: {
    Authorization: auth.accessToken ? `${auth.tokenType} ${auth.accessToken}` : undefined,
    IsGuest: false,
  },
  validateStatus: () => true,
});

export const GalleryContext = createContext<Gallery.Context | undefined>(undefined);

export const GalleryContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { slugs, navigate } = useContext(RouterContext);
  const { auth } = useContext(AuthContext);
  const [state, setState] = useState<Gallery.Context | undefined>(undefined);

  const loadContext = async () => {
    try {
      const { data } = await getContext(slugs, auth);
      if (data?.galleryBackgroundColor && data?.galleryTextColor) {
        ColorScheme.setSelectedColorScheme(data?.galleryTextColor, data?.galleryBackgroundColor);
      }

      setState(data);
    } catch (err: any) {
      // eslint-disable-next-line no-console
      console.error({ err });
      if (err.response.data.message === 'Gallery not found.') navigate(routes.notFound());
    }
  };

  useEffect(() => {
    loadContext();
  }, [auth.authenticated]);

  const getContextValue = useCallback(() => state, [state]);

  return (
    <GalleryContext.Provider value={getContextValue()}>
      {getContextValue()?.partnerId ? children : null}
    </GalleryContext.Provider>
  );
};
