interface FooterProps {
  hiddeLogo?: boolean
}

export function Footer({ hiddeLogo }: FooterProps) {
  return (
    <footer className="footer">
      <div className="page__container footer__container">
        {!hiddeLogo && (
        <div className="footer__content">
          <div className="footer__logo-group">
            <a
              href="https://lp.fotogo.com.br/home/"
              target="_blank"
              className="footer__logo-link"
            >
              <span className="footer__logo-call">Densevolvido por</span>
            </a>
            <a
              href="https://lp.fotogo.com.br/home/"
              target="_blank"
              className="footer__logo-link"
            >
              <img
                src="https://s3.amazonaws.com/foto-go-premium.partners-files/static/logos/logo.png"
                alt=""
                className="footer__logo"
              />
            </a>
          </div>
        </div>
        )}
        <div className="footer__copyright">
          <div className="footer__left">
            <div className="footer__link">
              <a href="https://marketing.goimage.com.br/go-image-termos-de-uso" target="_blank">
                <span className="footer__text">Termos de Uso</span>
              </a>
            </div>
            <div className="footer__link">
              <a
                href="https://marketing.goimage.com.br/goimage-politica-de-privacidade"
                target="_blank"
              >
                <span className="footer__text">Privacidade</span>
              </a>
            </div>
          </div>
          <div className="footer__right">
            <span className="footer__text">© 2023 FotoGo. Todos os direitos reservados</span>
          </div>
        </div>
      </div>
    </footer>
  );
}
