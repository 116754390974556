/* eslint-disable react/no-array-index-key */
import React, {
  useContext,
  useEffect,
  useState,
  Fragment,
} from 'react';
import { MdOutlineFavoriteBorder, MdOutlineAttachMoney, MdOutlineDone } from 'react-icons/md';
import { useInView } from 'react-intersection-observer';
import { Template, UI } from '@components';
import { useReplaceThumbUrls, ThumbSizes } from '@hooks';
import { routes } from '@routes';
import { RouterContext } from '@contexts';
import { useGallerySelection, useScene } from '@api';
import { Scene } from '@types';

export function Selection() {
  const { navigate } = useContext(RouterContext);
  const { replaceThumbUrls } = useReplaceThumbUrls();
  const {
    addSelectedFile,
    addSelectedFileComment,
    getSelectionFileComments,
    getSelectionInfo,
    getSelectionList,
    getSelectionSceneFiles,
    removeSelectedFile,
    selectionFileCommentsRead,
  } = useGallerySelection();
  const { getScenesList } = useScene();

  // selection tabs
  const [selectionTabs, setSelectionTabs] = useState<Array<UI.ITab>>();
  const [currentSelectionId, setCurrentSelectionId] = useState('');
  const [selectionTabsIsLoading, setSelectionTabsIsLoading] = useState<boolean>(true);
  // selection resume
  const [selectionResume, setSelectionResume] = useState<Template.ISelectionResume>();
  const [selectionResumeIsLoading, setSelectionResumeIsLoading] = useState<boolean>(true);
  // selection scene
  const [scene, setScene] = useState<Scene.WithImages>();
  // selection scene tabs
  const [sceneTabs, setSceneTabs] = useState<Array<UI.ITab>>();
  const [currentSceneId, setCurrentSceneId] = useState('');

  const [currentFileCommentId, setCurrentFileCommentId] = useState('');
  const [fileCommentsWithImage, setFileCommentsWithImage] = useState<Template.IComments>();
  const [fileCommentsWithImageIsLoading, setFileCommentsWithImageIsLoading] = useState<boolean>(true);

  const [showExtraPhotosModal, setShowExtraPhotosModal] = useState<boolean>(false);
  const [showSelectedMaxCountModal, setShowSelectedMaxCountModal] = useState<boolean>(false);

  const [justSelectedFiles, setJustSelectedFiles] = useState<boolean>(false);
  const [showImageViewer, setShowImageViewer] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [initialSlideIndex, setInitialSlideIndex] = useState<number>(0);

  const commentsEl = React.useRef<HTMLDivElement>(null);
  const bannerResumeEl = React.useRef<HTMLElement>(null);
  const imageGridEl = React.useRef<HTMLDivElement>(null);
  const contentEl = React.useRef<HTMLElement>(null);

  const selectionTabsPlaceholder = [
    {
      id: 123,
      name: 'placeholder',
      handleClick: () => { },
    },
  ];

  const scenePlaceholder: Scene.WithImages = {
    id: 12315,
    name: 'placeholder',
    filesCount: 99,
    isActive: true,
    createdAt: new Date(),
    files: [
      {
        id: '1',
        name: 'placeholder',
        url: 'placeholder',
        createdAt: new Date(),
      },
      {
        id: '12',
        name: 'placeholder',
        url: 'placeholder',
        createdAt: new Date(),
      },
      {
        id: '123',
        name: 'placeholder',
        url: 'placeholder',
        createdAt: new Date(),
      },
      {
        id: '1234',
        name: 'placeholder',
        url: 'placeholder',
        createdAt: new Date(),
      },
    ],
  };

  const extraPhotosModalEl: React.ReactNode = (
    <>
      <p>Você concluiu o mínimo de fotos da seleção!</p>
      <p>Mas você pode continuar selecionando fotos extras antes de finalizar a seleção.</p>
    </>
  );

  const selectedMaxCountModalEl: React.ReactNode = (
    <>
      <p>Você já selecionou a quantidade de fotos do pacote.</p>
      <p>Remova fotos selecionadas para adicionar outras, caso desejar.</p>
    </>
  );

  function fadeInEl(elements: Array<React.RefObject<HTMLElement> | React.RefObject<HTMLDivElement>>, duration: number = 500) {
    elements.forEach((el) => {
      if (el) {
        el.current?.setAttribute('style', `animation-duration: ${duration}ms`);
        el.current?.classList.add(...['fadeIn']);
        setTimeout(() => {
          el.current?.classList.remove(...['fadeIn']);
        }, duration);
      }
    });
  }

  function onCloseComment() {
    setCurrentFileCommentId('');
    commentsEl.current?.classList.add('comments-chat--hidden');
  }

  const hideOnClick: Template.IHideableChatComments = {
    commentsEl,
    onCloseComment,
  };

  const { ref, inView } = useInView({
    threshold: 0.2,
  });

  async function getSelectionTabs() {
    setSelectionTabsIsLoading(true);
    setSelectionTabs(selectionTabsPlaceholder);

    const { data } = await getSelectionList();
    setSelectionTabs(
      data.reverse().map((selection) => ({
        id: selection.id,
        name: selection.name,
        handleClick: () => {
          setCurrentSelectionId(selection.id);
        },
      })),
    );
    setCurrentSelectionId(data[0].id);

    setSelectionTabsIsLoading(false);
  }

  async function getSceneTabs() {
    let { data } = (await getScenesList());
    data = data.filter((scene) => (scene.name === 'suggested' || scene.filesCount > 0));
    setSceneTabs(
      data.map((scene) => ({
        id: scene.id,
        name: scene.name,
        handleClick: () => {
          setCurrentSceneId(`${scene.id}`);
        },
      })),
    );

    setCurrentSceneId(`${data[0].id}`);
  }

  async function getSelectionFiles() {
    setScene(scenePlaceholder);

    const { data } = await getSelectionSceneFiles({
      sceneId: currentSceneId,
      selectionId: currentSelectionId,
      sceneQuery: {
        sceneFilter: `${currentSceneId}` === '0' ? 'suggested' : '',
        pagination: {
          skip: 0,
          take: 0,
        },
      },
    });

    setScene(undefined);
    setScene(data);

    setJustSelectedFiles(false);
  }

  async function getAllSelectionSelectedFiles() {
    setScene(undefined);

    const { data } = await getSelectionSceneFiles({
      sceneId: currentSceneId,
      selectionId: currentSelectionId,
      sceneQuery: {
        sceneFilter: 'selecteds',
        pagination: {
          skip: 0,
          take: 0,
        },
      },
    });
    setScene(data);

    setJustSelectedFiles(true);
    setCurrentSceneId('-1');
  }

  function handleSelectionResumeFilesCount(action: 'addCount' | 'removeCount'): any {
    let storeCurrentState;
    setSelectionResume((currentState) => {
      // eslint-disable-next-line no-param-reassign
      storeCurrentState = JSON.parse(JSON.stringify(currentState));
      switch (action) {
        case 'removeCount':
          // eslint-disable-next-line no-unused-expressions, no-plusplus, no-param-reassign
          currentState?.selectedFilesCount !== undefined ? currentState.selectedFilesCount-- : 0;
          break;

        case 'addCount':
          // eslint-disable-next-line no-unused-expressions, no-plusplus, no-param-reassign
          currentState?.selectedFilesCount !== undefined ? currentState.selectedFilesCount++ : 0;
          break;

        default:
          break;
      }
      return currentState;
    });

    return storeCurrentState;
  }

  async function getSelectionResume(): Promise<void> {
    setSelectionResumeIsLoading(true);

    const { data } = await getSelectionInfo({
      selectionId: currentSelectionId,
    });

    // eslint-disable-next-line no-nested-ternary
    const selectionStatus = (new Date(data.expirationDate as Date) < new Date()) ? data.status !== 'Finished' ? 'Expired' : data.status : data.status;

    const minFiles = data.minFiles ? data.minFiles : 0;
    const selectedFilesCount = data.selectedFilesCount ? data.selectedFilesCount : 0;

    setSelectionResume({
      title: data.name,
      minFiles,
      selectedFilesCount,
      expirationDate: data.expirationDate,
      price: data.price,
      totalValue:
      selectedFilesCount > minFiles
        ? (selectedFilesCount - minFiles) * data.price
        : 0,
      selectionStatus,
    });
    setSelectionResumeIsLoading(false);
  }

  async function onSelectedFile(index: number) {
    let storeCurrentState;
    if (scene) {
      if (scene.files[index].isSelected) {
        scene.files[index].isSelected = !scene.files[index].isSelected;
        setScene({ ...scene });

        storeCurrentState = handleSelectionResumeFilesCount('removeCount');

        try {
          await removeSelectedFile({
            selectionId: currentSelectionId,
            fileId: scene.files[index].id,
          });
        } catch (error) {
          console.log('error', error);
          scene.files[index].isSelected = !scene.files[index].isSelected;
          setScene({ ...scene });
          setSelectionResume(undefined);
          setSelectionResume(storeCurrentState);
        }
      } else {
        scene.files[index].isSelected = !scene.files[index].isSelected;
        setScene({ ...scene });

        storeCurrentState = handleSelectionResumeFilesCount('addCount');

        try {
          await addSelectedFile({
            selectionId: currentSelectionId,
            fileId: scene.files[index].id,
          });
        } catch (error) {
          console.log('error', error);
          scene.files[index].isSelected = !scene.files[index].isSelected;
          setScene({ ...scene });
          setSelectionResume(undefined);
          setSelectionResume(storeCurrentState);
        }
      }
    }
  }

  useEffect(() => {
    getSelectionTabs();
  }, []);

  useEffect(() => {
    if (currentSelectionId && !sceneTabs) getSceneTabs();
    if (currentSelectionId) {
      getSelectionResume();
    }
    setCurrentSceneId(sceneTabs ? (sceneTabs[0].id as string) : '');
    fadeInEl([bannerResumeEl, imageGridEl, contentEl]);
  }, [currentSelectionId]);

  useEffect(() => {
    if (currentSelectionId && currentSceneId !== '' && currentSceneId !== '-1') {
      getSelectionFiles();
      fadeInEl([imageGridEl]);
    }
  }, [currentSelectionId, currentSceneId]);

  useEffect(() => {
    if (selectionResume?.minFiles === 0 && selectionResume.price) return;

    if (selectionResume?.price
      && selectionResume.minFiles === selectionResume.selectedFilesCount
      && selectionResume.selectionStatus !== 'Finished'
    ) {
      setShowExtraPhotosModal(true);
    }
  }, [selectionResume?.selectedFilesCount]);

  async function onOpenComments(fileId: string, url: string) {
    setFileCommentsWithImageIsLoading(true);

    const { data } = await getSelectionFileComments({
      selectionId: currentSelectionId,
      fileId,
    });

    setFileCommentsWithImage({ productId: fileId, url, fileComments: data });

    await selectionFileCommentsRead({
      selectionId: currentSelectionId,
      fileId,
    });

    setFileCommentsWithImageIsLoading(false);
  }

  async function addFileComment(productId: string, text: string): Promise<void> {
    let storeCurrentState;
    setFileCommentsWithImage((currentState) => {
      storeCurrentState = JSON.parse(JSON.stringify(currentState));
      currentState?.fileComments.push({
        text,
        date: new Date(),
        isOwn: true,
      });
      return currentState;
    });

    try {
      if (text) {
        await addSelectedFileComment({
          selectionId: currentSelectionId,
          fileId: productId,
          text,
        });
      }
    } catch (error) {
      setFileCommentsWithImage(undefined);
      setFileCommentsWithImage(storeCurrentState);
    }
  }

  function returnToHomePage() {
    navigate(routes.home());
  }

  function closeImageViewer() {
    setShowImageViewer(false);
  }

  return (
    <>
      <Template.Header active="Photo" />
      {selectionTabs && sceneTabs && (
        <>
          <main className="page__main">
            <section className="page__section">
              <div className="page__container">
                <div className="page__component-wrapper">
                  <Template.Heading
                    currentId={currentSelectionId}
                    tabs={selectionTabs}
                    onReturnClick={returnToHomePage}
                    isLoading={selectionTabsIsLoading}
                  />
                </div>
              </div>
            </section>
            <section
              ref={bannerResumeEl}
              className="page__section page__section--bottom-padding-lg"
            >
              <div className="page__container">
                <div
                  ref={ref}
                  className="page__component-wrapper selection__banner-resume-wrapper"
                >
                  <div className="selection__banner-resume">
                    <div className="selection__banner">
                      <div className="selection__banner-img">
                        <Template.ProjectCover />
                      </div>
                    </div>
                    <div className="selection__resume">
                      {selectionResume && (
                        <Template.SelectionResume
                          currentId={currentSelectionId}
                          selectionResumeData={selectionResume}
                          isLoading={selectionResumeIsLoading}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section
              ref={contentEl}
              className="page__section page__section--grow"
            >
              <div className="page__container">
                <div className="page__component-wrapper page__component-wrapper--row selection__tabs-actions-wrapper">
                  <div className="selection__tabs-wrapper">
                    <UI.Tabs
                      tabs={sceneTabs}
                      currentId={currentSceneId}
                      variant={UI.TabsEnum.flexWrap}
                    />
                  </div>
                  <div className="selection__actions">
                    <UI.Button
                      type="button"
                      variant={
                        justSelectedFiles
                          ? UI.ButtonEnum.primaryOutlineActive
                          : UI.ButtonEnum.primaryOutline
                      }
                      loading={false}
                      onClick={getAllSelectionSelectedFiles}
                      // eslint-disable-next-line no-nested-ternary, no-unneeded-ternary
                      disabled={selectionResume ? (selectionResume.selectedFilesCount > 0 ? false : true) : true}
                      text={(
                        <>
                          <MdOutlineFavoriteBorder />
                          {' Ver selecionadas'}
                        </>
                      )}
                    />
                  </div>
                </div>
                <div
                  ref={imageGridEl}
                  className="selection__image-grid-wrapper selection__image-grid-wrapper--reduced-container"
                >
                  <div className="images-grid">
                    {scene
                      && scene.files.map(({
                        id, url, isSelected, commentsCount,
                      }: any, index: number) => (
                        <Fragment key={index}>
                          <Template.ImageThumb
                            key={index}
                            url={replaceThumbUrls(url, ThumbSizes.small)}
                            disableButtonHeart={
                              !!(
                                selectionResume
                                && selectionResume.selectionStatus === 'Finished'
                              ) || !!(
                                selectionResume
                                && selectionResume.selectionStatus === 'Expired'
                              )
                            }
                            hiddenComment={
                              !!(
                                selectionResume
                                && selectionResume.selectionStatus === 'Finished'
                              ) || !!(
                                selectionResume
                                && selectionResume.selectionStatus === 'Expired'
                              )
                            }
                            isSelected={isSelected}
                            commentsCount={commentsCount}
                            onExpandClick={() => {
                              setCurrentIndex(index);
                              setInitialSlideIndex(index);
                              setShowImageViewer(true);
                            }}
                            onSelectClick={() => {
                              if (selectionResume?.selectionStatus === 'Expired') {
                                return;
                              }

                              if (scene.files[index].isSelected
                                && scene.files[index].id === currentFileCommentId) {
                                commentsEl.current?.classList.add(
                                  'comments-chat--hidden',
                                );
                              }

                              onSelectedFile(index);
                            }}
                            onCommentClick={() => {
                              setCurrentFileCommentId(id);
                              onOpenComments(id, replaceThumbUrls(url, ThumbSizes.big));
                              commentsEl.current?.classList.remove(
                                'comments-chat--hidden',
                              );
                            }}
                          />
                        </Fragment>
                      ))}
                  </div>
                </div>
              </div>
            </section>
          </main>
          <Template.OffcanvasMenu />
          {selectionResume && (
          <Template.FixedSelectionSummary
            reveal={!inView}
            maxValue={selectionResume?.minFiles as number}
            currentValue={selectionResume?.selectedFilesCount as number}
            currentId={currentSelectionId}
            selectionResumeData={selectionResume}
            isLoading={selectionResumeIsLoading}
          />
          )}
          <Template.CommentsChat
            variant={Template.CommentsChatEnum.imageSelection}
            comments={fileCommentsWithImage}
            hideOnClick={hideOnClick}
            addComment={addFileComment}
            isLoading={fileCommentsWithImageIsLoading}
          />
          {showImageViewer && (
            <UI.ImageViewer
              disableButtonHeart={
                !!(
                  selectionResume
                  && selectionResume.selectionStatus === 'Finished'
                )
              }
              hiddenComment={
                !!(
                  selectionResume
                  && selectionResume.selectionStatus === 'Finished'
                )
              }
              initialSlide={initialSlideIndex}
              currentIndex={currentIndex}
              sliderImages={
                scene
                  ? scene.files.map((file: any) => ({
                    ...file,
                    url: file.url,
                  }))
                  : []
              }
              isSelected={scene ? scene.files[currentIndex].isSelected : false}
              onCloseClick={closeImageViewer}
              onSelectClick={() => {
                if (
                  scene?.files[currentIndex].isSelected
                  && scene?.files[currentIndex].id === currentFileCommentId
                ) {
                  commentsEl.current?.classList.add('comments-chat--hidden');
                }

                onSelectedFile(currentIndex);
              }}
              onCommentClick={() => {
                setCurrentFileCommentId(
                  scene?.files[currentIndex].id as string,
                );
                onOpenComments(
                  scene?.files[currentIndex].id as string,
                  replaceThumbUrls(scene?.files[currentIndex].url as string, ThumbSizes.big),
                );
                commentsEl.current?.classList.remove('comments-chat--hidden');
              }}
              onNextSlide={() => {
                setCurrentIndex(currentIndex + 1);
              }}
              onPrevSlide={() => {
                setCurrentIndex(currentIndex - 1);
              }}
            />
          )}
          <Template.Footer />

          <UI.Modal
            isOpen={showExtraPhotosModal}
            maxWidth={560}
            header={{
              title: 'Fotos extras',
              Icon: MdOutlineAttachMoney,
            }}
            contentEl={extraPhotosModalEl}
            buttonGroupDirection="column"
            closeable={false}
            onClose={() => false}
            positiveButton={{
              label: 'Ok, entendi!',
              onClick() {
                setShowExtraPhotosModal(false);
              },
              variant: UI.ButtonEnum.primary,
            }}
          />

          <UI.Modal
            isOpen={showSelectedMaxCountModal}
            maxWidth={560}
            header={{
              title: 'Seleção concluída',
              Icon: MdOutlineDone,
            }}
            contentEl={selectedMaxCountModalEl}
            buttonGroupDirection="column"
            closeable={false}
            onClose={() => false}
            positiveButton={{
              label: 'Ok, entendi!',
              onClick() {
                setShowSelectedMaxCountModal(false);
              },
              variant: UI.ButtonEnum.primary,
            }}
          />
        </>
      )}
    </>
  );
}
