/* eslint-disable arrow-body-style */
import { useCallback } from 'react';
import { Album, Comment } from '@types';
import { useApi } from './api';
import {
  GalleryAlbumRequest,
  GalleryAlbumRequestWithUser,
  AlbumPageCommentsReadRequest,
  GetAlbumsListRequest,
} from './types/galleries_albums';

export const useGalleriesAlbums = () => {
  const api = useApi();

  const getAlbumsList = useCallback((params: GetAlbumsListRequest) => {
    return api.get<Album.Item[]>('/galleriesAlbums/summary', { params });
  }, [api]);

  const getAlbumDetails = useCallback(({ albumId }: GalleryAlbumRequestWithUser) => {
    return api.get<Album.Detailed>(`/galleriesAlbums/albumId/${albumId}`);
  }, [api]);

  const approveAlbum = useCallback(({ albumId }: GalleryAlbumRequestWithUser) => api.patch(`/galleriesAlbums/albumId/${albumId}/finish`), [api]);

  const getAlbumPageComments = useCallback(({ albumId, page }: GalleryAlbumRequest) => {
    return api.get<Comment[]>(`/galleriesAlbums/albumId/${albumId}/page/${page}`);
  }, [api]);

  const addAlbumPageComment = useCallback(({ albumId, page, text }: GalleryAlbumRequest) => {
    return api.post(`/galleriesAlbums/albumId/${albumId}/comment`, { page, text });
  }, [api]);

  const albumPageCommentsRead = useCallback(({ albumId, page }: AlbumPageCommentsReadRequest) => {
    return api.patch(`/galleriesAlbums/albumId/${albumId}/comments/page/${page}/read`);
  }, [api]);

  return {
    getAlbumsList,
    getAlbumDetails,
    approveAlbum,
    getAlbumPageComments,
    addAlbumPageComment,
    albumPageCommentsRead,
  };
};
