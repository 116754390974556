export namespace ShoppingCart {
  export interface Item {
    id: string
    productId: string
    productName: string
    selectionId?: string
    files: Item.File[]
    uploadData: Item.UploadData
    clientData: Item.ClientData
    productDescription: string
    productImageUrl?: string
    optionId: number
    pages: number
    quantity: number
    unitPrice: number
    available: boolean
  }

  export namespace Item {
    export interface File {
      copies: number
      name: string
      type: File.Type
      url: string
      edited: boolean
      editorData: any
      editorConfigs: any
    }

    export namespace File {
      export enum Type {
        Cover = 'Cover',
        Image = 'Image',
        LaserEngraving = 'LaserEngraving',
        Attachment = 'Attachment',
        DigitalImage = 'DigitalImage',
      }
    }

    export interface UploadData {
      startDate: Date
      endDate: Date
      version: number
    }

    export interface ClientData {
      userAgent: string
    }
  }
}
