import { useCallback } from 'react';
import { useApi } from './api';
import { GetUserInfosRequest, GetUserInfosResponse, UpdateUserInfosRequest } from './types/users';

export const useUsers = () => {
  const api = useApi();

  const getUserInfos = useCallback((params: GetUserInfosRequest) => api.get<GetUserInfosResponse>('/users/userData', { params }), [api]);

  const updateUserInfos = useCallback((request: UpdateUserInfosRequest) => api.patch('/users', request), [api]);

  return {
    getUserInfos,
    updateUserInfos,
  };
};
