import {
  FormEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Form, Notification, Template, UI,
} from '@components';
import { lang } from '@i18n';
import { AuthContext, RouterContext } from '@contexts';
import { routes } from '@routes';
import { useAuth, useUsers } from '@api';

export function UserSettingsRecovery() {
  const { navigate } = useContext(RouterContext);
  const { auth } = useContext(AuthContext);
  const { getUserInfos } = useUsers();
  const { recoveryPassword } = useAuth();
  const [form, setForm] = useState<{ email: string }>({ email: '' });
  const [loading, setLoading] = useState(false);
  const [hasMessage, setHasMessage] = useState<{
    type: 'error' | 'success' | 'warning';
    message: string;
  } | null>(null);

  // const {
  //   auth: { user },
  // } = useSelector((store: IStore) => store);

  async function getUserDetails() {
    const { data: userDetails } = await getUserInfos({ userId: auth.userId });
    setForm({ email: userDetails.user.email });
  }

  useEffect(() => {
    getUserDetails();
  }, []);

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    try {
      setLoading(true);

      await recoveryPassword({
        email: form.email,
      });
      setHasMessage({
        type: 'success',
        message: lang.translate(lang.keys.pages.recovery.success_submit),
      });
    } catch (error: any) {
      if (error?.response?.status === 400) {
        setHasMessage({
          type: 'error',
          message: lang.translate(lang.keys.errors.api.generic),
        });
      } else {
        setHasMessage({
          type: 'warning',
          message: lang.translate(lang.keys.errors.api.generic),
        });
      }
    } finally {
      setLoading(false);
    }
  }

  const onClickModal = () => {
    setHasMessage(null);
  };

  return (
    <>
      <Template.Header />
      <main className="page__main reset">
        <section className="page__section">
          <div className="page__container">
            <div className="user-settings__heading-wrapper">
              <Template.Heading titleId="Change_password" onReturnClick={() => navigate(routes.userSettings())} />
            </div>
            <form
              className="user-settings__forms-area"
              onSubmit={handleSubmit}
              noValidate
            >
              <div className="page__container page__container--center">
                <div className="page__bg-area reset__area">
                  <div className="page__heading">
                    <h5 className="page__title">
                      {lang.translate(lang.keys.forms.users.changePassword)}
                    </h5>
                    <p className="page__desc">
                      Um link para redefinir sua senha será enviado por email.
                      Lembre-se de verificar a caixa de spam.
                    </p>
                  </div>
                  <div className="form">
                    <div className="form__grid">
                      <div className="form__group">
                        <Form.Label
                          name="email"
                          text={lang.translate(lang.keys.forms.users.email)}
                        />
                        <Form.Input
                          onChange={() => { }}
                          id="email"
                          name="email"
                          type="text"
                          value={form.email}
                          disabled
                        />
                      </div>
                      <div className="form__group">
                        <div className="reset__btn-group">
                          <UI.Button
                            type="submit"
                            loading={loading}
                            variant={UI.ButtonEnum.primary}
                            fullwidth="w-full"
                            text="Redefinir senha"
                          />
                          {hasMessage && (
                            <Notification.Message
                              type={hasMessage.type}
                              onClick={onClickModal}
                              message={hasMessage.message}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>
      </main>
      <Template.Footer />
    </>
  );
}
