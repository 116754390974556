/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import {
  MdOutlineCheckCircleOutline,
  MdOutlineQueryBuilder,
  MdOutlineBlock,
} from 'react-icons/md';
import { UI } from '@components';
import { Selection } from '@types';

export interface ISelectionProgressCardSummary {
  id: string;
  title: string;
  leftSubtitle: string;
  rightSubtitle?: string;
  buttonLabel: string;
  handleButtonClick: () => void;
  currentValue: number;
  totalValue: number;
  status: Selection.Status;
}

interface SelectionProgressCardProps {
  selectionProgressCardSummary: ISelectionProgressCardSummary;
  isLoading: boolean;
}

const statusUI = [
  {
    name: 'Pending',
    label: 'Não iniciado',
    Icon: MdOutlineQueryBuilder,
  },
  {
    name: 'InProgress',
    label: 'Em andamento',
    Icon: MdOutlineQueryBuilder,
  },
  {
    name: 'Finished',
    label: 'Concluído',
    Icon: MdOutlineCheckCircleOutline,
  },
  {
    name: 'Expired',
    label: 'Expirado',
    Icon: MdOutlineBlock,
  },
];

function calcProgressPercentage(
  totalValue: number,
  currentValue: number,
): number {
  if (totalValue === 0) {
    return 0;
  }

  const progressPercentage = (currentValue * 100) / totalValue;

  if (progressPercentage > 100) {
    return 100;
  }

  if (progressPercentage < 0) {
    return 0;
  }

  return progressPercentage;
}

export function SelectionProgressCard({
  selectionProgressCardSummary,
  isLoading,
}: SelectionProgressCardProps) {
  const {
    id,
    title,
    leftSubtitle,
    rightSubtitle,
    buttonLabel,
    handleButtonClick,
    currentValue,
    totalValue,
    status,
  } = selectionProgressCardSummary;
  const progressPercentage = calcProgressPercentage(totalValue, currentValue);
  return (
    <div
      className={`selection-progress selection-progress--${selectionProgressCardSummary.status.toLowerCase()}`}
      onClick={handleButtonClick}
    >
      <div className="selection-progress__top">
        {statusUI.map(({ name, label, Icon }) => {
          if (name === status) {
            return (
              <div key={id} className={`selection-progress__satus-label-icon ${isLoading ? 'skeleton skeleton--neutral-90' : ''}`}>
                <Icon className="selection-progress__satus-icon" />
                <span className="selection-progress__satus-label">{label}</span>
              </div>
            );
          }
        })}
        <div className={`selection-progress__title-btn ${isLoading ? 'skeleton skeleton--neutral-90' : ''}`}>
          <span className="selection-progress__title">{title}</span>
          <UI.Button
            type="button"
            variant={UI.ButtonEnum.primarySmall}
            loading={false}
            classes="selection-progress__open-btn"
            onClick={handleButtonClick}
            text={buttonLabel}
          />
        </div>
      </div>
      <div className="selection-progress__bottom">
        <div className="selection-progress__flex-column">
          <div className="selection-progress__status">
            <span className={`selection-progress__countdown ${isLoading ? 'skeleton skeleton--neutral-90' : ''}`}>
              {leftSubtitle}
            </span>
            <span className={`selection-progress__deadline ${isLoading ? 'skeleton skeleton--neutral-90' : ''}`}>
              {rightSubtitle}
            </span>
          </div>
          <div className={`selection-progress__progress-bar ${isLoading ? 'skeleton skeleton--neutral-90' : ''}`}>
            <span className="selection-progress__bar selection-progress__bar--bg" />
            <span
              className="selection-progress__bar"
              style={{ width: `${progressPercentage}%` }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
