/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import {
  MdArrowBack,
  MdOutlineFileDownload,
  MdOutlineLock,
  MdOutlinePhotoLibrary,
  MdOutlineShoppingBag,
  MdOutlineAutoStories,
  MdOutlinePaid,
  MdOutlineSettings,
} from 'react-icons/md';
import { UI } from '@components';

interface HeadingProps {
  titleId?:
    | 'Albums'
    | 'Album_approval'
    | 'Change_password'
    | 'Downloads'
    | 'Orders'
    | 'Payment'
    | 'Photo_selection'
    | 'Selection_review'
    | 'Checkout'
    | 'CheckoutUserSettings'
    | 'Settings';
    hasReturnButton?: boolean;
  onReturnClick?: () => void;
  tabs?: Array<UI.ITab>;
  currentId?: string | number;
  isLoading?: boolean;
}

const TitleIcon = [
  {
    name: 'Albums',
    label: 'Álbuns',
    Icon: MdOutlineAutoStories,
  },
  {
    name: 'Album_approval',
    label: 'Aprovação de Álbum',
    Icon: MdOutlineAutoStories,
  },
  {
    name: 'Change_password',
    label: 'Alterar minha senha',
    Icon: MdOutlineLock,
  },
  {
    name: 'Downloads',
    label: 'Downloads',
    Icon: MdOutlineFileDownload,
  },
  {
    name: 'Orders',
    label: 'Meus Pedidos',
    Icon: MdOutlineShoppingBag,
  },
  {
    name: 'Payment',
    label: 'Pagamento',
    Icon: MdOutlineFileDownload,
  },
  {
    name: 'Photo_selection',
    label: 'Seleção de Fotos',
    Icon: MdOutlinePhotoLibrary,
  },
  {
    name: 'Selection_review',
    label: 'Revisão da Seleção',
    Icon: MdOutlinePhotoLibrary,
  },
  {
    name: 'Checkout',
    label: 'Pagamento',
    Icon: MdOutlinePaid,
  },
  {
    name: 'CheckoutUserSettings',
    label: 'Checkout - Preencha seus Dados Pessoais',
    Icon: MdOutlinePaid,
  },
  {
    name: 'Settings',
    label: 'Configurações',
    Icon: MdOutlineSettings,
  },
];

export function Heading({
  titleId,
  hasReturnButton = true,
  onReturnClick,
  tabs,
  currentId,
  isLoading = false,
}: HeadingProps) {
  return (
    <div className="heading">
      {hasReturnButton && (
        <div className="heading__left">
          <div className={`heading__content ${isLoading ? 'skeleton' : ''}`}>
            <button
              type="button"
              className="heading__back-btn"
              onClick={onReturnClick}
            >
              <MdArrowBack className="heading__icon" />
            </button>
          </div>
        </div>
      )}
      <div className="heading__right">
        <div className={`heading__content ${isLoading ? 'skeleton' : ''}`}>
          {titleId && (
            <>
              {TitleIcon.map((Heading) => {
                const { name, label, Icon } = Heading;

                if (name === titleId) {
                  return (
                    <div key={name} className="heading__title-icon">
                      <Icon className="heading__icon" />
                      <h1 className="heading__title">
                        {' '}
                        {label}
                        {' '}
                      </h1>
                    </div>
                  );
                }
              })}
            </>
          )}
          {tabs && (
            <UI.Tabs
              variant={UI.TabsEnum.heading}
              currentId={currentId || ''}
              tabs={tabs}
            />
          )}
        </div>
      </div>
    </div>
  );
}
