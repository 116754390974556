import { FormEvent, useContext, useState } from 'react';
import { lang } from '@i18n';
import { routes } from '@routes';
import {
  Form,
  Notification,
  Template,
  UI,
} from '@components';
import { AuthContext, RouterContext } from '@contexts';
import { GalleryContext } from '@contexts/gallery.context';
import { LoginError, LoginErrorType, LoginForm } from './types';

export function Login() {
  const { translate, keys: { errors, forms, pages } } = lang;
  const { navigate } = useContext(RouterContext);
  const { login } = useContext(AuthContext);
  const context = useContext(GalleryContext);
  const [loading, setLoading] = useState(false);
  const [loginErrors, setLoginErrors] = useState<LoginError | null>(null);
  const [form, setForm] = useState<LoginForm>({ email: '', password: '' });

  const onClickGuest = () => navigate(routes.gallery());
  const onClickModal = () => setLoginErrors(null);
  const onChange = ({ currentTarget }: FormEvent<HTMLInputElement>) => {
    setForm({ ...form, [currentTarget.name]: currentTarget.value });
  };

  const onClickRegister = () => {
    navigate(routes.register());
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    // if (!validateSchema(loginSchema, form)) return;

    try {
      setLoading(true);
      await login(form.email, form.password);
      setLoginErrors({ type: 'error', message: translate(errors.login.invalid_auth_data) });
    } catch (error: any) {
      const isInvalid = error?.response?.status === 400;
      const type: LoginErrorType = isInvalid ? 'error' : 'warning';
      const message = isInvalid ? errors.login.invalid_auth_data : errors.api.generic;
      setLoginErrors({ type, message });
    } finally {
      setLoading(false);
    }
  };

  return (
    // translate(pages.login.subtitle)
    <Template.Page title={context?.galleryName} description="subtitle login">
      <form className="login" onSubmit={handleSubmit} noValidate>
        <div className="form">
          <div className="form__grid">
            <div className="form__group">
              <Form.Label name="email" text={translate(forms.users.email)} />
              <Form.Input
                id="email"
                name="email"
                onChange={onChange}
                type="email"
                value={form.email}
                error={null}
                placeholder={translate(forms.users.email_login_placeholder)}
              />
            </div>
            <div className="form__group">
              <div className="form__row form__row--between">
                <Form.Label name="password" text={translate(forms.users.password)} />
                <a className="login__link" onClick={() => navigate(routes.recovery())}>
                  {translate(pages.login.recovery_password)}
                </a>
              </div>
              <Form.Input
                id="password"
                name="password"
                onChange={onChange}
                type="password"
                value={form.password}
                error={null}
                placeholder={translate(forms.users.password_login_placeholder)}
              />
            </div>
            <div className="login__btn-group">
              <UI.Button
                type="submit"
                variant={UI.ButtonEnum.primary}
                loading={loading}
                text={translate(pages.login.action_submit)}
              />
              <div className="form__btn-message">
                {loginErrors && (<Notification.Message type={loginErrors.type} onClick={onClickModal} message={loginErrors.message} />)}
                <UI.Button
                  type="button"
                  disabled={!context?.publicAccess}
                  variant={UI.ButtonEnum.neutralOutline}
                  loading={false}
                  onClick={onClickGuest}
                  text="Usuário convidado"
                // text={translate(pages.login.guest_user)}
                />
              </div>
              <UI.Button
                type="button"
                disabled={false}
                variant={UI.ButtonEnum.neutralOutline}
                loading={false}
                onClick={onClickRegister}
                text={lang.translate(lang.keys.pages.login.register)}
              />
            </div>
          </div>
        </div>
      </form>
    </Template.Page>
  );
}
