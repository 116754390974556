import { AiFillWarning } from 'react-icons/ai';
import { BsCheckCircle } from 'react-icons/bs';

interface MessageProps {
  message: string;
  onClick: () => void;
  type: 'error' | 'success' | 'warning';
}

export function Message({ message, onClick, type }: MessageProps) {
  return (
    <div
      className={`message message--${type}`}
    >
      <div className="h-full justify-start">
        {type === 'error' ? (
          <AiFillWarning className="message__icon" />
        ) : (
          <BsCheckCircle className="message__icon" />
        )}
      </div>
      <p className="message__text">{message}</p>
      <span onClick={onClick} className="message__btn">
        OK
      </span>
    </div>
  );
}
