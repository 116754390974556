/* eslint-disable react/no-array-index-key */
import { MdOutlineNoPhotography } from 'react-icons/md';
import { useFormatLocal } from '@hooks';
import { Product } from '@types';

export interface IOrderItem {
  productName: string;
  quantity: number;
  productImageUrl?: string;
  unitValue?: number;
}

interface OrderResumeProps {
  orderResumeSummary: Array<Product.Summary>;
  orderTotalValue: number;
  isLoading: boolean;
}

export function OrderResume({
  orderResumeSummary,
  orderTotalValue,
  isLoading,
}: OrderResumeProps) {
  const { formatCurrencyPtBR } = useFormatLocal();

  return (
    <div className="order-resume">
      <div className="page__bg-area order-resume__container">
        <div className="page__heading">
          <h5 className={`page__title page__title--small page__title--color-neutral page__title--bold
          ${isLoading ? 'skeleton' : ''}`}
          >
            Resumo do pedido
          </h5>
        </div>

        <div className="order-resume__table">
          <div className="items-table items-table--header">
            <ul className="items-table__list">
              <li className="items-table__item">
                <div className="items-table__left">
                  <span className={`items-table__text items-table__text--small ${isLoading ? 'skeleton' : ''}`}>
                    Produtos
                  </span>
                </div>
                <div className="items-table__middle">
                  <span className={`items-table__text items-table__text--small ${isLoading ? 'skeleton' : ''}`}>
                    Qtd
                  </span>
                </div>
                <div className="items-table__right">
                  <span className={`items-table__text items-table__text--small ${isLoading ? 'skeleton' : ''}`}>
                    Valor Un.
                  </span>
                </div>
              </li>
            </ul>
          </div>

          <div className="items-table">
            <ul className="items-table__list">
              {orderResumeSummary
                && orderResumeSummary.map(
                  (
                    {
                      productName, quantity, productImageUrl, unitValue = 0,
                    },
                    index,
                  ) => (
                    <li key={index} className="items-table__item">
                      <div className="items-table__left">
                        <div className={`items-table__aspect-ratio ${isLoading ? 'skeleton' : ''}`}>
                          { productImageUrl && (
                            <img
                              src={productImageUrl}
                              alt=""
                              className="items-table__img"
                            />
                          )}
                          { !productImageUrl && (
                            <MdOutlineNoPhotography
                              className="items-table__img items-table__img--icon"
                            />
                          )}
                        </div>
                        <div className="items-table__title-desc-wrapper">
                          <span className={`items-table__text ${isLoading ? 'skeleton' : ''}`}>
                            {productName}
                          </span>
                        </div>
                      </div>
                      <div className="items-table__middle">
                        <span className={`items-table__text ${isLoading ? 'skeleton' : ''}`}>{quantity}</span>
                      </div>
                      <div className="items-table__right">
                        <span className={`items-table__text ${isLoading ? 'skeleton' : ''}`}>
                          {formatCurrencyPtBR(unitValue)}
                        </span>
                      </div>
                    </li>
                  ),
                )}
            </ul>
          </div>

          <div className="items-table items-table--bottom">
            <ul className="items-table__list">
              <li className="items-table__item">
                <div className="items-table__left">
                  <span className={`items-table__text items-table__text--small items-table__text--emphasys
                   ${isLoading ? 'skeleton' : ''}`}
                  >
                    <b>Total</b>
                  </span>
                </div>
                <div className="items-table__middle">
                  <span className={`items-table__text items-table__text--small items-table__text--emphasys
                   ${isLoading ? 'skeleton' : ''}`}
                  >
                    <b>
                      {orderResumeSummary.reduce(
                        (total, value) => total + value.quantity,
                        0,
                      )}
                    </b>
                  </span>
                </div>
                <div className="items-table__right">
                  <span className={`items-table__text items-table__text--small items-table__text--emphasys
                   ${isLoading ? 'skeleton' : ''}`}
                  >
                    <b>{formatCurrencyPtBR(orderTotalValue)}</b>
                  </span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
