/* eslint-disable no-unused-vars */
/* revisar componente na implementação das pages */

import 'react-tooltip/dist/react-tooltip.css';

import { MdStarBorder } from 'react-icons/md';
import { IconType } from 'react-icons';
import { Tooltip } from 'react-tooltip';

export enum TabsEnum {
  heading = 'tabs--heading',
  buttonsNeutralOutline = '',
  paymentMethods = 'tabs--payment-methods',
  flexWrap = 'tabs--flex-wrap',
}

export interface ITab {
  id: number | string;
  name: string;
  iconPngUrl?: string;
  Icon?: IconType;
  toolTip?: string;
  handleClick: () => void;
  disabled?: boolean
}

export interface ITabs {
  currentId: number | string;
  tabs: Array<ITab>;
  variant?: TabsEnum;
  isLoading?: boolean;
}

export function Tabs({
  currentId, tabs, variant, isLoading = false,
}: ITabs) {
  return (
    <div className={`tabs ${variant || ''}`}>
      <div className="tabs__overflow-container custom-scrollbar">
        {tabs?.map(({
          id, name, iconPngUrl, Icon, handleClick, disabled = false,
        }) => (
          <button
            type="button"
            key={id}
            id={id.toString()}
            className={`tabs__btn btn btn--neutral btn--outline 
                  ${currentId === id ? 'btn--active' : ''}
                  ${name === 'suggested' ? 'tabs__btn--suggested' : ''}
                  ${isLoading ? 'skeleton' : ''}`}
            onClick={handleClick}
            disabled={disabled}
          >
            {iconPngUrl && (<img src={iconPngUrl} alt={name} className="btn__icon-img img-icon" />)}

            {Icon && <Icon className="btn__icon" />}
            {name === 'suggested' || name === 'Melhores Fotos' ? (<MdStarBorder className="btn__icon" />) : null}

            <span className="tabs__text">
              {name === 'suggested' || name === 'Melhores Fotos' ? 'Sugeridas' : name}
            </span>
            <span className="tabs__border-bottom" />

            <Tooltip data-tooltip-id={id.toString()} />
          </button>
        ))}
      </div>
    </div>
  );
}
