import { Routes, Route, Navigate } from 'react-router-dom';
import { MappedRoutes, paths, routesMap } from './map';

export const routes = paths;

export const AppRoutes = () => {
  const mappedRoutes = Object.keys(routesMap).map((k) => routesMap[k as MappedRoutes]);
  return (
    <Routes>
      {mappedRoutes.map(({ path, element, guards }) => {
        let pageElement = element;
        guards.forEach((guard) => {
          pageElement = guard({ children: pageElement });
        });
        return <Route key={path} path={path} element={pageElement} />;
      })}
      <Route path="/" element={routesMap.notFound.element} />
      <Route path="/*" element={<Navigate to={paths.login()} replace />} />
    </Routes>
  );
};
