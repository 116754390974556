import { FC, PropsWithChildren } from 'react';
import {
  ApiContextProvider,
  AuthContextProvider,
  BannerContextProvider,
  GalleryContextProvider,
  RouterContextProvider,
} from '@contexts';

export const FotoGoProviders: FC<PropsWithChildren> = ({ children }) => (
  <RouterContextProvider>
    <AuthContextProvider>
      <GalleryContextProvider>
        <ApiContextProvider>
          <BannerContextProvider>
            {children}
          </BannerContextProvider>
        </ApiContextProvider>
      </GalleryContextProvider>
    </AuthContextProvider>
  </RouterContextProvider>
);
