/* eslint-disable arrow-body-style */
import { useCallback } from 'react';
import { ShoppingCart } from '@types';
import { useApi } from './api';
import { GetShoppingCartItemsRequest } from './types/shopping-cart';

export const useShoppingCart = () => {
  const api = useApi();

  const clearShoppingCartAndAddItem = useCallback((request: ShoppingCart.Item) => {
    return api.post('/shoppingCart/clearCartAndAddItem', request);
  }, [api]);

  const getShoppingCartItems = useCallback(({ partnerSlug }: GetShoppingCartItemsRequest) => {
    return api.get<ShoppingCart.Item[]>(`/shoppingCart/partner/${partnerSlug}/items`);
  }, [api]);

  return {
    clearShoppingCartAndAddItem,
    getShoppingCartItems,
  };
};
