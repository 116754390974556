import {
  useState,
  useEffect,
  useRef,
} from 'react';
import { usePartners } from '@api';
import { Partner } from '@types';

/* eslint-disable no-unused-vars */
export enum LogoEnum {
  logoHome = 'logo--home',
  logoHeader = 'logo--header',
}

interface LogoProps {
  variant: LogoEnum;
}

export function Logo({ variant }: LogoProps) {
  const [partner, setPartner] = useState<Partner.Info | null>(null);
  const { getPartnerInfo } = usePartners();
  const logoRef = useRef<HTMLImageElement>(null);

  const getPartner = async () => {
    const { data } = await getPartnerInfo();
    if (!data.logoUrl) {
      data.logoUrl = 'https://s3.amazonaws.com/foto-go-premium.partners-files/static/logos/logo.png';
    }
    setPartner(data);
  };

  useEffect(() => {
    getPartner();
  }, []);

  return (
    <div ref={logoRef} className={`logo ${variant} skeleton`}>
      <img
        className="logo__img"
        src={partner?.logoUrl}
        alt="Logo do Fotógrafo"
        onLoad={() => logoRef.current?.classList.remove('skeleton')}
      />
    </div>
  );
}
