/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* revisar componente na implementação das pages */

import {
  MdOutlineAutoStories,
  MdOutlineFileDownload,
  MdOutlineImage,
  MdOutlineShoppingBag,
  MdOutlinePersonOutline,
} from 'react-icons/md';

import { useContext, useEffect, useState } from 'react';
import { IconType } from 'react-icons';
import { RouterContext } from '@contexts';
import { usePartners } from '@api';
import { GetPartnerInfoHeaderResponse } from '@api/types';

export enum NavbarEnum {
  row = 'navbar--row',
  Home1 = 'navbar--home-1',
  column = '',
}

export type NavBarItens = 'Photo' | 'Downloads' | 'Albums' | 'AlbumApproval' | 'Orders' | 'UserSettings';

interface NavbarProps {
  variant: NavbarEnum;
  active?: NavBarItens;
  hiddenItens?: NavBarItens[]
}

export function Navbar({ variant, active, hiddenItens }: NavbarProps) {
  const { slugs } = useContext(RouterContext);
  const { getPartnerInfoHeader } = usePartners();

  const [availableNavLinks, setAvailableNavLinks] = useState<GetPartnerInfoHeaderResponse>({
    hasDownloads: true,
    hasSelections: true,
    hasAlbums: false,
  });
  const [availableNavLinksIsLoading, setAvailableNavLinksIsLoading] = useState<boolean>(true);

  async function getAvailableNavLinks() {
    try {
      const { data } = await getPartnerInfoHeader({
        partnerSlug: slugs.partner,
        gallerySlug: slugs.project,
      });
      setAvailableNavLinks(data);
    } catch (error) {
      console.log('error', error);
    }

    setAvailableNavLinksIsLoading(false);
  }

  useEffect(() => {
    getAvailableNavLinks();
  }, []);

  const Links: { name: NavBarItens, url: string, label: string, Icon: IconType, showItem?: boolean }[] = [
    {
      name: 'Photo',
      url: `/${slugs.project}/selection`,
      label: 'Fotos',
      Icon: MdOutlineImage,
      showItem: availableNavLinks.hasSelections,
    },
    {
      name: 'Downloads',
      url: `/${slugs.project}/downloads`,
      label: 'Downloads',
      Icon: MdOutlineFileDownload,
      showItem: availableNavLinks.hasDownloads,
    },
    {
      name: 'Albums',
      url: `/${slugs.project}/albums`,
      label: 'Álbuns',
      Icon: MdOutlineAutoStories,
      showItem: availableNavLinks.hasAlbums,
    },
    {
      name: 'Orders',
      url: `/${slugs.project}/orders`,
      label: 'Meus Pedidos',
      Icon: MdOutlineShoppingBag,
    },
    {
      name: 'UserSettings',
      url: `/${slugs.project}/user-settings`,
      label: 'Meu Perfil',
      Icon: MdOutlinePersonOutline,
    },
  ];

  return (
    <nav className={`navbar ${variant}`}>
      <ul className="navbar__list">
        {Links.map((item) => {
          const {
            name, url, Icon, label, showItem = true,
          } = item;

          if (!hiddenItens?.includes(name) && showItem) {
            return (
              <li key={name} className={`navbar__item ${availableNavLinksIsLoading ? 'skeleton' : ''}`}>
                <a href={url} className="navbar__link">
                  <div className={`navbar__wrapper ${active === name ? 'navbar__wrapper--active' : ''}`}>
                    <Icon className="navbar__icon" />
                    <h3 className="navbar__text">{label}</h3>
                  </div>
                </a>
              </li>
            );
          }
        })}
      </ul>
    </nav>
  );
}
