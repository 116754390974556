import { UI, Template } from '@components';
import { useFormatLocal } from '@hooks';
import { Order } from '@types';

export interface ICardOrder extends Order.Summary {
  totalItemsCount: number;
  onDetailClick: () => void;
}

interface CardOrderProps {
  cardOrder: ICardOrder;
  isLoading: boolean;
}

export function CardOrder({ cardOrder, isLoading }: CardOrderProps) {
  const { formatCurrencyPtBR, formatLocalDate } = useFormatLocal();

  return (
    <div className="card-order">
      <div className="card-order__inner">
        <div className="card-order__grid">
          <div className="card-order__info-group">
            <span className={`card-order__label ${isLoading ? 'skeleton' : ''}`}>Número de Pedido</span>
            <span className={`card-order__value ${isLoading ? 'skeleton' : ''}`}>{cardOrder.goImageId}</span>
          </div>
          <div className="card-order__info-group">
            <Template.PaymentStatus
              isLoading={isLoading}
              status={cardOrder.paymentStatus}
            />
          </div>
          <div className="card-order__info-group">
            <span className={`card-order__label ${isLoading ? 'skeleton' : ''}`}>Realizado em</span>
            <span className={`card-order__value ${isLoading ? 'skeleton' : ''}`}>
              {formatLocalDate(cardOrder.createdAt)}
            </span>
          </div>
          <div className="card-order__info-group">
            {cardOrder.paymentConfirmationDate && (
              <>
                <span className={`card-order__label ${isLoading ? 'skeleton' : ''}`}>Pagamento confirmado</span>
                <span className={`card-order__value ${isLoading ? 'skeleton' : ''}`}>
                  {formatLocalDate(cardOrder.paymentConfirmationDate)}
                </span>
              </>
            )}
          </div>
          <div className="card-order__info-group">
            <span className={`card-order__label ${isLoading ? 'skeleton' : ''}`}>Quantidade</span>
            <span className={`card-order__value ${isLoading ? 'skeleton' : ''}`}>
              {cardOrder.totalItemsCount}
            </span>
          </div>
          <div className="card-order__info-group">
            <span className={`card-order__label ${isLoading ? 'skeleton' : ''}`}>Valor Total</span>
            <span className={`card-order__value ${isLoading ? 'skeleton' : ''}`}>
              {formatCurrencyPtBR(cardOrder.totalValue)}
            </span>
          </div>
        </div>
        <div className={`card-order__btn-group ${isLoading ? 'skeleton' : ''}`}>
          <UI.Button
            type="button"
            variant={UI.ButtonEnum.primaryOutline}
            loading={false}
            onClick={cardOrder.onDetailClick}
            fullwidth="w-full"
            text="Ver detalhes"
          />
        </div>
      </div>
    </div>
  );
}
