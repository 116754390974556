import '@styles/Layout.scss';
import 'react-notifications-component/dist/theme.css';
import { BrowserRouter } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import { AppRoutes } from '@routes';
import { FotoGoProviders } from './FotoGoProviders';

const App = () => (
  <>
    <ReactNotifications />
    <BrowserRouter>
      <FotoGoProviders>
        <AppRoutes />
      </FotoGoProviders>
    </BrowserRouter>
  </>
);

export default App;
