/* eslint-disable arrow-body-style */
import { useCallback } from 'react';
import { Partner } from '@types';
import { useApi } from './api';
import { GetPartnerInfoHeaderRequest, GetPartnerInfoHeaderResponse } from './types';

export const usePartners = () => {
  const api = useApi();

  const getPartnerInfo = useCallback(() => api.get<Partner.Info>('/partners/info'), [api]);

  const getPartnerInfoHeader = useCallback(({ gallerySlug, partnerSlug }: GetPartnerInfoHeaderRequest) => {
    return api.get<GetPartnerInfoHeaderResponse>(`/partners/${partnerSlug}/gallery/${gallerySlug}/infosHeader`);
  }, [api]);

  return {
    getPartnerInfo,
    getPartnerInfoHeader,
  };
};
