import i18n from 'i18n-js';
import ptBr from './pt-br.json';

i18n.fallbacks = true;
i18n.translations = {
  'pt-BR': ptBr,
};

i18n.locale = 'pt-BR';

const translate = (val: string, vars = {}) => i18n.t(val, vars);

function getObjectKeys(obj: any, acc: string): any {
  const keys = Object.keys(obj);

  const objValue: any = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const k of keys) {
    if (typeof obj[k] !== 'string') {
      objValue[k] = {
        ...getObjectKeys(obj[k], acc === '' ? `${k}` : `${acc}.${k}`),
      };
    } else {
      objValue[k] = `${acc}.${k}`;
    }
  }

  return objValue;
}

const keys = getObjectKeys(ptBr, '') as typeof ptBr;

export { translate, keys };
