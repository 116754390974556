import logoFotogo from '@assets/logo_fotogo.png';

export const NotFound = () => (
  <main className="page__main not-found">
    <section className="page__section not-found__section">
      <div className="page__container not-found__container">
        <div className="not-found__logo">
          <img src={logoFotogo} alt="" className="not-found__logo-img" />
        </div>
      </div>
    </section>
    <section className="page__section not-found__section">
      <div className="page__container not-found__container">
        <h1 className="not-found__title">Página não encontrada</h1>
        <p className="not-found__text">
          <strong>Entre em contato com o fotógrafo</strong>
        </p>
      </div>
    </section>
  </main>
);
