/* eslint-disable eqeqeq */
import { Listbox } from '@headlessui/react';
import { MdOutlineKeyboardArrowDown, MdOutlineCheck } from 'react-icons/md';
import { Notification } from '@components';

export interface ISelectOption {
  value: number | string;
  text: string;
  comment?: string;
}

interface SelectProps {
  name: string;
  error?: any; // Arthur
  selectOptions: ISelectOption[];
  selectedValue: number | string;
  onChange: (_value: number | string) => void;
  disabled?: boolean;
}

export function Select({
  name,
  error,
  selectOptions,
  selectedValue,
  onChange,
  disabled = false,
}: SelectProps) {
  const currentValue = selectOptions.find(
    (option: ISelectOption) => option.value === selectedValue,
  );

  const err = error?.find((e: any) => e.key === name);

  return (
    <>
      <Listbox value={selectedValue} onChange={onChange} disabled={disabled}>
        <div className="select">
          <Listbox.Button
            className={`select__field ${err && 'select__field--error'}`}
          >
            <span className="select__text">{currentValue?.text}</span>
            <span className="select__arrow">
              <MdOutlineKeyboardArrowDown
                className="select__arrow-icon"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Listbox.Options className="select__option-dropdown">
            {selectOptions.map(({ value, text, comment }) => (
              <Listbox.Option
                key={value}
                className={({ active }) => `select__option-btn ${
                  active ? 'select__option-btn--active' : ''
                }`}
                value={value}
              >
                {({ selected }) => (
                  <span
                    className={`select__text ${
                      selected ? 'select__text--selected' : ''
                    }`}
                  >
                    {text}
                    {comment && (
                      <small className="select__comment">{comment}</small>
                    )}
                    {selected && (
                      <MdOutlineCheck
                        className="select__option-icon"
                        aria-hidden="true"
                      />
                    )}
                  </span>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
      {err && err.message && (
        <Notification.ErrorMessage message={err.message} />
      )}
    </>
  );
}
