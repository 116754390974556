/* eslint-disable arrow-body-style */
import { useCallback } from 'react';
import { Product } from '@types';
import { useApi } from './api';
import { GetProductByIdRequest } from './types/product';

export const useProducts = () => {
  const api = useApi();

  const getProductById = useCallback(({ productId }: GetProductByIdRequest) => {
    return api.get<Product.Detailed>(`/products/product/${productId}`);
  }, [api]);

  return {
    getProductById,
  };
};
