/* eslint-disable no-unused-vars */
import { useContext } from 'react';
import { GalleryContext } from '@contexts';

export enum ProjectTitleEnum {
  home = 'project-title--home',
  header = 'project-title--header',
}

interface LogoProps {
  variant: ProjectTitleEnum
}

export function ProjectTitle({ variant }: LogoProps) {
  const context = useContext(GalleryContext);

  return (
    <div className={`project-title ${variant}`}>
      <h1 className="project-title__text">{context?.galleryName}</h1>
    </div>
  );
}
