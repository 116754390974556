import { useState, useEffect } from 'react';

export interface ProgressiveImgInterface {
    alt: string,
    classes: string,
    height: string,
    placeholderSrc: string,
    src: string,
    width: string,
}

export function ProgressiveImg({
  alt, classes, height, placeholderSrc, src, width,
}: ProgressiveImgInterface) {
  const [imgSrc, setImgSrc] = useState(placeholderSrc || src);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => {
      setImgSrc(src);
      setLoading(false);
    };
  }, [src]);

  return (
    <img
      src={imgSrc}
      alt={alt}
      className={`${classes} ${loading ? 'progressive-image' : ''}`}
      height={height}
      width={width}
    />
  );
}
