import { Template } from '@components';

export const albumSummaryPlaceholder: Array<Template.IAlbumSummary> = [
  {
    id: '123',
    name: 'placeholder',
    status: 'Pending',
    expirationDate: new Date(),
    createdAt: new Date(),
    viewerUrl: 'placeholder',
    handleButtonClick: () => { },
  },
  {
    id: '123123',
    name: 'placeholder',
    status: 'Pending',
    expirationDate: new Date(),
    createdAt: new Date(),
    viewerUrl: 'placeholder',
    handleButtonClick: () => { },
  },
];

export const selectionProgressCardSummaryPlaceholder: Array<Template.ISelectionProgressCardSummary> = [
  {
    id: '123',
    title: 'placeholder',
    leftSubtitle: 'placeholder',
    rightSubtitle: 'placeholder',
    buttonLabel: 'placeholder',
    handleButtonClick: () => { },
    currentValue: 10,
    totalValue: 20,
    status: 'Pending',
  },
  {
    id: '123123',
    title: 'placeholder',
    leftSubtitle: 'placeholder',
    rightSubtitle: 'placeholder',
    buttonLabel: 'placeholder',
    handleButtonClick: () => { },
    currentValue: 10,
    totalValue: 20,
    status: 'Pending',
  },
];
