import { useContext } from 'react';
import { MdOutlineExpandMore, MdOutlineSettings, MdOutlineLogout } from 'react-icons/md';
import { UI, Template } from '@components';
import { routes } from '@routes';
import { AuthContext, RouterContext } from '@contexts';

interface HeaderProps {
  active?: 'Photo' | 'Downloads' | 'Albums' | 'Orders';
}

export function Header({ active }: HeaderProps) {
  const { navigate } = useContext(RouterContext);
  const { auth } = useContext(AuthContext);
  const hiddenItens: UI.NavBarItens[] = ['UserSettings'];

  function logout(): void {
    navigate(routes.login());
  }

  const dropdownButton: UI.dropdownButton = {
    classes: 'header__user-btn',
    Icon: MdOutlineExpandMore,
    text: auth.userId || '',
  };

  const dropdownItems: UI.dropdownItem[] = [
    {
      id: 'settings',
      Icon: MdOutlineSettings,
      text: 'Configurações',
      onClick: () => navigate(routes.userSettings()),
    },
    {
      id: 'logout',
      Icon: MdOutlineLogout,
      text: 'Sair',
      onClick: logout,
    },
  ];

  return (
    <>
      <header id="header" className="header">
        <div className="header__inner">
          <div className="page__container">
            <div className="header__container">
              <div className="header__left">
                <div className="header__logo">
                  <a className="header__logo-link">
                    <UI.Logo variant={UI.LogoEnum.logoHeader} />
                  </a>
                </div>
                <div className="header__project-title-wrapper">
                  <Template.ProjectTitle variant={Template.ProjectTitleEnum.header} />
                </div>
              </div>
              <div className="header__middle">
                <div className="header__menu">
                  <UI.Navbar
                    active={active}
                    variant={UI.NavbarEnum.row}
                    hiddenItens={hiddenItens}
                  />
                </div>
              </div>
              <div className="header__right">
                <UI.Dropdown dropdownItems={dropdownItems} dropdownButton={dropdownButton} align="right" />
              </div>
              <div className="header__mobile-trigger">
                <UI.HamburgerMenu />
              </div>
            </div>
          </div>
        </div>
      </header>
      <Template.OffcanvasMenu />
    </>
  );
}
