import { FC, PropsWithChildren, ReactNode } from 'react';
import { ProjectCover } from './ProjectCover';

interface AccessHubProps extends PropsWithChildren {
  title?: string;
  description?: string;
  descriptionEl?: ReactNode;
}

export const Page: FC<AccessHubProps> = ({
  title,
  description,
  descriptionEl,
  children,
}) => (
  <main className="access-hub page__main">
    <section className="page__section page__section--grow">
      <div className="page__container page__container--center">
        <div className="access-hub__bg">
          <ProjectCover />
        </div>
        <div className="access-hub__container custom-scrollbar page__bg-area">
          <div className="page__heading">
            {title && <h3 className="page__title page__title--bold page__title--large page__title--color-neutral">{title}</h3>}
            {description && <p className="page__desc">{description}</p>}
            {descriptionEl && descriptionEl}
          </div>
          {children}
        </div>
      </div>
    </section>
  </main>
);
