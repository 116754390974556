/* eslint-disable arrow-body-style */
import { useCallback } from 'react';
import { Comment, Scene, Selection } from '@types';
import { useApi } from './api';
import {
  AddSelectedFileCommentRequest,
  BaseGallerySelectionRequest,
  GallerySelectionRequestWithFileId,
  GetSelectionSceneFilesRequest,
} from './types/galleries_selections';

export const useGallerySelection = () => {
  const api = useApi();

  const getSelectionList = useCallback(() => api.get<Selection.Item[]>('/galleriesSelections/selections'), [api]);

  const getSelectionInfo = useCallback(({ selectionId }: BaseGallerySelectionRequest) => {
    return api.get<Selection.Info>(`/galleriesSelections/selection/${selectionId}/selectionDetails`);
  }, [api]);

  const getSelectionSceneFiles = useCallback(({ sceneId, sceneQuery, selectionId }: GetSelectionSceneFilesRequest) => {
    return api.post<Scene.WithImages>(`/galleriesSelections/scene/${sceneId}/selection/${selectionId}/files`, sceneQuery);
  }, [api]);

  const addSelectedFile = useCallback(({ selectionId, fileId }: GallerySelectionRequestWithFileId) => {
    return api.post(`/galleriesSelections/selection/${selectionId}/file/${fileId}`);
  }, [api]);

  const removeSelectedFile = useCallback(({ fileId, selectionId }: GallerySelectionRequestWithFileId) => {
    return api.delete(`/galleriesSelections/selection/${selectionId}/file/${fileId}`);
  }, [api]);

  const finishSelection = useCallback(({ selectionId }: BaseGallerySelectionRequest) => {
    return api.patch(`/galleriesSelections/selection/${selectionId}/finish`);
  }, [api]);

  const getSelectionFileComments = useCallback(({ fileId, selectionId }: GallerySelectionRequestWithFileId) => {
    return api.get<Comment[]>(`/galleriesSelections/fileId/${fileId}/selection/${selectionId}/comment`);
  }, [api]);

  const addSelectedFileComment = useCallback(({ fileId, selectionId, text }: AddSelectedFileCommentRequest) => {
    return api.post(`/galleriesSelections/selection/${selectionId}/comment`, { fileId, text });
  }, [api]);

  const selectionFileCommentsRead = useCallback(({ fileId, selectionId }: GallerySelectionRequestWithFileId) => {
    return api.get(`/galleriesSelections/fileId/${fileId}/selection/${selectionId}/comments/read`);
  }, [api]);

  return {
    getSelectionList,
    getSelectionInfo,
    getSelectionSceneFiles,
    addSelectedFile,
    removeSelectedFile,
    finishSelection,
    getSelectionFileComments,
    addSelectedFileComment,
    selectionFileCommentsRead,
  };
};
