/* eslint-disable react/no-array-index-key */
import {
  useState,
  useEffect,
  useContext,
  Fragment,
} from 'react';
import { Template, UI } from '@components';
import { routes } from '@routes';
import { RouterContext } from '@contexts';
import { useOrders } from '@api';

export function Orders() {
  const { navigate, slugs } = useContext(RouterContext);
  const { getOrdersList } = useOrders();
  const [ordersSummary, setOrdersSummary] = useState<Template.ICardOrder[]>([]);
  const [ordersSummaryIsLoading, setOrdersSummaryIsLoading] = useState<boolean>(true);

  const ordersSummaryPlaceholder: Template.ICardOrder[] = [
    {
      id: '2',
      goImageId: 2,
      createdAt: new Date(),
      paymentConfirmationDate: new Date(),
      productsSummary: [
        {
          productName: 'placeholder',
          quantity: 99,
          productImageUrl: 'placeholder',
          unitValue: 99,
        },
        {
          productName: 'placeholder',
          quantity: 99,
          productImageUrl: 'placeholder',
          unitValue: 99,
        },
      ],
      paymentStatus: 'Pending',
      totalValue: 99,
      totalItemsCount: 99,
      onDetailClick: () => { },
    },
    {
      id: '3',
      goImageId: 3,
      createdAt: new Date(),
      paymentConfirmationDate: new Date(),
      productsSummary: [
        {
          productName: 'placeholder',
          quantity: 99,
          productImageUrl: 'placeholder',
          unitValue: 99,
        },
        {
          productName: 'placeholder',
          quantity: 99,
          productImageUrl: 'placeholder',
          unitValue: 99,
        },
      ],
      paymentStatus: 'Pending',
      totalValue: 99,
      totalItemsCount: 99,
      onDetailClick: () => { },
    },
  ];

  async function getOrders() {
    setOrdersSummaryIsLoading(true);

    setOrdersSummary(ordersSummaryPlaceholder);

    const { data } = await getOrdersList({
      partnerSlug: slugs.partner,
    });

    setOrdersSummary(
      data.orderSummaryList.map((order) => ({
        ...order,
        totalItemsCount: order.productsSummary.length,
        onDetailClick: () => {
          navigate(routes.orderDetail(order.goImageId as unknown as string));
        },
      })),
    );

    setOrdersSummaryIsLoading(false);
  }

  useEffect(() => {
    getOrders();
  }, []);

  function returnToHomePage() {
    navigate(routes.home());
  }

  return (
    <>
      <Template.Header active="Orders" />
      <main className="page__main orders">
        <section className="page__section">
          <div className="page__container">
            <div className="page__component-wrapper">
              <Template.Heading
                titleId="Orders"
                onReturnClick={returnToHomePage}
              />
            </div>
          </div>
        </section>
        <section className="page__section">
          <div className="page__container">
            <div className={`orders__grid ${ordersSummary.length === 0 ? 'orders__grid--column-1' : ''}`}>
              {ordersSummary
                && ordersSummary.reverse().map((order, index) => (
                  <Fragment key={index}>
                    <Template.CardOrder
                      isLoading={ordersSummaryIsLoading}
                      cardOrder={order}
                    />
                  </Fragment>
                ))}

              {ordersSummary.length === 0 && (
                <UI.NoContent
                  title="Você ainda não realizou pedidos"
                  buttonLabel="Ir para Home"
                  onButtonClick={returnToHomePage}
                />
              )}
            </div>
          </div>
        </section>
      </main>
      <Template.Footer />
    </>
  );
}
