import { useCallback } from 'react';
import { Address } from '@types';
import { useApi } from './api';

export const useStates = () => {
  const api = useApi();

  const getStates = useCallback(() => api.get<Address.State[]>('/shipping/states'), [api]);

  return {
    getStates,
  };
};
