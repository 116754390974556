/* eslint-disable arrow-body-style */
import { useCallback } from 'react';
import { Payment } from '@types';
import { useApi } from './api';
import { GetInstallmentsRequest } from './types/payment_methods';

export const usePaymentMethods = () => {
  const api = useApi();

  const getInstallments = useCallback(({ partnerSlug, value }: GetInstallmentsRequest) => {
    return api.get<Payment.Installment[]>(`/paymentMethods/installments?partnerIdOrUrl=${partnerSlug}&value=${value}`);
  }, [api]);

  return {
    getInstallments,
  };
};
