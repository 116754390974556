import {
  useContext,
  useState,
  useEffect,
  Fragment,
} from 'react';
import { UI, Template } from '@components';
import { useReplaceThumbUrls, ThumbSizes } from '@hooks';
import { routes } from '@routes';
import { RouterContext } from '@contexts';
import { useScene } from '@api';
import { Scene } from '@types';

export function Download() {
  const { navigate } = useContext(RouterContext);
  const { getDownloadableScenes, sceneWithImagesBySceneId } = useScene();
  const { replaceThumbUrls } = useReplaceThumbUrls();

  const [sceneTabs, setSceneTabs] = useState<Array<UI.ITab>>();
  const [sceneTabsIsLoading, setSceneTabsIsLoading] = useState<boolean>(true);
  const [currentSceneId, setCurrentSceneId] = useState(0);
  const [showImageViewer, setShowImageViewer] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [scene, setScene] = useState<Scene.WithImages>();
  const [sceneIsLoading, setSceneIsLoading] = useState<boolean>(true);

  const scenePlaceholder = {
    id: 12315,
    name: 'placeholder',
    filesCount: 99,
    isActive: true,
    createdAt: new Date(),
    files: [
      {
        id: '1',
        name: 'placeholder',
        url: 'placeholder',
        createdAt: new Date(),
      },
      {
        id: '12',
        name: 'placeholder',
        url: 'placeholder',
        createdAt: new Date(),
      },
      {
        id: '123',
        name: 'placeholder',
        url: 'placeholder',
        createdAt: new Date(),
      },
      {
        id: '1234',
        name: 'placeholder',
        url: 'placeholder',
        createdAt: new Date(),
      },
    ],
  };

  async function getFolderTabs() {
    setSceneTabsIsLoading(true);

    const { data } = await getDownloadableScenes();
    setSceneTabs(
      data.scenes.map((scene) => ({
        id: scene.id,
        name: scene.name,
        handleClick: () => {
          setCurrentSceneId(scene.id);
        },
      })),
    );
    setCurrentSceneId(data.scenes[0].id);

    setSceneTabsIsLoading(false);
  }

  async function getDownloadableFiles() {
    setSceneIsLoading(true);

    setScene(scenePlaceholder);
    const { data: scene } = await sceneWithImagesBySceneId({
      sceneId: currentSceneId === 0 ? 'suggested' : currentSceneId,
      sceneQuery: {
        sceneFilter: currentSceneId === 0 ? 'suggested' : '',
        pagination: {
          skip: 0,
          take: 0,
        },
      },
    });
    setScene(undefined);
    setScene(scene);

    setSceneIsLoading(false);
  }

  useEffect(() => {
    getFolderTabs();
  }, []);

  useEffect(() => {
    getDownloadableFiles();
  }, [currentSceneId]);

  function navegateDownloadRequest() {
    if (scene) {
      navigate(routes.downloadRequest(scene.id as unknown as string));
    }
  }

  function closeImageViewer() {
    setShowImageViewer(false);
  }

  function returnToHomePage() {
    navigate(routes.home());
  }

  return (
    <>
      <Template.Header active="Downloads" />
      <main className="page__main download">
        {sceneTabs && (
          <>
            <section className="page__section">
              <div className="page__container">
                <div className="page__wrapper">
                  <Template.Heading
                    titleId="Downloads"
                    onReturnClick={returnToHomePage}
                  />
                </div>
              </div>
            </section>
            <section className="page__section page__section--grow">
              <div className="page__container">
                <div className="download__tab-actions-wrapper">
                  <div className={`download__tab-wrapper ${sceneTabsIsLoading ? 'skeleton skeleton--neutral-90' : ''}`}>
                    <UI.Tabs
                      tabs={sceneTabs}
                      currentId={currentSceneId}
                      variant={UI.TabsEnum.buttonsNeutralOutline}
                    />
                  </div>
                  <div className="download__actions-wrapper">
                    <p className={`download__counter ${sceneIsLoading ? 'skeleton skeleton--neutral-90' : ''}`}>
                      Total de Fotos:
                      {' '}
                      {scene ? scene.filesCount : 0}
                    </p>
                    <div className="download__btn-wrapper">
                      <button
                        type="button"
                        className={`download__btn btn btn--primary ${sceneIsLoading ? 'skeleton skeleton--neutral-90' : ''}`}
                        onClick={navegateDownloadRequest}
                      >
                        Baixar todas as fotos
                      </button>
                    </div>
                  </div>
                </div>
                <div className="download__grid-wrapper">
                  <div className="images-grid">
                    {
                      scene
                      && scene.files.map(({ url }, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Fragment key={index}>
                          <Template.ImageThumb
                            url={replaceThumbUrls(url, ThumbSizes.small)}
                            hiddenHeart
                            hiddenComment
                            onExpandClick={() => {
                              setCurrentIndex(index);
                              setShowImageViewer(true);
                            }}
                          />
                        </Fragment>
                      ))
                    }
                  </div>
                </div>
              </div>
            </section>
          </>
        )}
      </main>

      <Template.Footer />
      {showImageViewer && (
        <UI.ImageViewer
          hiddenHeart
          hiddenComment
          initialSlide={currentIndex}
          currentIndex={currentIndex}
          sliderImages={
            scene
              ? scene.files.map((file) => ({
                ...file,
                url: replaceThumbUrls(file.url, ThumbSizes.big),
              }))
              : []
          }
          onCloseClick={closeImageViewer}
        />
      )}
    </>
  );
}
