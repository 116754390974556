import { useRef, useContext } from 'react';
import { BannerContext, GalleryContext } from '@contexts';

export function ProjectCover() {
  const context = useContext(GalleryContext);
  const { focusPoint } = useContext(BannerContext);
  const projectCoverWrapperEl = useRef<HTMLImageElement>(null);

  return (
    <div
      className="project-cover skeleton"
      ref={projectCoverWrapperEl}
    >
      <img
        className="project-cover__img"
        src={context?.galleryCover?.jpg}
        style={{ objectPosition: `${focusPoint?.x}% ${focusPoint?.y}%` }}
        alt="Capa do Projeto"
        onLoad={() => { projectCoverWrapperEl.current?.classList.remove('skeleton'); }}
      />
    </div>
  );
}
