import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';

// eslint-disable-next-line object-curly-newline
import { Notification, Form, Template, UI } from '@components';
import { lang } from '@i18n';
import { routes } from '@routes';
import { useAuth } from '@api';

type RecoveryFormValueType = { email: string };
const recoveryFormInitialValue: RecoveryFormValueType = { email: '' };

const schema = Yup.object({
  email: Yup.string()
    .email(lang.translate(lang.keys.errors.login.email_invalid))
    .required(lang.translate(lang.keys.errors.generic.required_field, {
      path: lang.translate(lang.keys.forms.users.email),
    })),
});

export function Recovery() {
  const { recoveryPassword } = useAuth();
  const [loading, setLoading] = useState(false);
  const [hasMessage, setHasMessage] = useState<{
    type: 'error' | 'success' | 'warning';
    message: string;
  } | null>(null);

  const [buttonText, setButtonText] = useState('Enviar');
  const [linkHidden, setLinkHidden] = useState(true);

  const navigate = useNavigate();

  const handleSubmit = async ({ email }: RecoveryFormValueType) => {
    try {
      setLoading(true);

      await recoveryPassword({ email });

      setHasMessage({
        type: 'success',
        message: lang.translate(lang.keys.pages.recovery.success_submit),
      });
      setButtonText('Enviado');
      setLinkHidden(false);
    } catch (error: any) {
      if (error?.response?.status === 400) {
        setHasMessage({
          type: 'error',
          message: lang.translate(lang.keys.errors.api.generic),
        });
      } else {
        setHasMessage({
          type: 'warning',
          message: lang.translate(lang.keys.errors.api.generic),
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const onClickModal = () => {
    setHasMessage(null);
  };

  const goToLogin = () => {
    navigate(routes.login());
  };

  return (
    <Template.Page
      title={lang.translate(lang.keys.pages.recovery.title)}
      description={lang.translate(lang.keys.pages.recovery.subtitle)}
    >
      <Formik initialValues={recoveryFormInitialValue} validationSchema={schema} onSubmit={handleSubmit}>
        {({
          errors, touched, handleChange, handleBlur,
        }) => (
          <div className="recovery">
            <div className="form">
              <div className="form__grid">
                <div className="form__group">
                  <Form.Label name="email" text={lang.translate(lang.keys.forms.users.email)} />
                  <Form.Input
                    id="email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="email"
                    error={touched.email && errors.email ? errors.email : ''}
                    placeholder={lang.translate(lang.keys.forms.users.email_recovery_placeholder)}
                  />
                </div>
                <div className="login__btn-group">
                  <div className="form__btn-message">
                    <UI.Button
                      type="submit"
                      loading={loading}
                      disabled={buttonText === 'Enviado'}
                      fullwidth="w-full"
                      variant={UI.ButtonEnum.primary}
                      text={buttonText}
                    />

                    {hasMessage && (
                      <Notification.Message
                        type={hasMessage.type}
                        onClick={onClickModal}
                        message={hasMessage.message}
                      />
                    )}
                  </div>
                  <UI.Button
                    type="button"
                    loading={loading}
                    fullwidth="w-full"
                    variant={UI.ButtonEnum.primaryOutline}
                    onClick={goToLogin}
                    text="Voltar"
                  />
                  <div className="recovery__link-wrapper">
                    <div />
                    <a
                      hidden={linkHidden}
                      className="recovery__link"
                      onClick={() => {
                        setButtonText('Enviar');
                        setLinkHidden(true);
                      }}
                    >
                      {lang.translate(lang.keys.pages.recovery.resend_email)}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </Template.Page>
  );
}
