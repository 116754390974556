export class ColorScheme {
  public id: string;

  public cssModifier: string;

  public text: string;

  public background: string;

  constructor(id: string, cssModifier: string, text: string, background: string) {
    this.id = id;
    this.cssModifier = cssModifier;
    this.text = text;
    this.background = background;
  }

  public static colorsSchemes = [
    new ColorScheme('graniteGrey', 'granite-grey', '#666666', '#fff'),
    new ColorScheme('deepBlue', 'deep-blue', '#1D1D1D', '#fff'),
    new ColorScheme('deepBlue', 'deep-blue', '#385794', '#fff'),
    new ColorScheme('mayGreen', 'may-green', '#38943B', '#fff'),
    new ColorScheme('magenta', 'magenta', '#943857', '#fff'),
    new ColorScheme('metallicSunburst', 'metallic-sunburst', '#947D38', '#fff'),
  ];

  public static setSelectedColorScheme(textHex: string, backgroundHex: string): void {
    const selectedColorScheme = ColorScheme.colorsSchemes
      .find((i) => i.text.toUpperCase() === textHex.toUpperCase() && i.background.toUpperCase() === backgroundHex.toUpperCase());
    if (selectedColorScheme) {
      document.querySelector('.html')?.classList.add(`theme--${selectedColorScheme.cssModifier}`);
    }
  }
}
