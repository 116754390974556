import { ReactElement, useContext } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AuthContext } from '@contexts';
import { paths } from './map';

export interface RouteGuardProps {
  children: ReactElement
}

export type RouteGuardFn = ({ children }: RouteGuardProps) => ReactElement

export const RequireAuthGuard = ({ children }: RouteGuardProps) => {
  const location = useLocation();
  const { auth } = useContext(AuthContext);
  if (!auth.authenticated) {
    return <Navigate to={paths.login()} state={{ from: location }} replace />;
  }
  return children;
};

export const AlreadyAuthenticatedGuard = ({ children }: { children: ReactElement }) => {
  const { auth } = useContext(AuthContext);
  if (auth.authenticated) {
    return <Navigate to="../home" relative="path" />;
  }
  return children;
};
