export function calculateZoomOrigin(
  focusPointX: number,
  focusPointY: number,
): {focusPoint: { x: number; y: number }} {
  let transformOriginX = 50;
  let transformOriginY = 50;
  if (focusPointX < 0) {
    transformOriginX = Math.abs(50 * Math.abs(focusPointX) - 50);
  } else if (focusPointX >= 0) {
    transformOriginX = 50 * Math.abs(focusPointX) + 50;
  }

  if (focusPointY >= 0) {
    transformOriginY = Math.abs(50 * Math.abs(focusPointY) - 50);
  } else if (focusPointY < 0) {
    transformOriginY = 50 * Math.abs(focusPointY) + 50;
  }

  return { focusPoint: { x: transformOriginX, y: transformOriginY } };
}
