/* eslint-disable react/no-array-index-key */
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import QRCode from 'qrcode';
import { UI, Template } from '@components';
import { useFormatLocal } from '@hooks';
import { routes } from '@routes';
import { AuthContext, RouterContext } from '@contexts';
import { Payment, Product } from '@types';
import { useOrders, useUsers } from '@api';
import { GetUserInfosResponse } from '@api/types';

// const pixQrCodeImgUrl: 'https://foto-go.s3.sa-east-1.amazonaws.com/assets/images/pix.png'

export interface IOrderFinishedSummary {
  userName: string
  userEmail: string
  orderNumber: string
  navigateToOrder: () => void
  typePaymentMethod: Payment.Type
  totalValue: number
  paymentInstallments?: number
  paymentInstallmentsValue?: number
  paymentExpirationDate?: string
  paymentMethodDetails?: {
    steps: string[]
    codeBarImgUrl?: string
    buttonFn: () => void
    buttonLabel: string
    observationNote: string
  }
}

export function CheckoutFinished() {
  const { auth } = useContext(AuthContext);
  const { slugs, navigate } = useContext(RouterContext);
  const params = useParams();

  const [data, setData] = useState<{
    code: string
    url: string
    method: string
    totalValue: number
  } | null>(null);
  const { getUserInfos } = useUsers();
  const { getOrderFinished } = useOrders();
  const [user, setUser] = useState<GetUserInfosResponse | null>(null);
  const [checkoutDetails, setCheckoutDetails] = useState<Product.Summary[]>([]);
  const { formatCurrencyPtBR } = useFormatLocal();
  const [steps, setSteps] = useState<string[]>([]);
  const [pix, setPix] = useState('');
  const [showCopySuccess, setShowCopySuccess] = useState<boolean>(false);
  const [btnData, setBtnData] = useState<{
    action:() => void
    text: string
    validateMessage: string
    validateData: string
      } | null>(null);

  async function getData() {
    const { data } = await getOrderFinished({
      partnerSlug: slugs.partner,
      orderId: params.orderId as unknown as string,
    });

    let pixContent;
    if (data.paymentType === 'pix') {
      pixContent = await (await fetch(data.payment.url)).text();
    }

    const paymentUrl = pixContent || data.payment.url;

    setData({
      code: data.payment.goImageId as unknown as string,
      url: paymentUrl,
      method: data.paymentType as unknown as string,
      totalValue: data.totalValue,
    });

    const { data: dataUser } = await getUserInfos({ userId: auth.userId });
    setUser(dataUser);
  }

  useEffect(() => {
    getData();
  }, []);

  const openBilletPdf = () => {
    window.open(data?.url, '_blank')?.focus();
  };

  const copyPixCode = () => {
    navigator.clipboard.writeText(data?.url as string);
    setShowCopySuccess(true);
  };

  const stepsPix = [
    'Abra o app ou banco de sua preferência e escolha a opção pagar com código Pix ”copia e cola", ou QR Code.',
    'Copie e cole o código, ou escaneie o QR Code abaixo com a câmera do seu celular.',
    'Confira todas as informações e autorize o pagamento.',
    'Você receberá a confirmação do pagamento no seu e-mail. E pronto! ',
  ];

  const stepsBillet = [
    'Clique em "Gerar boleto” para visualizar o boleto.',
    'Confira as informações e faça o pagamento através do código de barras.',
    'Para visualizá-lo novamente, faça download ou acesse através de seu email.',
    'Você receberá a confirmação do pagamento no seu e-mail. E pronto!',
  ];

  useEffect(() => {
    switch (data?.method) {
      case 'pix':
        setSteps(stepsPix);
        QRCode.toDataURL(
          data.url,
          { color: { dark: '#000000', light: '#ffffffff' } },
          (err, url) => {
            setPix(url);
          },
        );
        setBtnData({
          action: copyPixCode,
          text: 'Copiar código PIX',
          validateMessage: 'Validade do QR Code',
          validateData: moment().add(1, 'd').format('DD/MM/YYYY HH:mm').toString(),
        });
        break;
      case 'bolbradesco':
        setSteps(stepsBillet);
        setBtnData({
          action: openBilletPdf,
          text: 'Gerar Boleto',
          validateMessage: 'Vencimento do Boleto',
          validateData: moment().add(3, 'd').format('DD/MM/YYYY').toString(),
        });
        break;
      default:
        break;
    }
  }, [data]);

  const onClickOrderDetails = () => {
    if (data?.code) {
      navigate(routes.orderDetail(data?.code));
    }
  };

  const onClickHome = () => {
    navigate(routes.home());
  };

  return (
    <>
      <Template.Header />
      {user && checkoutDetails && (
        <main className="page__main">
          <section className="page__section checkout-finished">
            <div className="page__container">
              <div className="checkout-finished__flex-container">
                <div className="checkout-finished__heading-wrapper">
                  <Template.Heading
                    titleId="Checkout"
                    hasReturnButton={false}
                  />
                </div>
                <div className="checkout-finished__content">
                  <div className="checkout-finished__common-infos">
                    <div className="checkout-finished__container checkout-finished__container--resume">
                      <div className="checkout-finished__group">
                        <p className="checkout-finished__text">
                          Olá,
                          {' '}
                          <b>{user.user.name}</b>
                        </p>
                        <p className="checkout-finished__text">
                          {data?.method === 'PIX' && (
                            <>
                              O Código gerado
                              {' '}
                              <b>tem validade de 24 horas</b>
                              ,
                              <br />
                              após este prazo o pedido será cancelado.
                            </>
                          )}

                          {data?.method === 'bolbradesco' && (
                            <>
                              O prazo de pagamento é de
                              <br />
                              até
                              {' '}
                              <b>2 dias úteis após ser efetuado</b>
                              .
                            </>
                          )}

                          {data?.method === 'credit_card' && (
                            <>
                              Seu pagamento está sendo processado,
                              <br />
                              {' '}
                              isso pode levar
                              {' '}
                              <b>até 20 minutos</b>
                              .
                            </>
                          )}
                        </p>
                        <p className="checkout-finished__text">
                          Você receberá todos os dados da compra pelo email
                          <b>
                            {' '}
                            {user.user.email}
                          </b>
                          .
                        </p>
                      </div>
                      <div className="checkout-finished__line" />
                      <div className="checkout-finished__group">
                        <div className="checkout-finished__info-group">
                          <div className="checkout-finished__info">
                            <span className="checkout-finished__text">Número do pedido</span>
                            <span className="checkout-finished__text">
                              <b>{data?.code}</b>
                            </span>
                          </div>
                          <div className="checkout-finished__info">
                            <span className="checkout-finished__text">
                              Valor total (produtos + frete)
                            </span>
                            <span className="checkout-finished__text">
                              <b>{formatCurrencyPtBR(data?.totalValue || 0)}</b>
                              {' '}
                              {/* checkoutDetails?.totalValue as number */}
                              {data?.method === 'credit_card' && (
                                <b>
                                  {' '}
                                  em
                                  {' '}
                                  1
                                  { /* order.installmentsQuantity */}
                                  x
                                </b>
                              )}
                            </span>
                          </div>
                          {data?.method !== 'credit_card' && (
                            <div className="checkout-finished__info">
                              <span className="checkout-finished__text">
                                {btnData?.validateMessage}
                              </span>
                              <span className="checkout-finished__text">
                                <b>{btnData?.validateData}</b>
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="checkout-finished__group">
                        <div className="checkout-finished__btn-group">
                          <UI.Button
                            fullwidth="w-full"
                            text="Acompanhar pedido"
                            type="button"
                            variant={UI.ButtonEnum.primaryOutlineSmall}
                            onClick={onClickOrderDetails}
                            loading={false}
                          />
                          <UI.Button
                            fullwidth="w-full"
                            text="Voltar para Home"
                            type="button"
                            variant={UI.ButtonEnum.neutralOutlineSmall}
                            onClick={onClickHome}
                            loading={false}
                          />
                        </div>
                      </div>
                    </div>

                    {data?.method !== 'credit_card' && (
                    <div className="checkout-finished__container">
                      <div className="checkout-finished__group">
                        <div className={`payment-details ${data?.method === 'pix' ? 'payment-details--column-reverse' : ''}`}>
                          <ul className="payment-details__steps">
                            {steps && steps.map((step, index) => (
                              <li key={index} className="payment-details__step">
                                <span className="payment-details__step-counter" />
                                <p className="checkout-finished__text checkout-finished__text--small checkout-finished__text--align-left">
                                  {step}
                                </p>
                              </li>
                            ))}
                          </ul>

                          <div className="payment-details__barcode-group  checkout-finished__group--align-start">
                            {data?.method === 'pix' && (
                              <div className="payment-details__image-wrapper payment-details__image-wrapper--square">
                                <img className="payment-details__img" src={pix} alt="Imagem PIX" />
                              </div>
                            )}
                            <div className="checkout-finished__group checkout-finished__group--padding-top-bottom">

                              <div className="payment-details__btn-group">
                                <UI.Button
                                  type="button"
                                  onClick={btnData?.action}
                                  loading={false}
                                  variant={UI.ButtonEnum.primary}
                                  fullwidth="w-full"
                                  text={btnData?.text}
                                />
                              </div>
                              {data?.method === 'pix' && (
                                <p className={`checkout-finished__text checkout-finished__text--success checkout-finished__text--animate ${showCopySuccess ? '' : 'checkout-finished__text--hidden'}`}>
                                  O Código Pix foi copiado!
                                </p>
                              )}
                              <p className="checkout-finished__text">
                                <b>
                                  Se o pagamento não for confirmado, não se preocupe. O pedido
                                  será cancelado automaticamente.
                                </b>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    )}

                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      )}
      <Template.Footer />
    </>
  );
}
