/* eslint-disable no-unused-vars */
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';

export enum ShowContentSizeEnum {
  sm = 'form__icon--sm',
  lg = 'form__icon--lg',
}

interface ShowContentProps {
  show?: string;
  size?: ShowContentSizeEnum;
  onClick: () => void;
}

export function ShowContent({ show, size, onClick }: ShowContentProps) {
  return (
    <div className="form__btn-icon">
      {show ? (
        <AiOutlineEyeInvisible
          onClick={onClick}
          className={`form__icon form__icon--lg ${size}`}
        />
      ) : (
        <AiOutlineEye
          onClick={onClick}
          className={`form__icon form__icon--lg ${size}`}
        />
      )}
    </div>
  );
}
