import { useCallback } from 'react';
import { Project } from '@types';
import { useApi } from './api';

export const useGallery = () => {
  const api = useApi();

  const getProjectBanner = useCallback(() => api.get<Project.Banner>('/galleries/banner'), [api]);

  return {
    getProjectBanner,
  };
};
