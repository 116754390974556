/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-array-index-key */
import {
  MdOutlineCheckCircleOutline,
  MdOutlineQueryBuilder,
  MdOutlineBlock,
} from 'react-icons/md';
import { useFormatLocal } from '@hooks';
import { Album } from '@types';

export interface IAlbumApprovalResume {
  name: string;
  status: Album.Status;
  expirationDate?: Date;
  price?: number;
}

interface AlbumApprovalResumeProps {
  albumApprovalResume: IAlbumApprovalResume;
  isLoading: boolean;
}

const statusUI = [
  {
    name: 'Pending',
    label: 'Não iniciado',
    Icon: MdOutlineQueryBuilder,
  },
  {
    name: 'InProgress',
    label: 'Em andamento',
    Icon: MdOutlineQueryBuilder,
  },
  {
    name: 'Finished',
    label: 'Concluído',
    Icon: MdOutlineCheckCircleOutline,
  },
  {
    name: 'Expired',
    label: 'Expirado',
    Icon: MdOutlineBlock,
  },
];

export function AlbumApprovalResume({
  albumApprovalResume,
  isLoading,
}: AlbumApprovalResumeProps) {
  const { formatCurrencyPtBR, formatLocalDate } = useFormatLocal();

  return (
    <div className={`approval-resume approval-resume--${albumApprovalResume.status.toLowerCase()}`}>
      <div className="approval-resume__title-status-wrapper">
        <h5 className={`approval-resume__title ${isLoading ? 'skeleton' : ''}`}>{albumApprovalResume.name}</h5>
        {statusUI.map(({ name, label, Icon }, index) => {
          if (name === albumApprovalResume.status) {
            return (
              <div key={index} className={`approval-resume__status ${isLoading ? 'skeleton' : ''}`}>
                <Icon className="approval-resume__icon" />
                <span className="approval-resume__text approval-resume__text--less-emphasys">
                  {label}
                </span>
              </div>
            );
          }
        })}
      </div>
      <div className="approval-resume__infos-wrapper">
        {albumApprovalResume.expirationDate && (
          <div className="approval-resume__info-outter">
            <div className="approval-resume__info-group">
              <span className="approval-resume__title approval-resume__title--less-emphasys">
                Prazo
              </span>
              <span className={`approval-resume__text ${isLoading ? 'skeleton' : ''}`}>
                {formatLocalDate(albumApprovalResume.expirationDate)}
              </span>
            </div>
          </div>
        )}
        {albumApprovalResume.price && (
          <div className="approval-resume__info-outter">
            <div className="approval-resume__info-group">
              <span className="approval-resume__title approval-resume__title--less-emphasys">
                Valor
              </span>
              <span className={`approval-resume__text ${isLoading ? 'skeleton' : ''}`}>
                {formatCurrencyPtBR(albumApprovalResume.price)}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
