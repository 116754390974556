/* eslint-disable no-param-reassign */
import moment from 'moment';
import { Template } from '@components';

export class SelectionProgress {
  public static handleSelectionStatusData(selectionSummary: Array<Template.IAlbumSummary>, buttonLabel: string): Array<Template.ISelectionProgressCardSummary> {
    let selectionsProgressCardSummary = [];

    selectionsProgressCardSummary = selectionSummary.map((el: any) => {
      el.createdAt = moment(el.createdAt).local().toDate();
      el.expirationDate = el.expirationDate ? moment(el.expirationDate).local().toDate() : undefined;
      return {
        id: el.id,
        title: el.name,
        leftSubtitle: el.expirationDate ? `Prazo: ${moment(el.expirationDate).format('DD/MM/YYYY')}` : 'Sem prazo definido',
        rightSubtitle: SelectionProgress.daysLeft(el.expirationDate),
        currentValue: SelectionProgress.daysCurrentValue(el.createdAt),
        totalValue: SelectionProgress.daysTotalValue(el.createdAt, el.expirationDate),
        status: el.status,
        buttonLabel,
        handleButtonClick: el.handleButtonClick,
      };
    });

    return selectionsProgressCardSummary;
  }

  public static daysDiffAsNumber(startingDate: Date, finalDate: Date): number {
    const momentStartingDate = moment(startingDate);
    const momentFinalDate = moment(finalDate);
    return Math.round((moment.duration(momentFinalDate.diff(momentStartingDate))).asDays());
  }

  public static daysTotalValue(startingDate: Date, finalDate: Date | undefined): number {
    if (!finalDate) { return 0; }

    return this.daysDiffAsNumber(startingDate, finalDate);
  }

  public static daysLeft(expirationDate: Date | undefined): string {
    if (!expirationDate) {
      return '';
    }

    const daysLeft = this.daysDiffAsNumber(new Date(), expirationDate);

    if (daysLeft === 0) { return 'Último dia'; }
    if (daysLeft < 0) { return 'Prazo expirado'; }

    return `${daysLeft} Dias restantes`;
  }

  public static daysCurrentValue(createAt: Date): number {
    if (!createAt) { return 0; }

    const currentValue = this.daysDiffAsNumber(createAt, new Date());

    if (currentValue < 0) { return 0; }

    return currentValue;
  }
}
