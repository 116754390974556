/* eslint-disable arrow-body-style */
import { useCallback } from 'react';
import { Order } from '@types';
import { useApi } from './api';
import {
  GetOrderDetailsRequest,
  GetOrderFinishedRequest,
  GetOrderFinishedResponse,
  GetOrdersListRequest,
  GetOrdersListResponse,
  ProcessOrderRequest,
} from './types/order';

export const useOrders = () => {
  const api = useApi();

  const getOrdersList = useCallback(({ partnerSlug }: GetOrdersListRequest) => {
    return api.get<GetOrdersListResponse>(`orders/${partnerSlug}/listOrders`);
  }, [api]);

  const getOrderDetails = useCallback(({ partnerSlug, orderId }: GetOrderDetailsRequest) => {
    return api.get<Order.Details>(`orders/${partnerSlug}/${orderId}/orderDetails`);
  }, [api]);

  const processOrder = useCallback((request: ProcessOrderRequest) => {
    return api.post<Order.Processed>('orders', request);
  }, [api]);

  const getOrderFinished = useCallback(({ partnerSlug, orderId }: GetOrderFinishedRequest) => {
    return api.get<GetOrderFinishedResponse>(`orders/${partnerSlug}/${orderId}/orderFinished`);
  }, [api]);

  return {
    getOrdersList,
    getOrderDetails,
    processOrder,
    getOrderFinished,
  };
};
