interface LabelProps {
  name: string;
  text: string;
  classes?: string;
}

export function Label({ name, text, classes = '' }: LabelProps) {
  return (
    <label htmlFor={name} className={`form__label ${classes}`}>
      {text}
    </label>
  );
}
