/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
import React, {
  useContext,
  useEffect,
  useState,
  Fragment,
} from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { MdOutlineAttachMoney, MdOutlineDone, MdOutlineQuestionMark } from 'react-icons/md';
import { RouterContext } from '@contexts';
import {
  useGallerySelection,
  useProducts,
  useScene,
  useShoppingCart,
} from '@api';
import { Gallery } from '@types';
import { ProductId } from '@api/types';
import { Template, UI } from '@components';
import { routes } from '@routes';

export function SelectionReview() {
  const { navigate } = useContext(RouterContext);
  const params = useParams();
  const { finishSelection, getSelectionInfo, getSelectionSceneFiles } = useGallerySelection();
  const { getScenesList } = useScene();
  const { getProductById } = useProducts();
  const { clearShoppingCartAndAddItem } = useShoppingCart();

  const [selectionResume, setSelectionResume] = useState<Template.ISelectionResume>();
  const [selectionFiles, setSelectionFiles] = useState<Array<Gallery.ImageThumb>>();
  const [showRedirectToCheckoutModal, setShowRedirectToCheckoutModal] = useState(false);
  const [showConfirmFinishSelectionModal, setShowConfirmFinishSelectionModal] = useState(false);
  const [showConfirmFinishSelectionExtrasPhotosNoPriceModal, setShowConfirmFinishSelectionExtrasPhotosNoPriceModal] = useState(false);
  const [showSelectionFinishedModal, setShowSelectionFinishedModal] = useState(false);
  const [isFinishingSelection, setIsFinishingSelection] = useState<boolean>(false);
  const [isPreparingShoppingCart, setIsPreparingShoppingCart] = useState<boolean>(false);

  async function getSelectionResume() {
    const { data } = await getSelectionInfo({
      selectionId: params.selectionId || '',
    });

    setSelectionResume({
      title: data.name,
      minFiles: data.minFiles,
      selectedFilesCount: data.selectedFilesCount,
      expirationDate: data.expirationDate,
      price: data.price,
      totalValue:
        data.selectedFilesCount > data.minFiles
          ? (data.selectedFilesCount - data.minFiles) * data.price
          : 0,
      selectionStatus: data.status,
    });
  }

  async function getAllSelectionFiles() {
    const { data } = await getScenesList();

    const selectionFiles: Array<Gallery.ImageThumb> = [];
    for (const scene of data) {
      if (scene.name !== 'suggested') {
        const { data: sceneWithImage } = await getSelectionSceneFiles({
          sceneId: `${scene.id}`,
          selectionId: params.selectionId || '',
          sceneQuery: {
            sceneFilter: '',
            pagination: {
              skip: 0,
              take: 0,
            },
          },
        });

        sceneWithImage.files
          .filter((file) => file.isSelected)
          .forEach((s) => selectionFiles.push(s));
      }
    }
    setSelectionFiles(selectionFiles);
  }

  useEffect(() => {
    getSelectionResume();
    getAllSelectionFiles();
  }, [params.selectionId]);

  async function onFinishClick() {
    if (selectionResume && selectionResume.minFiles && (selectionResume.selectedFilesCount === selectionResume.minFiles)) {
      setShowConfirmFinishSelectionModal(true);
      return;
    }

    if (selectionResume && selectionResume.minFiles && !selectionResume?.price && (selectionResume.selectedFilesCount > selectionResume.minFiles)) {
      setShowConfirmFinishSelectionExtrasPhotosNoPriceModal(true);
      return;
    }

    if (selectionResume && selectionResume.minFiles && selectionResume.price && (selectionResume.selectedFilesCount > selectionResume.minFiles)) {
      setShowRedirectToCheckoutModal(true);
      return;
    }

    if (selectionResume && !selectionResume?.minFiles && !selectionResume?.price && selectionResume.selectedFilesCount > 0) {
      setShowConfirmFinishSelectionModal(true);
    }
  }

  async function handleFinishSelection() {
    setIsFinishingSelection(true);
    try {
      const { data } = await finishSelection({
        selectionId: params.selectionId || '',
      });
      if (data) {
        setShowSelectionFinishedModal(true);
      }
    } catch (error) {
      console.log('error', error);
    }
    setIsFinishingSelection(false);
  }

  async function goToCheckout() {
    setIsPreparingShoppingCart(true);

    if (selectionResume && selectionResume.minFiles
      && (selectionResume?.selectedFilesCount > selectionResume?.minFiles)) {
      setShowRedirectToCheckoutModal(true);

      const { data: productExtraPhoto } = await getProductById({ productId: ProductId.extraPhotoProof });

      await clearShoppingCartAndAddItem({
        id: uuidv4(),
        pages: 0,
        quantity: selectionResume.selectedFilesCount - selectionResume.minFiles,
        productDescription: productExtraPhoto.description,
        productId: productExtraPhoto.id,
        selectionId: params.selectionId || '',
        available: true,
        productName: productExtraPhoto.name,
        optionId: productExtraPhoto.options[0].id,
        unitPrice: selectionResume.price as number,
        files: [],
        clientData: { userAgent: window.navigator.userAgent },
        uploadData: { startDate: new Date(), endDate: new Date(), version: 1 },
      });

      navigate(routes.checkout());
    }

    setIsPreparingShoppingCart(false);
  }

  function returnToSelectionPage() {
    navigate(routes.selection());
  }

  return (
    <>
      <Template.Header active="Photo" />
      <main className="page__main selection-review">
        <section className="page__section">
          <div className="page__container">
            <div className="page__component-wrapper">
              <Template.Heading
                titleId="Selection_review"
                onReturnClick={returnToSelectionPage}
              />
            </div>
          </div>
        </section>
        <section className="page__section">
          <div className="page__container">
            <div className="selection-review__wrapper page__component-wrapper page__component-wrapper--row">
              <div className="selection-review__image-grid-wrapper">
                <div className="images-grid">
                  {/* ADD STYLE FOR SELECTED IMAGE THUMB (MAURICIO) */}
                  {selectionFiles
                    && selectionFiles.map(({ url, isSelected }, index) => (
                      <Fragment key={index}>
                        <Template.ImageThumb
                          url={url
                            .replace('[SIZE]', '256')
                            .replace('[FORMAT]', 'jpg')}
                          hiddenHeart
                          hiddenExpand
                          hiddenComment
                          isSelected={isSelected}
                        />
                      </Fragment>
                    ))}
                </div>
              </div>
              <div className="selection-review__resume-wrapper">
                <div className="selection-review__resume-container">
                  {selectionResume && (
                    <Template.SelectionResume
                      currentId={params.selectionId || ''}
                      selectionResumeData={selectionResume}
                      reviewSelectionButtons={[
                        {
                          label: 'Finalizar Seleção',
                          style: UI.ButtonEnum.primary,
                          onClick: () => onFinishClick(),
                        },
                        {
                          label: 'Selecionar mais fotos',
                          style: UI.ButtonEnum.primaryOutline,
                          onClick: () => returnToSelectionPage(),
                        },
                      ]}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Template.Footer />

      <UI.FullscreenLoader
        textFristLine={isFinishingSelection ? 'Aguarde, estamos finalizando sua seleção.' : 'Aguarde, você será redirecionado a Tela de Pagamento em breve.'}
        active={isFinishingSelection || isPreparingShoppingCart}
      />

      <UI.Modal
        isOpen={showRedirectToCheckoutModal}
        maxWidth={560}
        header={{
          title: 'Fotos extras',
          Icon: MdOutlineAttachMoney,
        }}
        contentEl={
          (
            <>
              <p>
                Você selecionou
                <b>
                  {' '}
                  {selectionResume && selectionResume.minFiles && (selectionResume.selectedFilesCount - selectionResume.minFiles)}
                  {' '}
                  {(selectionResume && selectionResume.minFiles && (selectionResume.selectedFilesCount - selectionResume.minFiles)) === 1 ? 'Foto Extra' : 'Fotos Extras'}
                  {' '}
                </b>
              </p>
              <p>
                Para finalizar a seleção, realize o pagamento
                {' '}
                {(selectionResume && selectionResume.minFiles && (selectionResume.selectedFilesCount - selectionResume.minFiles)) === 1 ? 'dessa foto' : 'dessas fotos'}
                .
              </p>
            </>
          ) as React.ReactNode
        }
        buttonGroupDirection="column"
        closeable={false}
        onClose={() => false}
        positiveButton={{
          label: 'Pagar',
          onClick: () => {
            setShowRedirectToCheckoutModal(false);
            goToCheckout();
          },
          variant: UI.ButtonEnum.primary,
        }}
      />

      <UI.Modal
        isOpen={showConfirmFinishSelectionModal}
        maxWidth={560}
        header={{
          title: 'Finalizar seleção',
          Icon: MdOutlineQuestionMark,
        }}
        contentEl={
          (
            <>
              <p>
                Após finalizar a seleção, a mesma&nbsp;
                <b>
                  não poderá ser alterada
                </b>
                !&nbsp;Deseja finalizar?
              </p>
              <p>
                Deseja finalizar a seleção? Este processo é irreversível.
              </p>
            </>
          ) as React.ReactNode
        }
        buttonGroupDirection="column"
        closeable={false}
        onClose={() => false}
        positiveButton={{
          label: 'Sim. Já escolhi as fotos!',
          onClick() {
            handleFinishSelection();
            setShowConfirmFinishSelectionModal(false);
          },
          variant: UI.ButtonEnum.primary,
        }}
        negativeButton={{
          label: 'Não. Quero mudar algo!',
          onClick() {
            setShowConfirmFinishSelectionModal(false);
          },
          variant: UI.ButtonEnum.errorOutline,
        }}
      />

      <UI.Modal
        isOpen={showConfirmFinishSelectionExtrasPhotosNoPriceModal}
        maxWidth={560}
        header={{
          title: 'Finalizar seleção - Fotos Extra',
          Icon: MdOutlineQuestionMark,
        }}
        contentEl={
          (
            <>
              <p>
                Você selecionou
                <b>
                  {' '}
                  {selectionResume && selectionResume.minFiles && (selectionResume.selectedFilesCount - selectionResume.minFiles)}
                  {' '}
                  {(selectionResume && selectionResume.minFiles && (selectionResume.selectedFilesCount - selectionResume.minFiles)) === 1 ? 'Foto Extra' : 'Fotos Extras'}
                </b>
                .
              </p>
              <p>
                Sua seleção poderá acarretar em custos extras com o fotógrafo. Negocie diretamente com o mesmo.
              </p>
              <p>
                Deseja finalizar a seleção? Este processo é irreversível.
              </p>
            </>
          ) as React.ReactNode
        }
        buttonGroupDirection="column"
        closeable={false}
        onClose={() => false}
        positiveButton={{
          label: 'Sim. Já escolhi as fotos!',
          onClick() {
            handleFinishSelection();
            setShowConfirmFinishSelectionExtrasPhotosNoPriceModal(false);
          },
          variant: UI.ButtonEnum.primary,
        }}
        negativeButton={{
          label: 'Não. Quero mudar algo!',
          onClick() {
            setShowConfirmFinishSelectionExtrasPhotosNoPriceModal(false);
          },
          variant: UI.ButtonEnum.errorOutline,
        }}
      />

      <UI.Modal
        isOpen={showSelectionFinishedModal}
        maxWidth={560}
        header={{
          title: 'Seleção finalizada com Sucesso!',
          Icon: MdOutlineDone,
        }}
        contentEl={
          (
            <>
              <p>
                <b>
                  Parabéns!&nbsp;
                </b>
                Sua seleção foi finalizada.
              </p>
              <p>
                Tanto você quanto o fotográfo receberão e-mails de confirmação.
              </p>
            </>
          ) as React.ReactNode
        }
        buttonGroupDirection="column"
        closeable={false}
        onClose={() => false}
        positiveButton={{
          label: 'Voltar para Home',
          onClick() {
            setShowSelectionFinishedModal(false);
            navigate(routes.home());
          },
          variant: UI.ButtonEnum.primary,
        }}
      />
    </>
  );
}
