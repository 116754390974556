/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import {
  MdOutlineChevronLeft,
  MdOutlineChevronRight,
  MdOutlineAttachMoney,
  MdOutlineCheckCircleOutline,
} from 'react-icons/md';
import { UI, Template } from '@components';
import { useFrameAlbumViewer } from '@hooks';
import { routes } from '@routes';
import { AuthContext, GalleryContext, RouterContext } from '@contexts';
import { useGalleriesAlbums, useShoppingCart } from '@api';
import { Album } from '@types';

export const AlbumApproval = () => {
  const params = useParams();
  const { navigate } = useContext(RouterContext);
  const { auth } = useContext(AuthContext);
  const context = useContext(GalleryContext);
  const {
    approveAlbum,
    getAlbumDetails,
    getAlbumPageComments,
    addAlbumPageComment,
  } = useGalleriesAlbums();
  const { clearShoppingCartAndAddItem } = useShoppingCart();
  const [albumDetails, setAlbumDetails] = useState<Album.Detailed>();
  const [albumResume, setAlbumResume] = useState<Template.IAlbumApprovalResume>();
  const [albumResumeIsLoading, setAlbumResumeIsLoading] = useState<boolean>(true);
  const [albumComments, setAlbumComments] = useState<Template.IComments>();
  const [albumCommentsIsLoading, setAlbumCommentsIsLoading] = useState<boolean>(true);
  const [showNavigateToCheckoutModal, setShowNavigateToCheckoutModal] = useState<boolean>(false);
  const [navigateToCheckout, setNavigateToCheckout] = useState<boolean>(false);
  const [showFinishAlbumApprovalModal, setShowFinishAlbumApprovalModal] = useState<boolean>(false);
  const [showFinishAlbumSuccesslModal, setShowFinishAlbumSuccesslModal] = useState<boolean>(false);
  const [finishAlbumApproval, setFinishAlbumApproval] = useState<boolean>(false);
  const [finishAlbumApprovalIsLoading, setFinishAlbumApprovalIsLoading] = useState<boolean>(false);

  const iframeEl = useRef<HTMLIFrameElement>(null);
  const iframeWrapperEl = useRef<HTMLIFrameElement>(null);
  const iframeOverlayEl = useRef<HTMLIFrameElement>(null);
  const {
    nextAlbumPage, backAlbumPage, firstPage, lastPage,
  } = useFrameAlbumViewer(iframeEl);

  const albumResumePlaceholder: Template.IAlbumApprovalResume = {
    name: 'placeholder',
    status: 'Pending',
    expirationDate: new Date(),
    price: 999,
  };

  const modalNavigateToCheckoutBodyEl: React.ReactNode = (
    <p>Para finaliza a aprovação do álbum voce precisa realizar o pagamento. Deseja continuar para o checkout?</p>
  );

  const modalFinishAlbumApprovalEl: React.ReactNode = (
    <p>
      Após aprovar o álbum, o mesmo será finalizado e
      {' '}
      <b>não poderá ser alterado</b>
      . Deseja continuar?
    </p>
  );

  const modalFinishAlbumSuccessEl: React.ReactNode = (
    <p>
      Parabéns! Você acabou de aprovar este álbum com sucesso.
      {' '}
      <b>não poderá ser alterado</b>
      . Deseja continuar?
    </p>
  );

  async function getAlbum() {
    setAlbumResumeIsLoading(true);
    setAlbumResume(albumResumePlaceholder);

    const { data } = await getAlbumDetails({
      galleryId: context?.galleryId || '',
      partnerId: context?.partnerId || '',
      userId: auth.userId || '',
      albumId: params.albumId || '',
    });

    setAlbumDetails(data);

    setAlbumResume(undefined);
    setAlbumResume({
      name: data.name,
      status: data.status,
      expirationDate: data?.expirationDate,
      price: data.product?.price,
    });

    setAlbumResumeIsLoading(false);
  }

  async function getAlbumComments() {
    setAlbumCommentsIsLoading(true);

    try {
      const { data } = await getAlbumPageComments({
        galleryId: context?.galleryId || '',
        partnerId: context?.partnerId || '',
        albumId: params.albumId as string,
        page: (
          firstPage === undefined
            ? lastPage : lastPage
              ? `${firstPage} - ${lastPage}` : firstPage
            ) as string,
      });

      setAlbumComments({
        productId: params.albumId as string,
        fileComments: data,
      });
    } catch (error) {
      console.log('error', error);
    }

    setAlbumCommentsIsLoading(false);
  }

  async function addAlbumComment(productId: string, text: string = '') {
    let storeCurrentState;
    setAlbumComments((currentState) => {
      storeCurrentState = JSON.parse(JSON.stringify(currentState));
      currentState?.fileComments.push({
        text,
        date: new Date(),
        isOwn: true,
      });
      return currentState;
    });

    try {
      await addAlbumPageComment({
        galleryId: context?.galleryId || '',
        partnerId: context?.partnerId || '',
        albumId: productId,
        page: (lastPage ? `${firstPage} - ${lastPage}` : firstPage) as string,
        text,
      });
    } catch (err) {
      setAlbumComments(undefined);
      setAlbumComments(storeCurrentState);
    }
  }

  async function handleFinishAlbumApprovalModals() {
    if (albumDetails?.product?.price !== undefined) {
      setShowNavigateToCheckoutModal(true);
      return;
    }

    setShowFinishAlbumApprovalModal(true);
  }

  async function navigateToCheckoutFn(): Promise<void> {
    if (albumDetails?.product?.price) {
      await clearShoppingCartAndAddItem({
        id: uuidv4(),
        pages: 0,
        quantity: 1,
        productDescription: albumDetails.name,
        productId: albumDetails.product.id,
        available: albumDetails.product.active,
        productName: albumDetails.product.name,
        optionId: albumDetails.product.optionsIds[0],
        productImageUrl: albumDetails.product.imageUrl,
        unitPrice: albumDetails.product.price,
        files: [],
        clientData: { userAgent: window.navigator.userAgent },
        uploadData: { startDate: new Date(), endDate: new Date(), version: 1 },
      });

      navigate(routes.checkout());
    }
  }

  async function approveAlbumFn(): Promise<void> {
    setFinishAlbumApprovalIsLoading(true);

    try {
      await approveAlbum({
        galleryId: context?.galleryId as string,
        partnerId: context?.partnerId as string,
        userId: auth.userId as string,
        albumId: params.albumId as string,
      });
      if (albumDetails) {
        setAlbumDetails({
          ...albumDetails,
          status: 'Finished',
        });
      }
      setFinishAlbumApprovalIsLoading(false);
      setShowFinishAlbumSuccesslModal(true);
    } catch (err) {
      console.log(err);
      setFinishAlbumApprovalIsLoading(false);
    }
  }

  useEffect(() => {
    if (finishAlbumApproval === true) {
      approveAlbumFn();
    }
  }, [finishAlbumApproval]);

  useEffect(() => {
    if (navigateToCheckout === true) {
      navigateToCheckoutFn();
    }
  }, [navigateToCheckout]);

  useEffect(() => {
    getAlbum();
  }, [params.albumId]);

  useEffect(() => {
    getAlbumComments();
  }, [albumDetails, firstPage, lastPage]);

  function returnToAlbumsPage() {
    navigate(routes.albums());
  }

  return (
    <>
      <Template.Header active="Albums" />
      <main className="page__main">
        <section className="page__section album-approval">
          <div className="page__container album-approval__container">
            <div className="page__wrapper album-approval__heading-wrapper">
              <Template.Heading
                titleId="Album_approval"
                onReturnClick={returnToAlbumsPage}
              />
            </div>
            <div className="album-approval__content">
              <div className="album-approval__column">
                <div className="album-approval__row album-approval__row--grow-vertical">
                  <div
                    ref={iframeWrapperEl}
                    className="album-approval__iframe-wrapper skeleton"
                  >
                    {albumDetails && (
                      <>
                        <div
                          ref={iframeOverlayEl}
                          className="album-approval__iframe-overlay album-approval__iframe-overlay--hidden"
                          onClick={() => iframeOverlayEl.current?.classList.add('album-approval__iframe-overlay--hidden')}
                        >
                          Clique aqui para interagir com o Ábum 3D
                        </div>
                        <iframe
                          title="album"
                          ref={iframeEl}
                          className="album-approval__iframe"
                          src={albumDetails.viewerUrl}
                          onLoad={() => {
                            iframeWrapperEl.current?.classList.remove('skeleton');
                            iframeOverlayEl.current?.classList.remove('album-approval__iframe-overlay--hidden');
                          }}
                        />
                      </>
                    )}
                  </div>
                  <div className="album-approval__column album-approval__column--resume-chat-wrapper">
                    <div className="album-approval__resume-wrapper">
                      {albumResume && (
                        <Template.AlbumApprovalResume
                          albumApprovalResume={albumResume}
                          isLoading={albumResumeIsLoading}
                        />
                      )}
                    </div>
                    <div className="album-approval__chat-wrapper">
                      <Template.CommentsChat
                        variant={Template.CommentsChatEnum.albumApproval}
                        addComment={addAlbumComment}
                        comments={albumComments}
                        isLoading={albumCommentsIsLoading}
                        page={
                          lastPage == 0
                            ? 'Capa'
                            : lastPage
                              ? `Páginas ${firstPage} - ${lastPage}`
                              : 'Contracapa'
                        }
                        disableSendMessage={albumDetails?.status === 'Finished' || albumDetails?.status === 'Expired'}
                      />
                    </div>
                  </div>
                </div>
                <div className="album-approval__row album-approval__row--bg-100">
                  <div className="album-approval__iframe-controls">
                    <UI.Button
                      type="button"
                      loading={false}
                      classes="album-approval__arrow-btn album-approval__arrow-btn--left"
                      disabled={lastPage == 0}
                      onClick={backAlbumPage}
                      text={
                        <MdOutlineChevronLeft className="album-approval__arrow-icon" />
                      }
                    />
                    <div className="album-approval__pages-count">
                      <span className="album-approval__pages-span">
                        {lastPage == 0
                          ? 'Capa'
                          : lastPage
                            ? `Páginas ${firstPage} - ${lastPage}`
                            : 'Contracapa'}
                      </span>
                    </div>
                    <UI.Button
                      type="button"
                      loading={false}
                      classes="album-approval__arrow-btn album-approval__arrow-btn--right"
                      disabled={lastPage === undefined}
                      onClick={nextAlbumPage}
                      text={
                        <MdOutlineChevronRight className="album-approval__arrow-icon" />
                      }
                    />
                  </div>
                  <div className="album-approval__finish-btn-wrapper">
                    <UI.Button
                      type="button"
                      variant={UI.ButtonEnum.primary}
                      classes="album-approval__finish-btn"
                      fullwidth="w-full"
                      disabled={
                        albumDetails?.status === 'Expired'
                        || albumDetails?.status === 'Finished'
                        || (albumResumeIsLoading)
                      }
                      loading={finishAlbumApprovalIsLoading}
                      onClick={handleFinishAlbumApprovalModals}
                      text={
                        albumDetails?.status === 'Finished'
                          ? 'Álbum Aprovado'
                          : albumDetails?.status === 'Expired'
                            ? 'Prazo expirado'
                            : albumDetails?.product?.price !== undefined
                              ? 'Comprar Álbum'
                              : 'Aprovar Álbum'
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Template.Footer />

      <UI.FullscreenLoader
        active={finishAlbumApprovalIsLoading}
        textFristLine="Finalizando a aprovação do Álbum..."
      />

      <UI.Modal
        isOpen={showNavigateToCheckoutModal}
        maxWidth={560}
        header={{
          title: 'Comprar Álbum',
          Icon: MdOutlineAttachMoney,
        }}
        contentEl={modalNavigateToCheckoutBodyEl}
        buttonGroupDirection="row"
        closeable={false}
        onClose={() => false}
        positiveButton={{
          label: 'Comprar Álbum',
          onClick() {
            setShowNavigateToCheckoutModal(false);
            setNavigateToCheckout(true);
          },
          variant: UI.ButtonEnum.primary,
        }}
        negativeButton={{
          label: 'Cancelar',
          onClick: () => {
            setShowNavigateToCheckoutModal(false);
          },
          variant: UI.ButtonEnum.primaryOutline,
        }}
      />

      <UI.Modal
        isOpen={showFinishAlbumApprovalModal}
        maxWidth={560}
        header={{
          title: 'Aprovar Álbum',
          Icon: MdOutlineAttachMoney,
        }}
        contentEl={modalFinishAlbumApprovalEl}
        buttonGroupDirection="row"
        closeable={false}
        onClose={() => false}
        positiveButton={{
          label: 'Sim! Aprovar',
          onClick() {
            setShowFinishAlbumApprovalModal(false);
            setFinishAlbumApproval(true);
          },
          variant: UI.ButtonEnum.primary,
        }}
        negativeButton={{
          label: 'Cancelar',
          onClick: () => {
            setShowFinishAlbumApprovalModal(false);
          },
          variant: UI.ButtonEnum.primaryOutline,
        }}
      />

      <UI.Modal
        isOpen={showFinishAlbumSuccesslModal}
        maxWidth={560}
        header={{
          title: 'Albúm Aprovado!',
          Icon: MdOutlineCheckCircleOutline,
        }}
        contentEl={modalFinishAlbumSuccessEl}
        buttonGroupDirection="column"
        closeable={false}
        onClose={() => setShowFinishAlbumSuccesslModal(false)}
        positiveButton={{
          label: 'Ok! Entendi',
          onClick() {
            setShowFinishAlbumSuccesslModal(false);
          },
          variant: UI.ButtonEnum.primary,
        }}
      />
    </>
  );
};
