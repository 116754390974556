/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import { Menu } from '@headlessui/react';
import { IconType } from 'react-icons';

export interface dropdownButton {
  classes?: string;
  Icon: IconType;
  text: string;
}

export interface dropdownItem {
  id: string;
  Icon: IconType;
  text: string;
  url?: string;
  onClick?: () => void;
}

interface DropdownProps {
  align: 'right' | 'left';
  dropdownButton: dropdownButton;
  dropdownItems: dropdownItem[];
}

export function Dropdown({
  align = 'left',
  dropdownButton,
  dropdownItems,
}: DropdownProps) {
  return (
    <Menu as="div" className={`dropdown dropdown--${align}`}>
      <Menu.Button
        className={`dropdown__btn dropdown__btn--ellipsis btn btn--no-bg btn--small ${
          dropdownButton.classes ? dropdownButton.classes : ''
        }`}
      >
        <span>
          {dropdownButton.text}
        </span>
        <dropdownButton.Icon />
      </Menu.Button>
      <Menu.Items className="dropdown__list-box">
        {dropdownItems.map(({
          id, Icon, text, onClick, url,
        }) => (
          <Menu.Item key={id} as={Fragment}>
            <>
              {url && (
              <a
                href={url}
                className="dropdown__item-btn btn btn--no-bg btn--small"
              >
                <Icon />
                {text}
              </a>
              )}
              {onClick && (
              <button
                type="button"
                onClick={onClick}
                className="dropdown__item-btn btn btn--no-bg btn-small"
              >
                <Icon />
                {text}
              </button>
              )}
            </>
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
}
