import { useEffect } from 'react';
import { UI } from '@components';

interface FullscreenLoaderProps {
  textFristLine?: string;
  textSecondLine?: string;
  spinner?: UI.SpinnerProps
  active: boolean
}

export function FullscreenLoader({
  textFristLine,
  textSecondLine,
  spinner,
  active,
}: FullscreenLoaderProps) {
  useEffect(() => {
    if (active === true) {
      document.querySelector('.html')?.classList.add('page__html--overflow-y-hidden');
    } else if (active === false) {
      document.querySelector('.html')?.classList.remove('page__html--overflow-y-hidden');
    }
  }, [active]);

  return (
    <div
      className={`fullscreen-loader ${!active ? 'fullscreen-loader--hidden' : ''}`}
    >
      <div className="fullscreen-loader__container page__container">
        {textFristLine && (
          <p className="fullscreen-loader__text">
            {textFristLine}
          </p>
        )}
        {textSecondLine && (
          <p className="fullscreen-loader__text">
            {textSecondLine}
          </p>
        )}
        <div className="fullscreen-loader__spinner-wrapper">
          <UI.Spinner
            size={spinner?.size}
            strokeWidth={spinner?.strokeWidth}
            color={spinner?.color}
            colorScale={spinner?.colorScale}
          />
        </div>
      </div>
    </div>
  );
}
