/* eslint-disable radix */
/* eslint-disable no-plusplus */
import * as Yup from 'yup';
import { lang } from '@i18n';

export const creditCardSchema = Yup.object({
  cardholderCPF: Yup.string()
    .min(11, lang.translate(lang.keys.errors.generic.min_lenth, {
      path: lang.translate(lang.keys.forms.checkout.cardholder_cpf),
      length: 11,
    }))
    .max(11, lang.translate(lang.keys.errors.generic.max_lenth, {
      path: lang.translate(lang.keys.forms.checkout.cardholder_cpf),
      length: 11,
    }))
    .required(lang.translate(lang.keys.errors.generic.required_field, {
      path: lang.translate(lang.keys.forms.checkout.cardholder_cpf),
    }))
    .test('invalidDigits', lang.translate(lang.keys.errors.checkout.cardholder_cpf), (value) => {
      const cpf = (value as string).replace(/\D+/g, '');
      let sum = 0;
      for (let i = 0; i < 9; i++) {
        sum += parseInt(cpf.charAt(i)) * (10 - i);
      }
      let digit1 = 11 - (sum % 11);
      if (digit1 === 10 || digit1 === 11) digit1 = 0;
      if (parseInt(cpf.charAt(9)) !== digit1) return false;
      sum = 0;
      for (let i = 0; i < 10; i++) {
        sum += parseInt(cpf.charAt(i)) * (11 - i);
      }
      let digit2 = 11 - (sum % 11);
      if (digit2 === 10 || digit2 === 11) digit2 = 0;
      if (parseInt(cpf.charAt(10)) !== digit2) return false;
      return true;
    }),
  cardholderName: Yup.string().required(lang.translate(lang.keys.errors.generic.required_field, {
    path: lang.translate(lang.keys.forms.checkout.cardholder_name),
  })),
  creditCardCVV: Yup.string()
    .min(3, lang.translate(lang.keys.errors.generic.min_lenth, {
      path: lang.translate(lang.keys.forms.checkout.credit_card_cvv),
      length: 3,
    }))
    .max(3, lang.translate(lang.keys.errors.generic.max_lenth, {
      path: lang.translate(lang.keys.forms.checkout.credit_card_cvv),
      length: 3,
    }))
    .required(lang.translate(lang.keys.errors.generic.required_field, {
      path: lang.translate(lang.keys.forms.checkout.credit_card_cvv),
    })),
  creditCardNumber: Yup.string()
    .min(15, lang.translate(lang.keys.errors.generic.min_lenth, {
      path: lang.translate(lang.keys.forms.checkout.credit_card_number),
      length: 15,
    }))
    .max(16, lang.translate(lang.keys.errors.generic.max_lenth, {
      path: lang.translate(lang.keys.forms.checkout.credit_card_number),
      length: 16,
    }))
    .required(lang.translate(lang.keys.errors.generic.required_field, {
      path: lang.translate(lang.keys.forms.checkout.credit_card_number),
    }))
    .test('invalidDigits', lang.translate(lang.keys.errors.checkout.credit_card_number), (value) => {
      const creditCardNumber = (value as string).replace(' ', '');
      const regex = /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|3[47][0-9]{13}|6(?:011|5[0-9]{2})[0-9]{12}|(?:2131|1800|35\d{3})\d{11})$/;
      if (!regex.test(creditCardNumber)) return false;
      return true;
    }),
  creditCardValidity: Yup.string()
    .min(5, lang.translate(lang.keys.errors.generic.min_lenth, {
      path: lang.translate(lang.keys.forms.checkout.credit_card_validity),
      length: 4,
    }))
    .max(5, lang.translate(lang.keys.errors.generic.max_lenth, {
      path: lang.translate(lang.keys.forms.checkout.credit_card_validity),
      length: 4,
    }))
    .required(lang.translate(lang.keys.errors.generic.required_field, {
      path: lang.translate(lang.keys.forms.checkout.credit_card_validity),
    }))
    .test('invalidDate', lang.translate(lang.keys.errors.checkout.credit_card_validity), (value) => {
      const [monthStr, yearStr] = (value as string).split('/');
      const expMonth = Number(monthStr);
      const expYear = Number(yearStr);

      if (expMonth < 1 || expMonth > 12) return false;

      const currentDate = new Date();
      const expDateObj = new Date(2000 + expYear, expMonth - 1, 1);

      if ((expDateObj < currentDate) || (expDateObj > new Date(currentDate.getFullYear() + 10, 11, 31))) return false;

      return true;
    }),
});
