/* eslint-disable arrow-body-style */
import { useCallback } from 'react';
import { useApi } from './api';
import { CreateClientRequest } from './types';

export const useClients = () => {
  const api = useApi();

  const createClient = useCallback((request: CreateClientRequest) => api.post('/client', request), [api]);

  return {
    createClient,
  };
};
