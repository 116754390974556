import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import axios from 'axios';
import { decodeToken } from 'react-jwt';
import { AuthenticationInfo } from '@types';
import { LoginRequest } from '@api/types';
import { RouterContext } from './router.context';

export interface AuthContextData extends AuthenticationInfo {
  authenticated: boolean
  userId: string
}

const defaultValue: AuthContextData = {
  authenticated: false,
  accessToken: '',
  expiration: new Date(),
  refreshToken: '',
  tokenType: '',
  userId: '',
  userName: '',
};

const getAuthKey = (projectSlug: string, partnerSlug: string) => `auth:FotoGo:${projectSlug}@${partnerSlug}`;

const setAuth = (data: AuthContextData, projectSlug: string, partnerSlug: string) => {
  localStorage.setItem(getAuthKey(projectSlug, partnerSlug), JSON.stringify(data));
};

const login = ({ email, password }: LoginRequest) => axios.post<AuthenticationInfo>('/login', {
  userName: email,
  password,
  grant_type: 'password',
}, {
  baseURL: process.env.REACT_APP_PUBLIC_API_URL,
  validateStatus: () => true,
});

export const AuthContext = createContext({
  auth: defaultValue,
  // eslint-disable-next-line no-unused-vars
  login: (email: string, password: string) => Promise.resolve(),
});

export const AuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { slugs } = useContext(RouterContext);
  const storaged = localStorage.getItem(getAuthKey(slugs.project, slugs.partner));
  const data = storaged ? JSON.parse(storaged) as AuthContextData : defaultValue;
  const [state, setState] = useState<AuthContextData>(data);

  const doLogin = async (email: string, password: string) => {
    const { data } = await login({ email, password });
    const decodedToken = decodeToken<{ sub: string }>(data.accessToken as string);
    const auth: AuthContextData = { ...data, authenticated: true, userId: decodedToken?.sub || '' };
    setAuth(auth, slugs.project, slugs.partner);
    setState(auth);
  };

  const getContextValue = useCallback(() => ({ auth: state, login: doLogin }), [state]);

  return (
    <AuthContext.Provider value={getContextValue()}>
      {children}
    </AuthContext.Provider>
  );
};
