import { useRef } from 'react';

export function HamburgerMenu() {
  const offcanvasBtn = useRef<HTMLButtonElement>(null);

  const htmlEl = document.querySelector('.html');
  const toggleOffCanvasMenu = () => {
    htmlEl?.classList.toggle('page__html--offcanvas-is-open');
  };

  return (
    <button
      ref={offcanvasBtn}
      onClick={toggleOffCanvasMenu}
      type="button"
      className="hamburguer-menu"
    >
      <span className="hamburguer-menu__line hamburguer-menu__line--top" />
      <span className="hamburguer-menu__line hamburguer-menu__line--middle" />
      <span className="hamburguer-menu__line hamburguer-menu__line--bottom" />
    </button>
  );
}
