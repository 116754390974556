import React, { useContext, useState } from 'react';
import { MdOutlineDone } from 'react-icons/md';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { lang } from '@i18n';
import { routes } from '@routes';
import {
  Form,
  Notification,
  Template,
  UI,
} from '@components';
import { GalleryContext, RouterContext } from '@contexts';
import { useClients } from '@api';

type ErrorMessageType = 'error' | 'success' | 'warning';
type RegisterFormValueType = { email: string; new_password: string; new_password_confirm: string };
const registerFormInitialValue: RegisterFormValueType = {
  email: '',
  new_password: '',
  new_password_confirm: '',
};

const schema = Yup.object({
  email: Yup.string()
    .email(lang.translate(lang.keys.errors.register.email_invalid))
    .required(lang.translate(lang.keys.errors.generic.required_field, {
      path: lang.translate(lang.keys.forms.users.email),
    })),
  new_password: Yup.string()
    .required(lang.translate(lang.keys.errors.generic.required_field, {
      path: lang.translate(lang.keys.forms.users.password),
    }))
    .min(6, lang.translate(lang.keys.errors.generic.min_lenth, {
      path: lang.translate(lang.keys.forms.users.password),
      length: 6,
    })),
  new_password_confirm: Yup.string()
    .required(lang.translate(lang.keys.errors.generic.required_field, {
      path: lang.translate(lang.keys.forms.users.password),
    }))
    .oneOf([Yup.ref('new_password'), null], lang.translate(lang.keys.errors.reset.password_confirm)),
});

export function Register() {
  const { navigate } = useContext(RouterContext);
  const context = useContext(GalleryContext);
  const { createClient } = useClients();
  const [loading, setLoading] = useState(false);
  const [showClientRegisterSuccessModal, setShowClientRegisterSuccessModal] = useState(false);
  const [hasError, setHasErrors] = useState<{ type: ErrorMessageType, message: string } | null>(null);

  const clientRegisterSuccessModalEl: React.ReactNode = (
    <>
      <p>Clique no botão abaixo para ser redirecinado a tela de Acesso.</p>
      <p>Utilize os dados fornecidos em seu registro.</p>
    </>
  );

  const accessHubSubtitleEl: React.ReactNode = (
    <p className="page__desc">
      <b>
        {lang.translate(lang.keys.pages.register.subtitle1)}
      </b>
      {lang.translate(lang.keys.pages.register.subtitle2)}
    </p>
  );

  const handleSubmit = async (values: RegisterFormValueType) => {
    setLoading(true);

    try {
      await createClient({
        email: values.email,
        password: values.new_password,
      });
      setShowClientRegisterSuccessModal(true);
    } catch (error: any) {
      let type: ErrorMessageType = 'warning';
      let message = lang.translate(lang.keys.errors.api.generic);
      if (error?.response?.status === 400) {
        type = 'error';
        message = lang.translate(lang.keys.errors.login.invalid_auth_data);
      }
      setHasErrors({ type, message });
    }

    setLoading(false);
  };

  const onClickBack = () => {
    navigate(routes.login());
  };

  const onClickModal = () => {
    setHasErrors(null);
  };

  return (
    <>
      <Template.Page title={context?.galleryName} descriptionEl={accessHubSubtitleEl}>
        <Formik initialValues={registerFormInitialValue} validationSchema={schema} onSubmit={handleSubmit}>
          {({
            errors, touched, handleChange, handleBlur,
          }) => (
            <div className="login">
              <div className="form">
                <div className="form__grid">
                  <div className="form__group">
                    <Form.Label name="email" text={lang.translate(lang.keys.forms.users.email)} />
                    <Form.Input
                      id="email"
                      name="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="email"
                      error={touched.email && errors.email ? errors.email : ''}
                      disabled={loading}
                      placeholder={lang.translate(lang.keys.forms.users.email_login_placeholder)}
                    />
                  </div>
                  <div className="form__group">
                    <Form.Label name="new_password" text={lang.translate(lang.keys.forms.users.new_password)} />
                    <Form.Input
                      id="new_password"
                      name="new_password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="password"
                      error={touched.new_password && errors.new_password ? errors.new_password : ''}
                      disabled={loading}
                      placeholder={lang.translate(lang.keys.forms.users.password_login_placeholder)}
                    />
                  </div>
                  <div className="form__group">
                    <Form.Label name="new_password" text={lang.translate(lang.keys.forms.users.new_password_confirm)} />
                    <Form.Input
                      id="new_password_confirm"
                      name="new_password_confirm"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="password"
                      error={touched.new_password_confirm && errors.new_password_confirm ? errors.new_password_confirm : ''}
                      disabled={loading}
                      placeholder={lang.translate(lang.keys.forms.users.new_password_confirm_placeholder)}
                    />
                  </div>
                  <div className="login__btn-group">
                    <div className="form__btn-message">
                      <UI.Button
                        type="submit"
                        variant={UI.ButtonEnum.primary}
                        loading={loading}
                        text={lang.translate(lang.keys.pages.register.action_submit)}
                      />
                      {hasError && (
                        <Notification.Message
                          type={hasError.type}
                          onClick={onClickModal}
                          message={hasError.message}
                        />
                      )}
                    </div>
                    <UI.Button
                      type="button"
                      disabled={false}
                      variant={UI.ButtonEnum.neutralOutline}
                      loading={false}
                      onClick={onClickBack}
                      text={lang.translate(lang.keys.pages.register.back_button)}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </Template.Page>

      <UI.Modal
        isOpen={showClientRegisterSuccessModal}
        maxWidth={560}
        header={{
          title: 'Conta criada com sucesso',
          Icon: MdOutlineDone,
        }}
        contentEl={clientRegisterSuccessModalEl}
        buttonGroupDirection="column"
        closeable={false}
        onClose={() => false}
        positiveButton={{
          label: 'Ir para o acesso',
          onClick() {
            setShowClientRegisterSuccessModal(false);
            navigate(routes.login());
          },
          variant: UI.ButtonEnum.primary,
        }}
      />
    </>
  );
}
