/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */
import React, { MouseEvent } from 'react';

export enum ButtonEnum {
  neutral = 'btn btn--neutral',
  primary = 'btn btn--primary',
  noBg = 'btn btn--no-bg',
  error = 'btn btn--error',
  neutralLarge = 'btn btn--neutral btn--large',
  primaryLarge = 'btn btn--primary btn--large',
  errorLarge = 'btn btn--error btn--large',
  neutralSmall = 'btn btn--neutral btn--small',
  primarySmall = 'btn btn--primary btn--small',
  noBgSmall = 'btn btn--no-bg btn--small',
  errorSmall = 'btn btn--error btn--small',
  primaryIcon = 'btn btn--primary btn--icon',
  neutralOutline = 'btn btn--neutral btn--outline',
  primaryOutline = 'btn btn--primary btn--outline',
  errorOutline = 'btn btn--error btn--outline',
  neutralOutlineLarge = 'btn btn--neutral btn--outline btn--large',
  primaryOutlineLarge = 'btn btn--primary btn--outline btn--large',
  neutralOutlineSmall = 'btn btn--neutral btn--outline btn--small',
  primaryOutlineSmall = 'btn btn--primary btn--outline btn--small',
  primaryOutlineActive = 'btn btn--primary btn--outline btn--active',
}

interface ButtonProps {
  disabled?: boolean;
  fullwidth?: 'w-full';
  classes?: string;
  loading: boolean;
  onClick?: (_e: MouseEvent<HTMLButtonElement>) => void;
  text: string | React.ReactNode;
  type: 'submit' | 'button';
  variant?: ButtonEnum;
}

export function Button({
  disabled,
  fullwidth,
  classes,
  loading,
  onClick,
  text,
  type,
  variant,
}: ButtonProps) {
  return (
    <button
      disabled={disabled || loading}
      className={`${variant} ${fullwidth} ${classes}`}
      onClick={loading ? () => {} : onClick}
      type={type}
    >
      {!loading ? text : (
        <span className="btn__loader-wrapper">
          <svg className="btn__loader" viewBox="0 0 50 50">
            <circle className="btn__loader-path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
          </svg>
        </span>
      )}
    </button>
  );
}
