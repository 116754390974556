/* eslint-disable no-redeclare */
export type Locales = 'es-AR' | 'es-CL' | 'es-CO' | 'es-MX' | 'es-VE' | 'es-UY' | 'es-PE' | 'pt-BR' | 'en-US';

export interface Issuer {
  id: string;
  name: string;
  secure_thumbnail: string;
  thumbnail: string;
  processing_mode: string;
  merchant_account_id?: string;
}

export interface MerchantAccount {
  merchant_account_id: string;
  issuer: Issuer;
  payer_costs: {
    installments: number;
    installment_rate: number;
    discount_rate: number;
    labels: string[];
    installment_rate_collector: string[];
    min_allowed_amount: number;
    max_allowed_amount: number;
    recommended_message: string;
    installment_amount: number;
    total_amount: number;
    payment_method_option_id: string;
  }[];
}

export interface PaymentMethod {
  secure_thumbnail: string;
  min_accreditation_days: number;
  max_accreditation_days: number;
  id: string;
  payment_type_id: string;
  accreditation_time: number;
  thumbnail: string;
  marketplace: string;
  deferred_capture: string;
  labels: string[];
  name: string;
  site_id: string;
  processing_mode: string;
  additional_info_needed: string[];
  status: string;
  settings: [{
    security_code: {
      mode: 'mandatory' | string;
      card_location: string;
      length: number;
    };
    card_number: {
      length: number;
      validation: 'standard' | string;
    };
    bin: {
      pattern: string;
      installments_pattern: string;
      exclusion_pattern: string;
    };
  }];
  issuer: {
    default: boolean;
    name: string;
    id: number;
  };
}

export interface CardToken {
  id: string;
}

export interface MercadoPagoOptions {
  /** Set the locale */
  locale?: Locales;
  /** Set the advanced fraud prevention status */
  advancedFraudPrevention?: boolean;
}

export interface GetInstallmentsOptions {
  /** Payment total amount */
  amount: string;
  /** Card number first 6 digits */
  bin: string;
  /** Set the response message language */
  locale?: Locales;
  /** Process mode */
  processingMode?: 'aggregator' | 'gateway';
}

export interface GetPaymentMethodsOptions {
  /** Card number first 6 digits */
  bin: string;
  /** Process mode */
  processingMode?: 'aggregator' | 'gateway';
}

export interface GetIssuersOptions {
  /** Payment method ID */
  paymentMethodId: string;
  /** Card number first 6 digits */
  bin: string;
}

export interface CreateCardTokenOptions {
  /** Card number */
  cardNumber?: string;
  /** Cardholder name */
  cardholderName?: string;
  /** Expiration year */
  cardExpirationMonth?: string;
  /** Expiration year */
  cardExpirationYear?: string;
  /** Security code */
  securityCode?: string;
  /** Type of document */
  identificationType?: string;
  /** Value of document */
  identificationNumber?: string;
  /** Id of a saved card */
  cardId?: string;
}

export interface PagingResult<TResult> {
  paging: {
    total: number,
    limit: number,
    offset: number,
  },
  results: TResult[];
}

export interface MercadoPago {
  getInstallments: (options: GetInstallmentsOptions) => Promise<MerchantAccount[]>
  getPaymentMethods: (op1tions: GetPaymentMethodsOptions) => Promise<PagingResult<PaymentMethod>>
  getIssuers: (options: GetIssuersOptions) => Promise<Issuer[]>
  createCardToken: (options: CreateCardTokenOptions) => Promise<CardToken>
}

export namespace MercadoPago {
  export enum PaymentType {
    PaymentSlip = 'bolbradesco',
    CreditCard = 'credit_card',
    Pix = 'pix',
  }

  export interface CreditCard {
    token: string
    holderName: string
    holderDocument: string
    installmentsQuantity: number
    methodId: string
  }

  export interface Data {
    methodCode: PaymentType
    deviceId: string
    creditCard: CreditCard
  }
}

declare global {
  interface Window {
    MercadoPago: new (publicKey: string, options?: MercadoPagoOptions) => MercadoPago;
    MP_DEVICE_SESSION_ID: string;
  }
}
