/* eslint-disable arrow-body-style */
import { useCallback } from 'react';
import { useApi } from './api';
import { ChangePasswordRequest, RecoveryPasswordRequest } from './types/auth';

export const useAuth = () => {
  const api = useApi();

  const resetPassword = useCallback((request: ChangePasswordRequest) => api.post('/login/recoverChangePassword', request), [api]);

  const recoveryPassword = useCallback((request: RecoveryPasswordRequest) => api.post('/login/recover', request), [api]);

  return {
    resetPassword,
    recoveryPassword,
  };
};
