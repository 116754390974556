/* eslint-disable no-unused-vars */
import { FC, useEffect, useState } from 'react';
import { FiFacebook, FiInstagram, FiYoutube } from 'react-icons/fi';
import { usePartners } from '@api';
import { Partner } from '@types';

export enum SocialbarEnum {
  row = '',
  offcanvas = 'socialbar--offcanvas',
}

interface SocialbarProps {
  variant: SocialbarEnum
}

const SocialLinks = [
  {
    name: 'facebook',
    url: 'https://www.facebook.com/',
    Icon: FiFacebook,
  },
  {
    name: 'instagram',
    url: 'https://www.instagram.com/',
    Icon: FiInstagram,
  },
  {
    name: 'youtube',
    url: 'https://www.youtube.com/',
    Icon: FiYoutube,
  },
];

export const Socialbar: FC<SocialbarProps> = ({ variant }) => {
  const { getPartnerInfo } = usePartners();
  const [partnerSocials, setPartnerSocials] = useState<Pick<Partner.Info, 'facebook' | 'instagram' | 'youtube'>>();

  async function getPartnerInfosAsync() {
    const { data: PartnerSocialsSummary } = await getPartnerInfo();

    setPartnerSocials({
      facebook: PartnerSocialsSummary.facebook,
      instagram: PartnerSocialsSummary.instagram,
      youtube: PartnerSocialsSummary.youtube,
    });
  }

  useEffect(() => {
    // getPartnerInfosAsync();
  }, []);

  return (
    <nav className="socialbar">
      <ul className={`${variant} socialbar__list`}>
        {SocialLinks.map((item) => {
          const { name, url, Icon } = item;

          let socialURL;
          switch (name) {
            case 'facebook':
              socialURL = partnerSocials?.facebook;
              break;
            case 'instagram':
              socialURL = partnerSocials?.instagram;
              break;
            case 'youtube':
              socialURL = partnerSocials?.youtube;
              break;
            default:
              socialURL = '';
          }

          return (
            <li className={`socialbar__item ${socialURL === undefined ? 'socialbar__item--hidden' : ''}`} key={name}>
              <a href={socialURL} target="_blank" rel="noreferrer">
                <div className="socialbar__wrapper">
                  <Icon className="socialbar__icon" />
                </div>
              </a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
