/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */

import React, {
  useRef, useEffect, useState, FormEvent,
} from 'react';
import {
  MdOutlineSend, MdOutlineComment, MdOutlineClose,
} from 'react-icons/md';
import { Form } from '@components';
import { Comment } from '@types';
import { useFormatLocal } from '@hooks';

export enum CommentsChatEnum {
  imageSelection = 'comments-chat--image-selection comments-chat--hidden',
  albumApproval = 'comments-chat--album-approval',
}

export interface IHideableChatComments {
  commentsEl: React.RefObject<HTMLDivElement>;
  onCloseComment: () => void;
}

export interface IComments {
  productId?: string;
  url?: string;
  fileComments: Comment[];
}

interface CommentsChatProps {
  isLoading: boolean;
  variant: CommentsChatEnum;
  disableSendMessage?: boolean;
  hideOnClick?: IHideableChatComments;
  page?: string;
  comments?: IComments;
  addComment: (productId: string, text: string) => void;
}

export function CommentsChat({
  isLoading,
  variant,
  disableSendMessage,
  hideOnClick,
  page,
  comments = {
    productId: 'placeholder',
    url: 'placeholder',
    fileComments: [
      {
        text: 'placeholder',
        date: new Date(),
        isOwn: true,
      },
      {
        text: 'placeholder',
        date: new Date(),
        isOwn: false,
      },
      {
        text: 'placeholder',
        date: new Date(),
        isOwn: true,
      },
    ],
  },
  addComment,
}: CommentsChatProps) {
  const lastMessageAnchorRef = useRef<HTMLSpanElement>(null);
  const inputTextComment = useRef<HTMLInputElement>(null);
  const commentsPageEl = useRef<HTMLElement>(null);
  const commentsMessagesContainerEl = useRef<HTMLDivElement>(null);
  const { humanizeLocalDate } = useFormatLocal();
  const [value, setValue] = useState('');

  function onChange(e: FormEvent<HTMLInputElement>) {
    setValue(e.currentTarget.value);
  }

  function fadeInEl(elements: Array<React.RefObject<HTMLElement> | React.RefObject<HTMLDivElement>>, duration: number = 500) {
    elements.forEach((el) => {
      if (el) {
        el.current?.setAttribute('style', `animation-duration: ${duration}ms`);
        el.current?.classList.add(...['fadeIn']);
        setTimeout(() => {
          el.current?.classList.remove(...['fadeIn']);
        }, duration);
      }
    });
  }

  function handleKeyDown(e: any): void {
    if (e.key === 'Enter') {
      e.preventDefault();
      addComment(
        comments?.productId as string,
        inputTextComment.current?.value as string,
      );
      setValue('');
    }
  }

  useEffect(() => {
    if (lastMessageAnchorRef.current) {
      lastMessageAnchorRef.current.scrollIntoView({
        block: 'end',
      });
    }
  }, [comments]);

  useEffect(() => {
    fadeInEl([commentsPageEl, commentsMessagesContainerEl]);
  }, [page]);

  return (
    <div ref={hideOnClick?.commentsEl} className={`comments-chat ${variant}`}>
      <div className="comments-chat__inner">
        <div className="comments-chat__heading">
          <div className="comments-chat__left">
            {comments?.url && (
              <img src={comments?.url} alt="" className="comments-chat__img" />
            )}
            <MdOutlineComment className="comments-chat__icon comments-chat__icon--emphasys" />
          </div>
          <div className="comments-chat__middle">
            <h5 className="comments-chat__title">Comentários</h5>
          </div>
          <div className={`comments-chat__right ${page ? 'comments-chat__right--page' : ''}`}>
            {page && (
              <span
                ref={commentsPageEl}
                className="comments-chat__page"
              >
                {page}
              </span>
            )}
            {hideOnClick && (
              <button
                type="button"
                className="comments-chat__btn"
                onClick={() => hideOnClick?.onCloseComment()}
              >
                <MdOutlineClose className="comments-chat__icon" />
              </button>
            )}
          </div>
        </div>
        <div
          ref={commentsMessagesContainerEl}
          className="comments-chat__messages-container custom-scrollbar"
        >
          {comments?.fileComments && comments?.fileComments?.length > 0 ? (
            comments.fileComments.map((fileComment, index) => (
              <div
                key={index}
                className={`comments-chat__message 
                ${fileComment.isOwn ? 'comments-chat__message--own' : ''}
                ${isLoading ? 'skeleton skeleton--neutral-100' : ''}`}
              >
                <p className="comments-chat__text">{fileComment.text}</p>
                <div className="comments-chat__infos">
                  <span className="comments-chat__author" />
                  <small className="comments-chat__time">
                    {humanizeLocalDate(fileComment.date)}
                  </small>
                </div>
              </div>
            ))
          ) : (
            <span className="comments-chat__empty">
              Nenhum comentário foi feito
            </span>
          )}
          <span
            ref={lastMessageAnchorRef}
            className="comments-chat__last-message-anchor"
          />
        </div>
        <div className="comments-chat__input">
          <Form.Input
            id={undefined}
            name="text-input"
            innerRef={inputTextComment}
            onChange={onChange}
            value={value}
            classes="comments-chat__input-field field__input field__input--large"
            placeholder="Escreva sua mensagem"
            type="text"
            error={undefined}
            disabled={disableSendMessage}
          />
          <button
            type="button"
            className="comments-chat__input-btn btn btn--primary btn--icon btn--large"
            disabled={disableSendMessage}
            onKeyDown={handleKeyDown}
            onClick={() => {
              addComment(
                comments?.productId as string,
                inputTextComment.current?.value as string,
              );
              setValue('');
            }}
          >
            <MdOutlineSend className="comments-chat__icon comments-chat__icon--lg" />
          </button>
        </div>
      </div>
    </div>
  );
}
